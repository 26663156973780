import React, { useState, useEffect } from 'react';
import {
    XCard,
    XBox,
    XLabel,
    XInput,
    XButton,
} from '@ximdex/xui-react/material';
import { useNavigate } from 'react-router-dom';
import HTMLReactParser from "html-react-parser";
import { 
    StyledGreenXButton,
    StyledRedXButton,
    StyledFontAwesomeIcon,
    StyledButtonCenterContainer,
    StyledAddActivitiesButton,
    StyledActDetailsParagraph
 } from '../../Styled-Components';

export const ActivitiesList = ({ 
    assessmentInfo, 
    setAssessmentInfo, 
    updateAssessmentInfo,
    setUpToDate,
    setAddActivities,
}) => {
    const navigate = useNavigate();
    const [weights, setWeights] = useState({})
    const [totalWeight, setTotalWeight] = useState({})
    useEffect(() => {
        handleSetTotalWeight(assessmentInfo.activities)
    }, [assessmentInfo]);


    const removeFromActivitiesList = (id) => {
        if (window.confirm(`Are you sure you want to remove the activity with id ${id} from this assessment?`)) {
            let cloneActivities = [ ...assessmentInfo.activities ]
            const indexOfActivityToRemove = assessmentInfo.activities.findIndex(activity => activity.id === id)
            cloneActivities.splice(indexOfActivityToRemove, 1)
            updateAssessmentInfo(cloneActivities)
        }
    }

    const handleSetTotalWeight = (activities) => {
        let newWeights = { ...weights }
        let newTotalWeight = 0
        let recommendedWeight = 100 / activities.length
        
        activities.map(activityWeightData => {
            newWeights[activityWeightData.id] = activityWeightData.weight
            newTotalWeight += Number(activityWeightData.weight)
        })

        setWeights(newWeights)

        setTotalWeight({
            weight: newTotalWeight, 
            isValid: newTotalWeight > 100 ? false : true,
            error: newTotalWeight > 100 ? `The sum of all the activity weights has to be 100. If all activities are going to have the same weight, then change them all to ${recommendedWeight}.` : "",
            errorIndividual: newTotalWeight > 100 ? 'Check the total weight for the whole assessment.' : ''
        })
    }

    const handleWeightChange = (value, index, assessmentInfo) => {
        let newActivitiesInfo = [...assessmentInfo.activities]
        newActivitiesInfo[index].weight = value
        
        setUpToDate(false)
        setAssessmentInfo({ ...assessmentInfo, activities: newActivitiesInfo })
    }

    return (
        <>
            <XCard
                isCollapsable={assessmentInfo?.activities?.length > 0 ? true : false}
                isCollapsed={false}
                title={`Current Activity List (${assessmentInfo?.activities?.length})`}
                marginXSize='m'
                style={{marginBottom: '2em'}}
            >
                {assessmentInfo?.activities?.length === 0 &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => setAddActivities(prevState => ({...prevState, status: true, from_position: 0}))}>
                            + Add activity
                        </XButton>
                    </StyledButtonCenterContainer>
                }
                {assessmentInfo?.activities?.length > 0 &&
                <>
                    {assessmentInfo.activities.map((activity, activityIndex) => (<>
                        <XBox
                            style={{ position: 'relative' }}
                            marginXSize='m'
                            key={`activityFromAssessment_${activityIndex}`}
                        >
                            <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
                                <StyledRedXButton onClick={() => removeFromActivitiesList(activity?.id)}>
                                    <StyledFontAwesomeIcon icon={['fa', 'eraser']} size='1x' title='Remove activity from this assessment.' />
                                </StyledRedXButton>
                                <StyledGreenXButton onClick={() => navigate(`/edit-activity?activity-id=${activity?.id}&from-assessment-id=${assessmentInfo?.id}`)}>
                                    <StyledFontAwesomeIcon icon={['fa', 'pen-to-square']} size='1x' title='Edit activity.' />
                                </StyledGreenXButton>
                            </div>
                            <StyledActDetailsParagraph>Name: {activity?.name}</StyledActDetailsParagraph>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexFlow: 'wrap'}}>
                                <StyledActDetailsParagraph>Id: {activity?.id}</StyledActDetailsParagraph>
                                <StyledActDetailsParagraph>Type: {activity?.type}</StyledActDetailsParagraph>
                                <StyledActDetailsParagraph>
                                    Default lang: {activity?.language_default ?? 'data unavailable'}
                                </StyledActDetailsParagraph>
                                <StyledActDetailsParagraph>
                                    Translations available: {
                                        !activity?.hasOwnProperty('available_languages') 
                                        ? 'data unavailable'
                                        : activity?.available_languages?.length === 0 
                                        ? 'none' 
                                        : activity?.available_languages?.join(', ')
                                    }
                                </StyledActDetailsParagraph>
                            </div>
                            <div style={{ width: '90%', margin: '0 auto', color: '#385985', padding: '6px 16px', borderWidth: '2px 2px 0 2px', borderStyle: 'solid', borderColor: '#f2f2f2', borderRadius: '8px'}}>
                                {activity.title && <p style={{ backgroundColor: '#f2f2f2', padding: '12px', borderRadius: '4px' }}>{activity?.title}</p>}
                                {activity.text && <p style={{ padding: '0 8px',}}>{HTMLReactParser(activity?.text)}</p> }
                            </div>
                            <XLabel
                                label={'Weight: '}
                                paddingSize='s'
                                style={{ margin: '0 auto' }}
                                component=
                                {
                                    <XInput
                                        value={weights[activity.id] || undefined}
                                        size='small'
                                        type='number'
                                        min="0" max="100"
                                        fullWidth
                                        style={{ margin: '0 0 0 8px' }}
                                        onChange={(e) => handleWeightChange(e.target.valueAsNumber, activityIndex, assessmentInfo)}
                                    />
                                }
                                htmlFor={'title'}
                                labelLocation='left'
                            />
                            {totalWeight.errorIndividual && <p style={{color: 'red', textAlign: 'center'}}>{totalWeight.errorIndividual}</p> }
                            {(activity?.status === 'DRAFT' || activity?.status === 'ARCHIVED') && <p style={{color: 'red', textAlign: 'center'}}>This activity is not active at the moment. Please, change the status as soon as possible or consider removing it from this assessment.</p>}
                        </XBox>
                        <StyledButtonCenterContainer>
                            {assessmentInfo?.activities[activityIndex + 1] 
                            ? 
                                <StyledAddActivitiesButton onClick={() => setAddActivities(prevState => ({...prevState, status: true, from_position: activityIndex + 1}))}>
                                    + <span>Add activity</span>
                                </StyledAddActivitiesButton>
                            : 
                                <XButton onClick={() => setAddActivities(prevState => ({...prevState, status: true, from_position: activityIndex + 1}))}>
                                    + Add activity
                                </XButton>
                            }
                        </StyledButtonCenterContainer>
                        </>
                    ))}
                    <XLabel
                        label={'Total weight: '}
                        paddingSize='s'
                        style={{ margin: '0 auto' }}
                        component=
                        {
                            <XInput
                                placeholder='Sum of all should be 100.'
                                size='small'
                                type='number'
                                value={totalWeight?.weight?.toFixed(2) || undefined}
                                min="0"
                                fullWidth
                                disabled={true}
                                style={{ marginLeft: '8px' }}
                            />
                        }
                        htmlFor={'title'}
                        labelLocation='left'
                    />
                    {totalWeight?.error && <p style={{color: 'red', textAlign: 'center'}}>{totalWeight?.error}</p> }
                </>}
            </XCard>
        </>
    )
};
