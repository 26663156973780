import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getActivityById, getUserActivityById, updateActivity,putActivityLanguage, deleteTranslation } from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import {
    XContainerPage,
    XContainerSidebar,
    XButton,
    XTabs,
    XDropdown,
    XCard,
} from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { GlobalFeedback } from './GlobalFeedback';
import { IdentifyingData } from './IdentifyingData';
import ActivityHeader from './ActivityHeader';
import ActivityContent from './ActivityContent';
import { ActivityAnswers } from './ActivityAnswers';
import ActivityProperties from './ActivityProperties';
import ActivityTranslations from './ActivityTranslations';
import ActivityCreateTranslations from './ActivityCreateTranslations'
import Preview from '../../components/Preview/Preview'
import SamplePreview from '../../components/Preview/SamplePreview'
import {
    ACTIVITIES_PREVIEWS,
    ACTIVITIES_GLOBAL_FEEDBACK,
    ACTIVITIES_ANSWERS_OPTIONS_SETTINGS,
    ACTIVITIES_ANSWERS_TEMPLATE,
    ACTIVITIES_CONTENT_SETTINGS,
    ACTIVITIES_PROPERTIES_SETTINGS,
    ACTIVITIES_TOOLTIPS,
    LANGUAGES_OPTIONS
} from '../../CONSTANTS';
import { 
    StyledXContainerPreview,
    StyledHeaderContainer,
    StyledFontAwesomeIcon,
    StyledRedXButton
} from '../../Styled-Components';
import { faSplotch, faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useSortOptionsTargets from '../../hooks/useSortOptionsTargets';

const ActivityCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const assessmentId = params.get('from-assessment-id')
    const { showSpinner, hideSpinner } = useSpinner();
    const activityId = parseInt(params.get('activity-id'));
    const [sortOptionsAndTargets, sortOptions] = useSortOptionsTargets();

    //STATE
    const [activityInfo, setActivityInfo] = useState({ id: activityId });
    const [activityTranslation,setActivityTranslation] = useState({ id: activityId })
    const [isActivityInfoUpdated, setIsActivityInfoUpdated] = useState(true);

    const [langTabs, setLangTabs] = useState([]);
    const [langTabChecked, setLangTabChecked] = useState(params.get('lang'));
    const [languageSelected, setLanguageSelected] = useState('');
    const [languagesAvaliables, setLanguagesAvaliables] = useState([])

    const [reload,setReload] = useState(false)

    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');
    const [userActivityId, setUserActivityId] = useState(null);
    
    useEffect(() => {
        if(localStorage.getItem("create-translation")){
            var translation = JSON.parse(localStorage.getItem("create-translation"))
            if(translation.lang !== '') setLanguageSelected(translation.lang)
        }
    }, []);

    useEffect(() => {
        !activityId ? handleNoActivityIdFound() : getActivityInfo(activityId, true)
    }, [reload]);

    useEffect(() => {
        navigate(`/edit-activity?activity-id=${activityId}${assessmentId ? `&from-assessment-id=${assessmentId}` : '' }&lang=${langTabChecked}`)
    }, [langTabChecked])

    useEffect(() => {
        localStorage.setItem("create-translation", JSON.stringify({
            lang: ''
        }))
    }, [activityId]);

    const handleNoActivityIdFound = () => {
        alert("The activity id seems to be missing. Try again later.")
        navigate.goBack();
    }

    useEffect(() => {
        const modifiedLangTabs = langTabs.map(tab => {
            const clonedTab = { 
                ...tab,
                status: (tab.name === params.get('lang')) || ((tab.name === '+ Add translation') && params.get('lang') === 'new') ? 'checked' : 'unchecked' 
            }
            return clonedTab
        })
        setLangTabs(modifiedLangTabs)
    }, [params.get('lang')]);
    

    const handleSetLangTabs = (data) => {
        let newLangTabs = data?.language_default ?
            [
                {
                    name: data.language_default,
                    status: params.get('lang') === data.language_default ? 'checked' : 'unchecked',
                    handleTabChange: (tab) => setLangTabChecked(tab.name),
                },
            ] : [
                {
                    name: 'No language assigned',
                    status: 'checked',
                    handleTabChange: (tab) => setLangTabChecked(tab.name),
                },
            ]

        if (data.available_languages.length > 0) {
            data.available_languages.forEach(lang => {
                const newTab = {
                    name: lang,
                    status: params.get('lang') === lang ? 'checked' : 'unchecked',
                    handleTabChange: (tab) => setLangTabChecked(tab.name),
                }
                newLangTabs.push(newTab)
            })
        }

        newLangTabs.push({
            name: '+ Add translation',
            status: params.get('lang') === 'new' ? 'checked' : 'unchecked',
            handleTabChange: (tab) => setLangTabChecked('new'),
        })

        setLangTabs(newLangTabs)
    }

    const getUserActivityId = async (activityInfoId) => {
        setUserActivityId(null);

        if (activityInfoId) {
            const activityId = await getUserActivityById(activityInfoId);

            if (activityId) {
                setUserActivityId(activityId)
            }
        }
    }

    const getActivityInfo = (currentId, isInit, langTabs) => {
        showSpinner()

        getActivityById(currentId).then(data => {
            let newData = { ...data }

            sortOptionsAndTargets(newData)
            newData["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]
            
            // if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT.includes(newData.type)) {
            //     newData = sortOptionsAndTargets(newData)
            // }
            // if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT_OPTIONS_ONLY.includes(newData.type)) {
            //     newData = sortOptions(newData)
            // }

            setActivityInfo(newData)
            return newData
        }).then((data) => {
            isInit && handleSetLangTabs(data) 
            setLanguagesAvaliables(LANGUAGES_OPTIONS.filter(element => element.value !== data.language_default && !data.available_languages.includes(element.value)))
            setLangTabChecked(data.available_languages.includes(params.get('lang')) ? params.get('lang') : data.language_default)
            getUserActivityId(data?.id)
            return data
        }).then((data) => {
            setIsActivityInfoUpdated(true)
        }).catch(err => {
            console.log(err)
            XPopUp({
                message:"There's been an error getting your activity. Please, try again.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner()
        });
    }

    const handleInfoUpdated = (newActivityInfo, newUpdateStatus) => {
        setIsActivityInfoUpdated(newUpdateStatus)
        setActivityInfo(newActivityInfo)
    }

    const editActivityInfo = (e, keyToEdit, actionForArray, detailToEditFromArray) => {
        const keyChange = keyToEdit || e?.target?.id;
        const valueChange = (e?.target?.value ?? e) || detailToEditFromArray;
        let newInfo = { ...activityInfo }

        if (!actionForArray) {
            if (keyToEdit === 'current_language' && activityInfo.language_default === activityInfo.current_language) {
                newInfo[keyChange]= valueChange
                newInfo.language_default= valueChange
            } else {
                newInfo[keyChange]= valueChange;
            }
        } else {
            if (actionForArray.toLowerCase() === 'remove') {
                newInfo[keyToEdit].splice(detailToEditFromArray, 1);
            } else if (actionForArray.toLowerCase() === 'add') {
                if (!newInfo[keyToEdit]) newInfo[keyToEdit] = []
                newInfo[keyToEdit].push(valueChange)
            } else if (actionForArray.toLowerCase() === 'edit-multiple-values') {
                newInfo[keyToEdit] = []
                valueChange.forEach(value => {
                    newInfo[keyToEdit].push(value.value)
                })
            }
        }

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo)
    }

    const saveActivity = async () => {
        // showSpinner();

        if (ACTIVITIES_GLOBAL_FEEDBACK.includes(activityInfo?.type) && activityInfo.feedbacks && activityInfo.feedbacks.length > 0 ) activityInfo.feedbacks.sort((x, y) => x.success_percentage - y.success_percentage);

        await updateActivity(activityInfo.id, activityInfo).then(data => {
            if (data?.id) {
                let newData = { ...data }
                if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT.includes(newData.type)) {
                    newData = sortOptionsAndTargets(newData)
                }
                if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT_OPTIONS_ONLY.includes(newData.type)) {
                    newData = sortOptions(newData)
                }
                setIsActivityInfoUpdated(true)
                setActivityInfo(newData);
                XPopUp({
                    message:"Activity saved successfully",
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
                return newData
            } else {
                XPopUp({
                    message:"Looks like an error may have occured while updating this activity. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
        }).catch(error => {
            console.error('There has been an error while updating this activity. Try again later.', error)
            XPopUp({
                message:"Looks like an error may have occured while updating this activity. Try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            // hideSpinner();
            setReload(!reload)
        });
    }

    const addNewTag = () => {
        const ALL_TAGS = [...tags];
        const tag = {
            name: currentTag,
            status: 'incorrect',
            onClick: removeTag
        }
        ALL_TAGS.push(tag);
        setCurrentTag('');
        setTags(ALL_TAGS);
        // REFACTOR: Remove one we solve why setCurrentTag to empty string does not work
        document.querySelector('#tags').value = '';
    }

    const removeTag = (allTags, index) => {
        const ALL_TAGS = [...allTags];
        ALL_TAGS.splice(index, 1);
        setTags(ALL_TAGS);
    }

    const handleLanguageNewTab = (e) => {
        let language = languagesAvaliables.find((option) => option.label === languagesAvaliables[e].label)
        localStorage.setItem("create-translation", JSON.stringify({
            lang: language.value
        }))
        setLanguageSelected(language.value)
    }
    
    const saveActivityTranslate = async () => {
        showSpinner()
        
        await putActivityLanguage(activityInfo.id, languageSelected, activityTranslation).then(data => {
            if (data?.id) {
                XPopUp({
                    message:"Translation saved successfully",
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
            } else {
                XPopUp({
                    message:"Looks like an error may have occured while updating this activity's translation. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
        }).catch(error => {
            console.error('There has been an error while updating this translation. Try again later.', error)
            XPopUp({
                message:"Looks like an error may have occured while updating this activity's translation. Try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner();
        });
        setIsActivityInfoUpdated(false)
        localStorage.setItem("create-translation", JSON.stringify({
            lang: ''
        }))
        setReload(!reload)
        setLanguageSelected("")
    }


    const deleteActivityTranslation = async (id,dataType,lang) => {
        if(window.confirm(`Are you sure you want to delete the ${lang} translation of the activity with id ${id}?`)){
            showSpinner()
            await deleteTranslation(id,dataType,lang)
                .then(res => {
                    if (res?.status === 200 || res?.statusText === 'ok') {
                        setReload(!reload)
                        setLanguageSelected("")
                        localStorage.setItem("create-translation", JSON.stringify({
                            lang: ''
                        }))
                        XPopUp({
                            message:"Translation successfully deleted",
                            iconType:'success',
                            timer:'3000',
                            popUpPosition:'top',
                            iconColor: 'ligthgreen',
                        })
                    } else {
                        XPopUp({
                            message:"Looks like an error may have occured while deleting this translation. Try again later.",
                            iconType:'error',
                            timer:'3000',
                            popUpPosition:'top',
                            iconColor: 'red',
                        })
                    }
                })
                .catch(error => {
                    console.error('There has been an error while deleting this translation. Try again later.', error)
                    XPopUp({
                        message:"Looks like an error may have occured while deleting this translation. Try again later.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                })
                .finally(() => {
                    hideSpinner();
                });
        };  
    };

    
    return (
        <XContainerPage navbarSize="unset" style={{ height: 'calc(100VH - 82px)' }}>
            <XContainerSidebar
                wider
                paddingSize='unset'
                bgColor='200'
                style={{ backgroundColor: '#f5f5f5' }}
            >
                <XTabs
                    bgColor='100'
                    tabs={langTabs}
                    style={{ paddingLeft: '16px'}}
                />
                
                {langTabChecked === 'new' 
                ? 
                    // ADD LANGUAGE TAB
                    <>
                        <StyledHeaderContainer>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <StyledFontAwesomeIcon icon={faSplotch} title='New feature 28/07/2022. Styles here will improve later.' size='1x' hasMarginRight isWarning />
                                <h1>{`${activityInfo?.type?.replace(/-/g, ' ').toUpperCase()} TRANSLATIONS  ${languageSelected !== '' ? 'TO ' + languageSelected : ''}`}</h1>
                            </div>
                            <div style={{display:'flex'}}>
                                {languageSelected && 
                                    <XButton
                                        onClick={() => {
                                            localStorage.setItem("create-translation", JSON.stringify({
                                                lang: ''
                                            }))
                                            setLanguageSelected('')}} 
                                        size='small' style={{minWidth: 'unset', height:'100%', marginRight:'1em'}}
                                    >
                                        <StyledFontAwesomeIcon size='1x'
                                            icon={faXmark} 
                                            title={"Cancel translation"}
                                        />
                                    </XButton>
                                }

                                <XButton 
                                    onClick={() => saveActivityTranslate()} 
                                    size='small' style={{minWidth: 'unset', height:'100%'}}
                                    disabled={isActivityInfoUpdated}
                                >
                                    <StyledFontAwesomeIcon size='1x' 
                                        icon={isActivityInfoUpdated ? ['fa', 'floppy-disk'] : ['fa', 'floppy-disk']  } 
                                        title={isActivityInfoUpdated ? 'Save language' : 'Choose a language first'}
                                    />
                                </XButton>

                                
                            </div>

                        </StyledHeaderContainer>

                        {languageSelected === '' 
                        ?
                            <XCard
                                isCollapsable={false}
                                isCollapsed={false}
                                marginXSize='m'
                                style={{display:'flex', alignItems:'center'}}
                            >
                                {languagesAvaliables.length > 0 ? <>

                                <XDropdown
                                    onChange={(e) => handleLanguageNewTab(e.target.dataset.optionIndex)}
                                    options={languagesAvaliables}
                                    labelOptions='label'
                                    label='Select current language'
                                    width='100%'
                                    hasCheckboxes={false}
                                    multiple={false}
                                    disableClearable
                                    size="small"
                                />
                                </>
                                :
                                <p>0 languages available to translate to.</p>
                            }
                            </XCard>
                        : 
                            <ActivityCreateTranslations
                                languageSelected={languageSelected}
                                activityInfo={activityInfo}
                                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                                setActivityTranslation={setActivityTranslation}
                            />   
                        }
                    </>
                : 
                // LANGUAGES TABS
                <>
                <StyledHeaderContainer>
                    <h1>{activityInfo?.type?.replace(/-/g, ' ').toUpperCase()} {activityInfo?.language_default !== langTabChecked ? `TRANSLATIONS ${langTabChecked}` : 'EDITOR'}</h1>
                    <div style={{display:'flex'}}>
                        {assessmentId && 
                            <XButton
                                onClick={() => navigate(`/edit-assessment?assessment-id=${assessmentId}`)} 
                                size='small' style={{minWidth: 'unset', height:'100%', marginRight:'1em'}}
                            >
                                <StyledFontAwesomeIcon 
                                    size='1x'
                                    icon={faArrowLeft} 
                                    title='Go back to the assessment.'
                                />
                            </XButton>
                        }
                        {/* New button that takes you to the create new activity page. Disabled when info is not up to date or if you are reviewing one of the translations. */}
                        {/* <XButton
                            size='small' style={{minWidth: 'unset', marginRight:'1em', display: `${langTabChecked !== activityInfo.language_default ? 'none' : 'block'}`}}
                            disabled={!isActivityInfoUpdated}
                            onClick={() => navigate('/create-activity')}
                        >
                            <StyledFontAwesomeIcon 
                                size='1x'
                                icon={['fa', 'plus']} 
                                title='Create a new activity.'  
                            />
                        </XButton> */}
                        <XButton 
                            onClick={() => langTabChecked === activityInfo?.language_default ? saveActivity() : saveActivityTranslate()} 
                            size='small' style={{minWidth: 'unset',display: `${langTabChecked !== activityInfo.language_default ? 'none' : 'block'}`}}
                            disabled={isActivityInfoUpdated}
                        >
                            <StyledFontAwesomeIcon 
                                size='1x'
                                icon={isActivityInfoUpdated ? ['fa', 'check'] : ['fa', 'floppy-disk']} 
                                title={isActivityInfoUpdated ? 'Activity is upToDate' : 'Save activity'}
                            />
                        </XButton>
                        <StyledRedXButton
                            onClick={() => deleteActivityTranslation(activityInfo.id,'ACTIVITY', langTabChecked)} 
                            size='small' style={{minWidth: 'unset',display: `${langTabChecked !== activityInfo.language_default ? 'block' : 'none'}`}}
                        >
                            <StyledFontAwesomeIcon 
                                size='1x' 
                                icon={['far', 'trash-can']} 
                                title={'Delete translation'}
                            />
                        </StyledRedXButton>
                    </div>
                </StyledHeaderContainer>
                
                {(activityInfo?.id && activityInfo?.id !== 0 && activityInfo?.type) &&
                    <div style={{ overflowY: 'auto', height: 'calc(100vh - (177px + 2.2em))'}}>
                        {activityInfo?.language_default === langTabChecked ? 
                            (<>
                                {/* IDENTIFYING DATA */}
                                <IdentifyingData
                                    activityInfo={activityInfo}
                                    setActivityInfo={setActivityInfo}
                                    editActivityInfo={editActivityInfo}
                                    tags={tags}
                                    currentTag={currentTag}
                                    setCurrentTag={setCurrentTag}
                                    addNewTag={addNewTag}
                                />
                            
                                {/* ACTIVITY HEADER */}
                                <ActivityHeader
                                    activityTitle={activityInfo?.title || ""}
                                    editActivityInfo={editActivityInfo}
                                />

                                {/* ACTIVITY CONTENT */}
                                {ACTIVITIES_CONTENT_SETTINGS.SHOW_CONTENT.includes(activityInfo?.type) &&
                                    <ActivityContent
                                        setActivityInfo={setActivityInfo}
                                        setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                                        activityInfo={activityInfo}
                                        SETTINGS={ACTIVITIES_CONTENT_SETTINGS}
                                        TOOLTIPS={ACTIVITIES_TOOLTIPS}
                                    />
                                }

                                {/* ANSWERS */}
                                {ACTIVITIES_ANSWERS_TEMPLATE.FINISHED.includes(activityInfo?.type) &&
                                    <ActivityAnswers
                                        SETTINGS={ACTIVITIES_ANSWERS_OPTIONS_SETTINGS}
                                        TEMPLATE={ACTIVITIES_ANSWERS_TEMPLATE}
                                        TOOLTIPS={ACTIVITIES_TOOLTIPS}
                                        userActivityId={userActivityId}
                                        activityInfo={activityInfo}
                                        setActivityInfo={setActivityInfo}
                                        setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                                        saveActivity={saveActivity}
                                    />
                                }

                                {/* CUSTOMIZABLE PROPERTIES */}
                                {ACTIVITIES_PROPERTIES_SETTINGS.SHOW_PROPERTIES.includes(activityInfo?.type) &&
                                    <ActivityProperties
                                        SETTINGS={ACTIVITIES_PROPERTIES_SETTINGS}
                                        activityInfo={activityInfo}
                                        setActivityInfo={setActivityInfo}
                                        setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                                        userActivityId={userActivityId}
                                    />
                                }

                                {/* GLOBAL FEEDBACK */}
                                {ACTIVITIES_GLOBAL_FEEDBACK.includes(activityInfo?.type) &&
                                    <GlobalFeedback
                                        TOOLTIPS={ACTIVITIES_TOOLTIPS}
                                        activityInfo={activityInfo}
                                        setActivityInfo={setActivityInfo}
                                        setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                                        handleInfoUpdated={handleInfoUpdated}
                                    />
                                }
                            </>) : (
                                <ActivityTranslations
                                    activityInfo={activityInfo}
                                    setActivityInfo={setActivityInfo}
                                    langTabChecked={langTabChecked}
                                />
                            )
                        }
                        
                    </div>
                }
                </>
                }

                
                
            </XContainerSidebar>
            <StyledXContainerPreview bgColor="500">
                {ACTIVITIES_PREVIEWS.SHOW_PREVIEW.includes(activityInfo?.type) 
                    ? <Preview typeOfPreview='activity' getUserId={getUserActivityId} userId={userActivityId} />
                    : <SamplePreview 
                        type={activityInfo?.type} 
                        showPreview={(ACTIVITIES_PREVIEWS.UNDER_CONSTRUCTION.includes(activityInfo?.type) || !ACTIVITIES_PREVIEWS.NOT_AVAILABLE.includes(activityInfo?.type)) ? true : false}
                      />
                }
            </StyledXContainerPreview>
        </XContainerPage >
    )
}

export default ActivityCreation;
