import React from 'react';
import { XCard } from '@ximdex/xui-react/material';
import { CorrespondenceAnswers } from './Answers/CorrespondenceAnswers';
import { StandardAnswers } from './Answers/StandardAnswers';
import { WordCollectionAnswers } from './Answers/WordCollectionAnswers';
import SeekAndFindAnswer from './Answers/SeekAndFindAnswer';
import FlashCardAnswer from './Answers/FlashCardAnswer';
import FroggyJumpAnswer from './Answers/FroggyJumpAnswer';

export const ActivityAnswers = ({
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated,
    userActivityId,
    SETTINGS,
    TEMPLATE,
    TOOLTIPS,
    saveActivity,
    ...props
}) => {
    const ACTIVITY_TYPE = activityInfo?.type;

    if (TEMPLATE.STANDARD.includes(ACTIVITY_TYPE)) {
        return (
            <StandardAnswers
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                ACTIVITY_TYPE={ACTIVITY_TYPE}
                SETTINGS={SETTINGS}
                TOOLTIPS={TOOLTIPS}
            />
        )
    } else if (TEMPLATE.CORRESPONDENCE.includes(ACTIVITY_TYPE)) {
        return (
            <CorrespondenceAnswers
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                ACTIVITY_TYPE={ACTIVITY_TYPE}
                SETTINGS={SETTINGS}
            />
        )
    } else if (TEMPLATE.WORD_COLLECTION.includes(ACTIVITY_TYPE)) {
        return (
            <WordCollectionAnswers
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                ACTIVITY_TYPE={ACTIVITY_TYPE}
                SETTINGS={SETTINGS}
            />
        )
    } else if (TEMPLATE.SELECTION_IMAGE.includes(ACTIVITY_TYPE)) {
        return (
            <SeekAndFindAnswer
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                userActivityId={userActivityId}
                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                saveActivity={saveActivity}
                TOOLTIPS={TOOLTIPS}
            />
        )
    } else if (TEMPLATE.FLASH_CARD.includes(ACTIVITY_TYPE)) {
        return (
            <FlashCardAnswer
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                TOOLTIPS={TOOLTIPS}
            />
        )
    } else if (TEMPLATE.FROGGY_JUMP.includes(ACTIVITY_TYPE)) {
        return (
            <FroggyJumpAnswer
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                setIsActivityInfoUpdated={setIsActivityInfoUpdated}
            />
        )
    } else {
        return (
            <XCard
                isCollapsable={false}
                title='Answers'
                marginXSize='m'
            >
                <p style={{ textAlign: 'center', color: 'blue' }}>Area Under Construction</p>
            </XCard>
        )
    }
};

