
import 'tinymce'

import './plugins'
import './theme'
import './icons'
import './styles'

import { addDamLoaderPlugin, hasDamLoaderPlugin } from './custom/plugins/damloader'

import { Editor } from '@tinymce/tinymce-react';
import { getConfigTinyMCE } from './config'
// import GalleryModal from '../Gallery/GalleryModal'
import { XGallery } from '@ximdex/xui-react/material'
import { getAllExistingMedia, postMediaToCMS } from '../../services/xevalApi.service'
import { MEDIA_PREVIEW } from '../../CONSTANTS'
import { useState } from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'

function EditorTinyMCE({handleEditorChange, config = {}, value, readonly, initialValue}) {
  const configTinyMCE = getConfigTinyMCE(config)
  const [isOpen, setOpen] = useState(false);
  const [editor, setEditor] = useState({})
  

  const handleSelectMediaGallery = ({url, type,  width, height, altText}) => {
    const str = type === 'image'
      ? `<img src="${url}" alt="${altText ?? ''}" title="${altText ?? ''}" width="${width}" height="${height}"/>`
      : `<video controls><source src="${url}" type="video/mp4" alt="${altText ?? ''}" title="${altText ?? ''}"/></video>`
      
      editor?.insertContent(str);
      return {url, type,  width, height, altText};
  }

  const toggleModal = () => setOpen(!isOpen)

  const hasDamLoader = useMemo(() => hasDamLoaderPlugin(configTinyMCE.plugins), [configTinyMCE.plugins])

  useEffect(() => {
    if (hasDamLoader) {
      addDamLoaderPlugin(toggleModal, setEditor)
    }
  }, [hasDamLoader])

  return (
    <> 
      {/* <GalleryModal
          isOpen={isOpen}
          onCloseModal={toggleModal}
          onSelect={handleSelectMediaGallery}
          insideEditor={true}
      /> */}
      <XGallery
        isGalleryOpen={isOpen}
        setIsGalleryOpen={setOpen}
        fetchMedia={getAllExistingMedia}
        selectMedia={handleSelectMediaGallery}
        addNewMedia={postMediaToCMS}
        mediaPreview={MEDIA_PREVIEW} 
        showExtraDetails
      />
      <Editor
        disabled={readonly}
        initialValue={initialValue ? initialValue : null}
        init={configTinyMCE}
        onEditorChange={handleEditorChange}
        value={value}
      />
    </>
  )
}

export default EditorTinyMCE
