const theme = {
    palette: {
        mode: 'light',
        primary: {
            main: '#214F61',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#214f61',
        },
        background: {
            default: "#eee",
            paper: "white"
        }

    },
    properties: {
        padding: {
            sm: '1rem'
        }
    }
}

export default theme;
