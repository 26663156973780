import React, { useEffect, useState } from 'react';
import { 
    StyledSearchContainer, 
    StyledXButtonS,
    StyledFontAwesomeIcon,
    StyledFilterXCard,
    StyledFilterInputDiv,
} from '../../Styled-Components';
import { 
    XCard, 
    XRadio, 
    XDropdown, 
    XInput, 
    XDateRangeCalendar 
} from '@ximdex/xui-react/material';
import { 
    CLIENT,
    LANGUAGES_OPTIONS_SHOW_ALL, 
    STATUS_OPTIONS, 
    ACTIVITIES_LIST_ALL_TYPES,
    UNITS_OPTIONS
} from '../.././CONSTANTS';

const ActivitiesSearchColumn = ({setActivitiesValues,activitiesValues, unitsValues, setUnitsValues, searchParams, updateSearchParams }) => {
    const [temporaryISBN, setTemporaryISBN] = useState('')
   

    const handleChangeActivities = (values) => {
        const valuesArray = values.map( value => value.value)
        updateSearchParams('type', valuesArray.join(','))
        
        const valuesOptionsArray = valuesArray.map((activityParam) => {
            return ACTIVITIES_LIST_ALL_TYPES.find(option => option.value === activityParam)
        })
        setActivitiesValues(valuesOptionsArray)
    }

    const handleChangeUnits = (values) => {
        const valuesArray = values.map( value => value.value)
        updateSearchParams('units', valuesArray.join(','))

        const valuesOptionsArray = valuesArray.map((unitParam) => {
            return UNITS_OPTIONS.find(option => option.value === unitParam)
        })
        setUnitsValues(valuesOptionsArray)
    }

    const handleKeyPress = (e, param, value) => {
        if (e.key === 'Enter') {
            updateSearchParams(param, value)
            setTemporaryISBN("")
        }
    }

    const handleClickISBNSearch = (temporaryISBN) => {
        updateSearchParams('isbn', temporaryISBN)
        setTemporaryISBN("")
    }


    return (
        <StyledSearchContainer>

            {/* DATE RANGE
            <XCard
                style={{ padding: '0.25em 1em'}}
                isCollapsable={true}
                isCollapsed={true}
                title='Date range'
                marginXSize='m'
            >
                <p>Date range integration pending</p>
                    <XDateRangeCalendar
                        value={extraSearchParams.date}
                        onChange={(newValue) => handleChange('date', [newValue.selection])}
                    /> 
            </XCard> */}


            {/* STATUS */}
            <StyledFilterXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Status'
            >
                <XRadio
                    direction='column'
                    value={searchParams.get('status') ? searchParams.get('status') : 'ALL'}
                    onChange={(e) => updateSearchParams('status', e.target.value)}
                    options={STATUS_OPTIONS}
                    paddingXSize='s'
                    style={{paddingBottom: '0.5em'}}
                />
            </StyledFilterXCard>


            {/* LANGUAGE*/}
            <StyledFilterXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Default language'
            >
                <XRadio
                    direction='column'
                    value={searchParams.get('language_default') ? searchParams.get('language_default') : 'ALL'}
                    onChange={(e) => updateSearchParams('language_default', e.target.value)}
                    options={LANGUAGES_OPTIONS_SHOW_ALL}
                    paddingXSize='s'
                    style={{paddingBottom: '0.5em'}}
                />
            </StyledFilterXCard>


            {/* TYPE */}
            <StyledFilterXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Activity types'
            >
                <XDropdown
                    onChange={(e, values) => handleChangeActivities(values)}
                    value={activitiesValues}
                    options={ACTIVITIES_LIST_ALL_TYPES}
                    multiple={true}
                    hasCheckboxes={true}
                    labelOptions='label'
                    width='100%'
                    bgColor='100'
                    size='small'
                    style={{ paddingBottom: '0.5em' }}
                />
            </StyledFilterXCard>

            {/* CATEGORY */}
            {/* <XCard
                style={{ padding: '0.25em 1em'}}
                isCollapsable={true}
                isCollapsed={true}
                title='Category'
                marginXSize='m'
            >
                <p>Category integration pending</p>
            </XCard> */}
             {/* IS EXAM - MHE */}
            {/* <XCard
                isCollapsable={true}
                title='Is exam'
                marginXSize='m'
            >
                <XRadio
                    value={'id'}
                    handleChange={(e) => handleChange('isExam',e.currentTarget.value)}
                    options={sortingOptions}
                    paddingXSize='m'
                />
            </XCard> */}
            {CLIENT === 'MHE' && 
                (<>
                    {/* ISBN */}
                    <StyledFilterXCard
                        isCollapsable={true}
                        isCollapsed={false}
                        title='ISBN'
                    >
                        <StyledFilterInputDiv>
                            <XInput
                                value={temporaryISBN}
                                id='isbn'
                                size='small'
                                fullWidth
                                style={{ margin: '0 6px 0 0' }}
                                onChange={(e) => setTemporaryISBN(e.target.value)}
                                onKeyDown={(e) => handleKeyPress(e, 'isbn', temporaryISBN)}
                            />
                            <StyledXButtonS onClick={() => handleClickISBNSearch(temporaryISBN)} variant='outlined'>
                                <StyledFontAwesomeIcon icon={['fa','search']} size='1x' title='Search by ISBN.' />
                            </StyledXButtonS>
                        </StyledFilterInputDiv>
                    </StyledFilterXCard>
                    {/* UNITS */}
                    <StyledFilterXCard
                        isCollapsable={true}
                        isCollapsed={false}
                        title='Units'
                    >
                        <XDropdown
                            onChange={(e, values) => handleChangeUnits(values)}
                            value={unitsValues}
                            options={UNITS_OPTIONS}
                            multiple={true}
                            hasCheckboxes={true}
                            labelOptions='label'
                            width='100%'
                            bgColor='100'
                            size='small'
                            style={{ paddingBottom: '0.5em' }}
                        />
                    </StyledFilterXCard>
                </>)
            }
           
        </StyledSearchContainer>
    )
}

export default ActivitiesSearchColumn;
