import { contentCss, contentUiCss }  from './styles'

const DEFAULT_CONFIG_TINYMCE = {
  max_num_characters: 100,
  skin: false,
  content_css: false,
  content_style: [contentCss, contentUiCss].join('\n'),
  menubar: false,
  height: '14em',
  plugins: [
    'mathjax, FMathEditor, fontselect, fontsize',
    'advlist, autolink, link, image, lists, hr, anchor, searchreplace, code, fullscreen, insertdatetime, media, nonbreaking, table, template, help, wordcount, charmap',
  ],
  toolbar: [
    // " FMathEditorButton mathjax | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
    // " hr nonbreaking | code anchor image media insertdatetime list table |  help fullscreen  |  charmap  image"
  ],
  fontsize_formats: "8pt 10pt 12pt 14pt 18pt",
  font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Cursive=cursive; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;",
  statusbar: [
    "wordcount.characters"
  ],
  external_plugins: {
    // mathjax: '/vendor/@dimakorotkov/tinymce-mathjax/plugin.min.js',
    FMathEditor: '/vendor/FMathEditor/plugin.js'
  },
  // mathjax: {
  //     lib: '/vendor/mathjax/es5/tex-mml-chtml.js'
  // },
  init_instance_callback: function (editor) {
    var $wordcount = editor.getContainer().querySelector('button.tox-statusbar__wordcount')
    $wordcount.click()
    $wordcount.disabled = true; 
  },
  setup: function(editor) {
    var eventFunc = function (event) {
      var numChars = editor.plugins.wordcount.body.getCharacterCount();
      var maxNumChars = editor.settings.max_num_characters

      var charactersStatusBar = editor.getContainer().querySelector('button.tox-statusbar__wordcount');

      if (numChars > maxNumChars && charactersStatusBar.style.color !== 'red') {
        charactersStatusBar.style.color = 'red';
      }
      if (numChars < maxNumChars && charactersStatusBar.style.color !== 'black') {
        charactersStatusBar.style.color = 'black'
      }
    }
    editor.on('input SetContent', eventFunc)
  }
}

function getConfigTinyMCE(config = {}) {
  let output = {
    ...DEFAULT_CONFIG_TINYMCE,
    ...config
  };

  if (config.plugins) {
    output.plugins = [
      ...DEFAULT_CONFIG_TINYMCE.plugins,
      ...config.plugins
    ]
  }

  if (config.external_plugins) {
    output.external_plugins = [
      ...DEFAULT_CONFIG_TINYMCE.external_plugins,
      ...config.external_plugins
    ]
  }

  if (config.toolbar) {
    output.toolbar = [
      ...DEFAULT_CONFIG_TINYMCE.toolbar,
      ...config.toolbar
    ]
  }

  if (config.fontselect_formats) {
    output.fontselect_formats = [
      ...DEFAULT_CONFIG_TINYMCE.fontselect_formats,
      ...config.fontselect_formats
    ]
  }

  if (config.content_style) {
    output.content_style += `\n ${config.content_style}`
  }

  return output
}

export { getConfigTinyMCE };