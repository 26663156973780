import React, { useState } from 'react';
import { getAllExistingMedia, postMediaToCMS } from '../../services/xevalApi.service';
import { MEDIA_PREVIEW } from '../../CONSTANTS';
import { XCard, XGallery } from '@ximdex/xui-react/material';
import { StyledFontAwesomeIcon, ExtraInfoText } from '../../Styled-Components';
// import GalleryModal from '../../components/Gallery/GalleryModal';
import EditorTinyMCE from '../../components/TinyMCE/EditorTinyMCE';

const ActivityContent = ({
    setActivityInfo,
    setIsActivityInfoUpdated,
    activityInfo,
    SETTINGS,
    TOOLTIPS
}) => {
    const maxChar = activityInfo?.type === 'input-long' ? 10000 : 450
    const [isOpen, setIsOpen] = useState(false);
    const [activityImg, setActivityImg] = useState(activityInfo?.image_url);

    // const toggleModal = () => {
    //     setIsOpen(!isOpen);
    // };

    const editActivityImg = (media) => {
        console.log(media)
    }

    const handleTextChange = (evt, editor) => {
        let newInfo = { ...activityInfo }
        newInfo.text = evt

        setActivityInfo(newInfo)
        setIsActivityInfoUpdated(false)
    }

    return (
        <XCard
            isCollapsable={true}
            title='Content'
            marginXSize='m'
            tooltip={
                TOOLTIPS.INSTRUCTIONS.CONTENT.hasOwnProperty(activityInfo?.type)
                    ? <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.CONTENT[activityInfo?.type]} size='1x' isInfo />
                    : false
            }
        >
            <EditorTinyMCE
                value={activityInfo?.text}
                handleEditorChange={(evt, editor) => handleTextChange(evt)}
                config={{
                    max_num_characters: maxChar,
                    plugins: [
                        'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                        'FMathEditor, codesample, fontselect',
                        'damloader'
                    ],
                    toolbar: activityInfo?.type === 'input-long'
                    ? [`undo redo | damloader | charmap FMathEditorButton`]
                    : [`
                        undo redo | bold italic forecolor backcolor fontsizeselect | 
                        alignleft aligncenter alignright alignjustify | 
                        bullist numlist outdent indent | 
                        link damloader list table | 
                        charmap FMathEditorButton | 
                        removeformat
                    `],
                }}
            />

            {/* <XInput
                style={{ margin: '8px', width: '95%' }}
                id='text'
                label='Text'
                size='normal'
                fullWidth
                multiline
                rows={4}
                value={activityText}
                onChange={(e) => editActivityInfo(e)}
                inputProps={{ maxLength: 200 }}
            /> */}

            <ExtraInfoText>{maxChar} characters max</ExtraInfoText>

            {SETTINGS.MEDIA.includes(activityInfo.type) &&
                <>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            alt=''
                            style={{ cursor: 'pointer' }}
                            src={activityImg ? activityImg : "/assets/images/default_open_gallery.svg"}
                            onClick={() => setIsOpen(true)}
                            onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/default_open_gallery.svg' }}
                        />
                    </div>
                    {/* <GalleryModal
                        isOpen={isOpen}
                        onCloseModal={toggleModal}
                        onSelect={editActivityImg}
                    /> */}
                    <XGallery
                        isGalleryOpen={isOpen}
                        setIsGalleryOpen={setIsOpen}
                        fetchMedia={getAllExistingMedia}
                        selectMedia={editActivityImg}
                        addNewMedia={postMediaToCMS}
                        mediaPreview={MEDIA_PREVIEW} 
                    />
                </>
            }
        </XCard>
    )
}

export default ActivityContent;
