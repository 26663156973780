import React from 'react'
import { StyledFilterTags } from '../../Styled-Components'
import {
    UNITS_OPTIONS,
} from '../../CONSTANTS';

const AssessmentsTags = ({
    searchParams,
    updateSearchParams,
    setUnitsValues,
}) => {
    const search = searchParams.get('search') || ''
    const language_default = searchParams.get('language_default') || ''
    const isbn = searchParams.get('isbn') || ''
    const status = searchParams.get('status') || ''
    const units = searchParams.has('units') ? searchParams.get('units').split(',') : []

    const removeTag = (type, value) => {
        if(type === 'units'){
            const valuesArray = searchParams.get(type).split(',').filter(element => element !== value)
            const valuesOptionsArray = valuesArray.map((activityParam) => {
                return UNITS_OPTIONS.find(option => option.value === activityParam)
            })
            setUnitsValues(valuesOptionsArray)
            updateSearchParams(type, valuesArray.join(','))
        } else {
            updateSearchParams(type, '')
        }
    }

    return (
        <>
            {search &&
                <StyledFilterTags
                    isRemovable
                    name={`Search: ${search}`}
                    onClickRemove={() => removeTag('search')}
                />
            }
            {language_default &&
                <StyledFilterTags
                    isRemovable
                    name={`Language: ${language_default}`}
                    onClickRemove={() => removeTag('language_default')}
                />
            }
            {isbn &&
                <StyledFilterTags
                    isRemovable
                    name={`ISBN: ${isbn}`}
                    onClickRemove={() => removeTag('isbn')}
                />
            }
            {units.length > 0 &&
                units.map((unit, index) => (
                    <StyledFilterTags
                        key={`activityUnitTag_${index}`}
                        isRemovable
                        name={`Unit: ${unit}`}
                        onClickRemove={() => removeTag('units', unit)}
                    />
                ))
            }
            {status &&
                <StyledFilterTags
                    isRemovable
                    name={`Status: ${status}`}
                    onClickRemove={() => removeTag('status')}
                />
            }
        </>
    )
}

export default AssessmentsTags
