import ACTIVITY_TYPE from './Activities'

export const ACTIVITIES_PREVIEWS = {
    SHOW_PREVIEW: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.FILL_THE_BLANKS,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,
        ACTIVITY_TYPE.WORD_SEARCH,
        ACTIVITY_TYPE.WORD_CATCHER,
        ACTIVITY_TYPE.SEEK_AND_FIND,
        ACTIVITY_TYPE.CORRESPONDENCE,
        
        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.INPUT,
        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,
        
        ACTIVITY_TYPE.DEDUCTION,
    ],
    UNDER_CONSTRUCTION: [
        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.TEXT_PAIRING,
        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.CARD_CHOICE,
        ACTIVITY_TYPE.FLASH_CARDS,
        ACTIVITY_TYPE.FROGGY_JUMP,
    ],
    NOT_AVAILABLE: [
        ACTIVITY_TYPE.INPUT_LONG,
    ],
}

export const ACTIVITIES_TOOLTIPS = {
    INSTRUCTIONS: {
        GLOBAL_FEEDBACK: 'Success % is the minimum percentage (0 to 100) you need to achieve in order to get the comment on the right as your feedback.',
        CONTENT: {
            [ACTIVITY_TYPE.FIT_WORD]: 'Write down the sentence for this activity. For each blank you should include the number of the blank inside curly brackets as such: {1}',
            [ACTIVITY_TYPE.DEDUCTION]: 'Write down the sentence for this activity. For each blank you should include the number of the blank inside curly brackets as such: {1}',
            [ACTIVITY_TYPE.FILL_THE_BLANKS]: 'Write down the sentence for this activity. For each blank you should include the number of the blank inside curly brackets as such: {1}',
            [ACTIVITY_TYPE.INPUT_LONG]: 'It is important to note that any image and/or formula you add will be extracted and shown below the actual text in the exam generator.',
        },
        IMAGES: {
            [ACTIVITY_TYPE.SEEK_AND_FIND]:'1:1 is the ideal image ratio for this activity (square)',
            [ACTIVITY_TYPE.ORDER_CHRONOLOGY]:'3:5 is the ideal image ratio for this activity (portrait)',
            
            [ACTIVITY_TYPE.SHORT_QUESTION]:'3:2 is ideal image ratio for this activity (landscape)',
            [ACTIVITY_TYPE.FLASH_CARDS]:'8:3 is the ideal image ratio for this activity (landscape)',
            [ACTIVITY_TYPE.IMAGE_PAIRING]:'1:1 is the ideal image ratio for this activity (square)',
            [ACTIVITY_TYPE.IMAGE_CHOICE]:'2:3 is the ideal image ratio for this activity (portrait)',
        },
        MATRIX_SIZE: "If you change the matrix size, be aware that the options inside the 'correct word collection' will be verified.\nThe words found to not fit inside the new matrix will be automatically removed in order to avoid issues."
    }
}

export const ACTIVITIES_CONTENT_SETTINGS = {
    SHOW_CONTENT: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.FILL_THE_BLANKS,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,
        ACTIVITY_TYPE.CORRESPONDENCE,
        
        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.INPUT,
        ACTIVITY_TYPE.INPUT_LONG,
        ACTIVITY_TYPE.FIT_WORD,
        
        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.CORRESPONDENCE,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ],
    TEXT: {},
    MEDIA: [],
}

export const ANSWERS_TEMPLATE = {
    STANDARD: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,

        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,

        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.TEXT_PAIRING,
        ACTIVITY_TYPE.CARD_CHOICE,
    ],
    WORD_COLLECTION: [
        ACTIVITY_TYPE.FILL_THE_BLANKS,
        ACTIVITY_TYPE.WORD_SEARCH,
        ACTIVITY_TYPE.WORD_CATCHER,

        ACTIVITY_TYPE.INPUT,
        ACTIVITY_TYPE.INPUT_LONG,
    ],
    CORRESPONDENCE: [
        ACTIVITY_TYPE.CORRESPONDENCE,
    ],
    SELECTION_IMAGE: [
        ACTIVITY_TYPE.SEEK_AND_FIND
    ],
    FLASH_CARD: [
        ACTIVITY_TYPE.FLASH_CARDS
    ],
    FROGGY_JUMP: [
        ACTIVITY_TYPE.FROGGY_JUMP
    ],
    FINISHED: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.FILL_THE_BLANKS,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,
        ACTIVITY_TYPE.CORRESPONDENCE,
        ACTIVITY_TYPE.WORD_SEARCH,
        ACTIVITY_TYPE.WORD_CATCHER,
        ACTIVITY_TYPE.SEEK_AND_FIND,

        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.INPUT,
        ACTIVITY_TYPE.INPUT_LONG,
        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,

        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.CARD_CHOICE,
        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.TEXT_PAIRING,
        ACTIVITY_TYPE.FLASH_CARDS,
        ACTIVITY_TYPE.FROGGY_JUMP,
    ]
}

export const ACTIVITIES_ANSWERS_OPTIONS_SETTINGS = {
    ACTIVITIES: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.FILL_THE_BLANKS,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,
        ACTIVITY_TYPE.WORD_SEARCH,
        ACTIVITY_TYPE.WORD_CATCHER,
        ACTIVITY_TYPE.SEEK_AND_FIND,
        ACTIVITY_TYPE.CORRESPONDENCE,
    ],
    INDIVIDUAL_TITLE: {
        [ACTIVITY_TYPE.SINGLE_CHOICE]: 'Option',
        [ACTIVITY_TYPE.MULTIPLE_CHOICE]: 'Option',
        [ACTIVITY_TYPE.CORRESPONDENCE]: 'Card',
        [ACTIVITY_TYPE.ORDER_CHRONOLOGY]: 'Order',

        [ACTIVITY_TYPE.FIT_WORD]: 'Answer for blank',
        [ACTIVITY_TYPE.RANK]: 'Match',
        [ACTIVITY_TYPE.MATCH]: 'Match',

        [ACTIVITY_TYPE.DEDUCTION]: 'Answer for blank',
        [ACTIVITY_TYPE.SHORT_QUESTION]: 'Card',
        [ACTIVITY_TYPE.TEXT_CHOICE]: 'Card',
        [ACTIVITY_TYPE.IMAGE_CHOICE]: 'Card',
        [ACTIVITY_TYPE.CARD_CHOICE]: 'Card',
        [ACTIVITY_TYPE.IMAGE_PAIRING]: 'Pair',
        [ACTIVITY_TYPE.TEXT_PAIRING]: 'Pair'
    },
    INDIVIDUAL_CONTROLS: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.CORRESPONDENCE,

        ACTIVITY_TYPE.RANK,
        ACTIVITY_TYPE.MATCH,

        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.TEXT_PAIRING,
    ],
    INDIVIDUAL_CONTROLS_TRASHCAN: [
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.TEXT_CHOICE,
    ],
    INDIVIDUAL_COLLAPSABLE: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.CORRESPONDENCE,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,

        ACTIVITY_TYPE.RANK,
        ACTIVITY_TYPE.MATCH,

        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.CARD_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.TEXT_PAIRING,
    ],
    EXCLUDE_ADD_ANOTHER_ANSWER: [
        ACTIVITY_TYPE.RANK
    ],
    EXCLUDE_MARK_AS_CORRECT: [
        ACTIVITY_TYPE.RANK
    ],
    ALIGN_CENTER_TEXT: [
        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.TRUE_FALSE,
    ],
    ROWS_TEXT: {
        [ACTIVITY_TYPE.SINGLE_CHOICE]: 4,
        [ACTIVITY_TYPE.MULTIPLE_CHOICE]: 4,
        [ACTIVITY_TYPE.TRUE_FALSE]: 1,
        'correspondence-options': 1,
        'correspondence-targets': 4,

        [ACTIVITY_TYPE.RANK]: 3,
        [ACTIVITY_TYPE.FIT_WORD]: 1,
        [ACTIVITY_TYPE.MATCH]: 3,

        [ACTIVITY_TYPE.DEDUCTION]: 1,
        [ACTIVITY_TYPE.SHORT_QUESTION]: 2,
        [ACTIVITY_TYPE.OPINION_SCALE]: 1,
        [ACTIVITY_TYPE.TEXT_CHOICE]: 2,
        [ACTIVITY_TYPE.CARD_CHOICE]: 2,
        [ACTIVITY_TYPE.TEXT_PAIRING]: 2,
        [ACTIVITY_TYPE.ORDER_CHRONOLOGY]: 3
    },
    ROWS_TEXT_WITH_SINGLE_TARGET: [
        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.RANK,
        ACTIVITY_TYPE.TEXT_PAIRING,
    ],
    LIMIT_OPTION_TEXT: {
        [ACTIVITY_TYPE.SINGLE_CHOICE]: 350,
        [ACTIVITY_TYPE.MULTIPLE_CHOICE]: 350,
        [ACTIVITY_TYPE.TRUE_FALSE]: 60, // to be revised
        'correspondence-options': 30,
        'correspondence-targets': 175,

        [ACTIVITY_TYPE.FIT_WORD]: 60,
        [ACTIVITY_TYPE.RANK]: 105,

        [ACTIVITY_TYPE.DEDUCTION]: 60,  // to be revised
        [ACTIVITY_TYPE.SHORT_QUESTION]: 60,
        [ACTIVITY_TYPE.OPINION_SCALE]: 18,
        [ACTIVITY_TYPE.TEXT_CHOICE]: 125,
        [ACTIVITY_TYPE.CARD_CHOICE]: 100,
        [ACTIVITY_TYPE.TEXT_PAIRING]: 60,
    },
    USE_TINY_MCE: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
    ],
    MEDIA: [
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,

        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ],
    MEDIA_OPTIONS_WITH_SINGLE_TARGET: [
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ],
    IS_RIGHT: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.CORRESPONDENCE,

        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.CARD_CHOICE,
    ],
    TARGET_UNACCEPTED_WORDS: [
        ACTIVITY_TYPE.WORD_CATCHER
    ],
    INDIVIDUAL_FEEDBACK: {
    },
    ADD_NEW_OPTION: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.CORRESPONDENCE,
        
        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,

        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.TEXT_PAIRING,
    ],
    ADD_NEW_OPTION_LIMITS: {
        [ACTIVITY_TYPE.IMAGE_PAIRING]: { MIN: 2, MAX: 4 },
        [ACTIVITY_TYPE.TEXT_CHOICE]: { MIN: 2, MAX: 4 },
        [ACTIVITY_TYPE.IMAGE_CHOICE]: { MIN: 2, MAX: 4 },
        [ACTIVITY_TYPE.TEXT_PAIRING]: { MIN: 2, MAX: 4 },
    },
    ADD_NEW_TARGET: [
        ACTIVITY_TYPE.FILL_THE_BLANKS
    ],
    DELETE_LAST: [
        ACTIVITY_TYPE.FILL_THE_BLANKS,

        ACTIVITY_TYPE.FIT_WORD,

        ACTIVITY_TYPE.DEDUCTION,
    ],
    SWITCH_THREE_FIVE: [
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,

        ACTIVITY_TYPE.OPINION_SCALE,
    ],
    MODIFY_OPTIONS_STANDARD: [
        ACTIVITY_TYPE.SINGLE_CHOICE,
        ACTIVITY_TYPE.MULTIPLE_CHOICE,
        ACTIVITY_TYPE.TRUE_FALSE,
        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.CARD_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.TEXT_CHOICE,
    ],
    MODIFY_OPTIONS_WITH_SINGLE_TARGET: [
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,

        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,
        ACTIVITY_TYPE.MATCH,

        ACTIVITY_TYPE.IMAGE_PAIRING,
        ACTIVITY_TYPE.TEXT_PAIRING,
        ACTIVITY_TYPE.DEDUCTION,
    ],
    TARGETS: [
        ACTIVITY_TYPE.FILL_THE_BLANKS,
        ACTIVITY_TYPE.CORRESPONDENCE,
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,

        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,
        ACTIVITY_TYPE.MATCH,
        
        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ],
    TO_SORT: [
        ACTIVITY_TYPE.ORDER_CHRONOLOGY,
        ACTIVITY_TYPE.FILL_THE_BLANKS,

        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.MATCH,

        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.TEXT_PAIRING,
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ],
    TO_SORT_OPTIONS_ONLY: [
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ]
}

export const ACTIVITIES_PROPERTIES_SETTINGS = {
    SHOW_PROPERTIES: [
        ACTIVITY_TYPE.SEEK_AND_FIND,
        ACTIVITY_TYPE.WORD_CATCHER,
        ACTIVITY_TYPE.WORD_SEARCH,

        ACTIVITY_TYPE.CARD_CHOICE,
    ],
    SEEK_AND_FIND_OPTIONS: [
        ACTIVITY_TYPE.SEEK_AND_FIND
    ],
    TIMER: [
        ACTIVITY_TYPE.SEEK_AND_FIND,
        ACTIVITY_TYPE.WORD_CATCHER,
        ACTIVITY_TYPE.WORD_SEARCH
    ],
    MATRIX_SIZE: [
        ACTIVITY_TYPE.WORD_SEARCH
    ],
    MATRIX_RULES: [
        ACTIVITY_TYPE.WORD_SEARCH
    ],
    BACKGROUND: {
        [ACTIVITY_TYPE.WORD_CATCHER]: [
            { 'title': 'Sunset in sandy beach', 'image_num': 1, 'image_index': 0, 'is_external': false, 'image': '/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png' },
            { 'title': 'Relaxed sunny beach', 'image_num': 2, 'image_index': 1, 'is_external': false, 'image': '/assets/images/word-catcher/relaxed-sunny-beach-standard-theme.png' },
            { 'title': 'Clear blue water', 'image_num': 3, 'image_index': 2, 'is_external': false, 'image': '/assets/images/word-catcher/clear-blue-water-standard-theme.png' },
            { 'title': 'Beach house decor', 'image_num': 4, 'image_index': 3, 'is_external': false, 'image': '/assets/images/word-catcher/beach-house-decor-standard-theme.png' },
            { 'title': 'Custom', 'is_external': true },
        ],
        [ACTIVITY_TYPE.CARD_CHOICE]: [
            { 'title': 'Pirates of the Troposphere', 'theme': 'PIRATES', 'image': 6, 'image_url': '/assets/images/card-choice/6.png', 'icon_image_url': '/assets/images/card-choice/barco.png' },
            { 'title': 'Blast off!', 'theme': 'SPACE', 'image': 2, 'image_url': '/assets/images/card-choice/2.png', 'icon_image_url': '/assets/images/card-choice/nave.png' },
            { 'title': 'Is that a flying car?', 'theme': 'MOUNTAINS', 'image': 4, 'image_url': '/assets/images/card-choice/4.png', 'icon_image_url': '/assets/images/card-choice/coche.png' },
        ]
    },
    SPEED: {
        [ACTIVITY_TYPE.WORD_CATCHER]: [
            { 'title': 'Slow', 'value': 'slow', 'values': {'min': 0.5, 'max': 1} },
            { 'title': 'Normal', 'value': 'normal', 'values': {'min': 0.5, 'max': 1.5}},
            { 'title': 'Fast', 'value': 'fast', 'values': {'min': 1, 'max': 2} },
            { 'title': 'Faster', 'value': 'faster', 'values': {'min': 2.5, 'max': 3.5} },
        ],
    },
    COLOR: [
        ACTIVITY_TYPE.WORD_CATCHER
    ]

}

export const ACTIVITIES_GLOBAL_FEEDBACK = [
    ACTIVITY_TYPE.FILL_THE_BLANKS,
    ACTIVITY_TYPE.ORDER_CHRONOLOGY,
    ACTIVITY_TYPE.WORD_SEARCH,
    ACTIVITY_TYPE.WORD_CATCHER,
    ACTIVITY_TYPE.SEEK_AND_FIND,
    ACTIVITY_TYPE.CORRESPONDENCE,

    ACTIVITY_TYPE.MATCH,
    ACTIVITY_TYPE.INPUT,
    ACTIVITY_TYPE.FIT_WORD,
    ACTIVITY_TYPE.RANK,

    ACTIVITY_TYPE.DEDUCTION,
    ACTIVITY_TYPE.OPINION_SCALE,
    ACTIVITY_TYPE.TEXT_CHOICE,
    ACTIVITY_TYPE.IMAGE_CHOICE,
    ACTIVITY_TYPE.TEXT_PAIRING,
    ACTIVITY_TYPE.IMAGE_PAIRING,
    ACTIVITY_TYPE.FLASH_CARDS,
    ACTIVITY_TYPE.FROGGY_JUMP,
]


export const OPTIONS_WITH_TINY_EDITOR = [
    ACTIVITY_TYPE.SINGLE_CHOICE,
    ACTIVITY_TYPE.MULTIPLE_CHOICE,
    ACTIVITY_TYPE.TRUE_FALSE,
]
