import CLIENTS from "../types/clients";
import defaultLight from "./default/light";
import defaultDark from "./default/dark";


const themes = {
    [CLIENTS.DEFAULT]: {
        name: "default",
        light: defaultLight,
        dark: defaultDark
    }
}

export default themes;
