import React, { useState, useEffect } from 'react';
import { getAllExistingMedia, postMediaToCMS } from '../../services/xevalApi.service';
import { XCard, XSwitch, XInput, XDropdown, XGallery } from '@ximdex/xui-react/material';
import { StyledLabelInput, StyledXLabelLeft, StyledFontAwesomeIcon } from '../../Styled-Components';
import { MATRIX_SIZE_OPTIONS, ACTIVITIES_TOOLTIPS, MEDIA_PREVIEW } from '../../CONSTANTS';
// import GalleryModal from './../../components/Gallery/GalleryModal';

const ActivityProperties = ({
    SETTINGS,
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated,
    userActivityId
}) => {

    const handleDefaultSpeed = () => {
        let speed = undefined;

        if(SETTINGS.SPEED.hasOwnProperty(activityInfo?.type)){
            if (activityInfo?.props?.speed?.max) {
                speed = SETTINGS.SPEED[activityInfo?.type]?.find(element => element?.values?.max === activityInfo?.props?.speed?.max);
            } else {
                speed = SETTINGS.SPEED[activityInfo?.type][1];
                setIsActivityInfoUpdated(false);
            }
        }
        return speed;
    }

    const handleDefaultTheme = () => {
        let theme = undefined

        if (activityInfo?.type === 'word-catcher') {
            if (typeof activityInfo.props.background_image === 'number') {
                // xEval v1 activityInfo 
                theme = SETTINGS.BACKGROUND[activityInfo?.type].find(bg => bg.image_num === activityInfo.props.background_image)
            } else if (activityInfo.props.background_image?.image?.endsWith('-standard-theme.png') && !activityInfo.props.background_image?.is_external) {
                // xEval v2 activityInfo standard theme
                theme = SETTINGS.BACKGROUND[activityInfo?.type].find(bg => bg.image === activityInfo.props.background_image?.image)
            } else if ((activityInfo.props.background_image?.image?.startsWith('http') && activityInfo.props.background_image?.is_external)
                || (activityInfo.props.background_image?.image?.startsWith('@@@') && !activityInfo.props.background_image?.is_external)
            ) {
                // xEval v2 activityInfo external
                theme = SETTINGS.BACKGROUND[activityInfo?.type].find(bg => bg.title?.toUpperCase()?.endsWith('CUSTOM'))
                setIsBackgroundCustom(true)
            } else {
                theme = SETTINGS.BACKGROUND[activityInfo?.type][0];
                setIsActivityInfoUpdated(false);
            } 
        }
        
        if (activityInfo?.type === 'card-choice' && activityInfo?.background) {
            theme = SETTINGS.BACKGROUND[activityInfo?.type].find(bg => bg.theme === activityInfo?.background)
        }
        
        return theme
    }
    
    const [matrixSizeValue, setMatrixSizeValue] = useState(null);
    const [timeValue, setTimeValue] = useState(activityInfo?.props?.time ? parseInt(activityInfo?.props?.time) : 0);
    const [isBackgroundCustom, setIsBackgroundCustom] = useState(false)
    const [defaultSpeed, setDefaultSpeed] = useState(() => handleDefaultSpeed())
    const [defaultTheme, setDefaultTheme] = useState(() => handleDefaultTheme())
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (SETTINGS.MATRIX_SIZE.includes(activityInfo?.type)) {
            MATRIX_SIZE_OPTIONS.forEach((matrixOption) => {
                if (activityInfo?.matrix?.props?.size?.height === matrixOption.height) {
                    setMatrixSizeValue(matrixOption);
                }
            });
        }
    }, [activityInfo.matrix?.props?.size]);

    useEffect(() => {
        if (SETTINGS.MATRIX_RULES.includes(activityInfo?.type)) {
            if (!activityInfo.matrix?.props?.rules || Object.keys(activityInfo?.matrix?.props?.rules).length === 0) {
                setIsActivityInfoUpdated(false)
                // console.log(activityInfo?.matrix)
                // activityInfo?.matrix = activityInfo?.matrix ? {
                //     ...activityInfo?.matrix, 
                //     props: { 
                //         ...activityInfo?.matrix.props, 
                //         rules: {...basicMatrixRules}
                //     }
                // } : null
            };
        }
    }, [activityInfo.matrix?.props?.rules])

    const handleRuleSwitch = (e) => {
        const newInfo = { ...activityInfo };
        const currentValue = typeof e.target.value === 'string' ? JSON.parse(e.target.value.toLowerCase()) : e.target.value;

        newInfo.matrix.props.rules[e.target.id] = !currentValue;

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo)
    };

    const handleTimerSwitch = () => {
        const newInfo = { ...activityInfo }

        newInfo.props = {   
            ...newInfo.props, 
            time: newInfo.props?.time ? 0 : 120
        }

        setIsActivityInfoUpdated(false)
        setTimeValue(newInfo?.props?.time)
        setActivityInfo(newInfo)
    };

    const handleTimerChange = (e) => {
        const newValue = e.target?.value
        setTimeValue(newValue)
    }

    const handleTimerBlur = (e) => {
        const newInfo = { ...activityInfo } 
        const newValue = e.target?.value > 0 ? parseInt(e.target.value) : 0
        
        if (!newInfo.props?.time) {
            newInfo.props = { ...newInfo.props, time: newValue }
        } else {
            newInfo.props.time = newValue
        }

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo)
    }

    const handleMatrixSizeChange = (e, value) => {
        const newInfo = { ...activityInfo };

        if (!newInfo.matrix?.props?.size) {
            newInfo.matrix = {
                ...newInfo?.matrix,
                props: {
                    ...newInfo.matrix.props,
                    size: {
                        height: value.height,
                        width: value.width
                    }
                }
            }
        } else {
            newInfo.matrix.props.size.height = value.height;
            newInfo.matrix.props.size.width = value.width;
        };
        
        newInfo.targets[0].accepted_values = newInfo.targets[0].accepted_values.filter((word) => word.length <= value.width);

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    };

    const setBackgroundImage = (newInfo, value) => {
        const new_background_image = {
            is_external: value.is_external,
            image: value.image
        }

        if (!newInfo.props?.background_image) {
            newInfo.props = { ...newInfo.props, background_image: new_background_image }
        } else {
            newInfo.props.background_image = new_background_image;
        }

        return newInfo;
    }

    const handleBgImgChange = (e, value) => {
        let newInfo = { ...activityInfo }
        setDefaultTheme(value)

        if (value.title?.toLowerCase()?.endsWith('custom') || value?.is_external) {
            return setIsBackgroundCustom(true)
        } else {
            setIsBackgroundCustom(false)
        }

        if (newInfo.type === 'word-catcher' && !value?.is_external) {
            newInfo = setBackgroundImage(newInfo, value)
        } else if (newInfo.type === 'card-choice') {
            newInfo.background = value.theme
            // to be removed... confirm with Giovanni
            if (newInfo.props?.background_image) {
                delete newInfo.props.background_image
            }
        } 

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    };

    const handleCustomBgImgChange = (media) => {
        let newInfo = { ...activityInfo };

        const new_background_image = {
            is_external: media?.is_external ?? true,
            image: media?.path ?? media?.url
        };

        if (!newInfo.props?.background_image) {
            newInfo.props = { ...newInfo.props, background_image: new_background_image };
        } else {
            newInfo.props.background_image = new_background_image;
        };

        setIsActivityInfoUpdated(false);
        setActivityInfo(newInfo);
        return media;
    };

    const handleOnChangeColor = (e) => {
        let newInfo = { ...activityInfo }
        const color = e.target.value

        if (!newInfo.props?.word_color) {
            newInfo.props = { ...newInfo.props, word_color: color }
        } else {
            newInfo.props.word_color = color;
        }

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo)
    }

    const handleOnChangeSpeed = (event, value) => {
        let newInfo = { ...activityInfo }

        if (!newInfo.props?.speed) {
            newInfo.props = { ...newInfo.props, speed: value.values }
        } else {
            newInfo.props.speed = value.values;
        }
        
        setDefaultSpeed(value.values)
        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo)
    }

    return (
        <XCard
            isCollapsable={true}
            isCollapsed={false}
            title='Properties'
            marginXSize='m'
        >
            {/* RULES: diagonal, horizontal, vertical, inverse */}
            {SETTINGS.MATRIX_RULES.includes(activityInfo?.type) && activityInfo.matrix?.props?.rules &&
                Object.entries(activityInfo.matrix?.props?.rules).map((rule, index) => (
                    <StyledXLabelLeft
                        style={{ width: '100%' }}
                        label={rule[0].toString().charAt(0).toUpperCase() + rule[0].toString().slice(1).split("_").join(" ")}
                        paddingSize='s'
                        component=
                        {<XSwitch
                            id={`${rule[0].toString()}`}
                            value={rule[1] || false}
                            onChange={(e) => handleRuleSwitch(e)}
                        />}
                        labelLocation='left'
                    />
                ))
            }

            {/* TIMER */}
            {SETTINGS.TIMER.includes(activityInfo?.type) && <>
                <StyledXLabelLeft
                    style={{ width: '100%' }}
                    label='Timer'
                    paddingSize='s'
                    component=
                    {<XSwitch
                        id='timer'
                        key='timer-switch'
                        value={activityInfo.props?.time ? true : false}
                        onChange={() => handleTimerSwitch()}
                    />}
                    labelLocation='left'
                />
                <XInput
                    placeholder={activityInfo.props?.time ? 'Set time' : 'Timer disabled'}
                    style={{ margin: '4px 8px 16px 8px', width: 'calc(100% - 16px)' }}
                    id='timer-seconds'
                    key='timer-seconds'
                    size='small'
                    type='number'
                    fullWidth
                    value={timeValue}
                    min="0"
                    disabled={activityInfo.props?.time ? false : true}
                    onChange={(e) => handleTimerChange(e)}
                    onBlur={(e) => handleTimerBlur(e)}
                    InputProps={{
                        endAdornment: (
                            <span style={{ marginLeft: '6px'}}>seconds</span>
                        ),
                    }} 
                />
            </>}

            {/* SPEED */}
            {SETTINGS.SPEED.hasOwnProperty(activityInfo?.type) && <>
                <StyledLabelInput>Word drop speed</StyledLabelInput>
                <XDropdown
                    options={SETTINGS.SPEED[activityInfo?.type]}
                    style={{ margin: '8px 8px 16px 8px' }}
                    width='calc(100% - 16px)'
                    id='speed'
                    size='small'
                    bgColor='100'
                    defaultValue={defaultSpeed}
                    multiple={false}
                    hasCheckboxes={true}
                    onChange={(e, value) => handleOnChangeSpeed(e, value)}
                    disableClearable
                />
            </>}

            {/* COLOR */}
            {SETTINGS.COLOR.includes(activityInfo?.type) && 
                <>
                    <StyledLabelInput>Word color</StyledLabelInput>
                    <div style={{ margin: '0 0 0 8px', width: 'calc(100% - 16px)', display: 'inline-flex' }}>
                        <XInput
                            style={{ margin: '0', width: '50px' }}
                            id='customColorPicker'
                            size='small'
                            type='color'
                            value={activityInfo.props?.word_color ?? null}
                            onChange={(e) => handleOnChangeColor(e)}
                        />
                        <XInput
                            style={{ margin: '0 0 0 8px' }}
                            id='customColor'
                            size='small'
                            type='text'
                            value={activityInfo.props?.word_color ?? null}
                            onChange={(e) => handleOnChangeColor(e)}
                            fullWidth
                            disabled
                        />
                    </div>
                </>
            }

            {/* MATRIX_SIZE */}
            {SETTINGS.MATRIX_SIZE.includes(activityInfo?.type) && MATRIX_SIZE_OPTIONS &&
                <>
                    <StyledLabelInput>
                        Matrix size
                        <StyledFontAwesomeIcon 
                            icon={['fa', 'circle-info']} size='1x' isWarning hasMarginLeft
                            title={ACTIVITIES_TOOLTIPS.INSTRUCTIONS.MATRIX_SIZE}   
                        />
                    </StyledLabelInput>
                    <XDropdown
                        options={MATRIX_SIZE_OPTIONS}
                        labelOptions='label'
                        style={{ margin: '8px' }}
                        width='calc(100% - 16px)'
                        size='small'
                        bgColor='100'
                        value={matrixSizeValue}
                        onChange={(e, value) => handleMatrixSizeChange(e, value)}
                        hasCheckboxes={true}
                        multiple={false}
                        disableClearable
                    />
                </>
            }
            {/* {SETTINGS.SEEK_AND_FIND_OPTIONS.includes(activityInfo?.type) && MATRIX_SIZE_OPTIONS &&
                
            } */}

            {/* BACKGROUND IMAGE */}
            {SETTINGS.BACKGROUND.hasOwnProperty(activityInfo?.type) && <>
                <StyledLabelInput>Background</StyledLabelInput>
                <XDropdown
                    options={SETTINGS.BACKGROUND[activityInfo?.type]}
                    labelOptions='title'
                    style={{ margin: '8px 8px 16px 8px' }}
                    width='calc(100% - 16px)'
                    size='small'
                    bgColor='100'
                    value={defaultTheme}
                    onChange={(e, value) => handleBgImgChange(e, value)}
                    hasCheckboxes={true}
                    multiple={false}
                    disableClearable
                />
                {isBackgroundCustom && 
                    <>
                        <p style={{textAlign:'center'}}>Custom background image</p>
                        <img
                            style={{ cursor: 'pointer', height: '175px', objectFit: 'contain', width: '-webkit-fill-available' }}
                            src={activityInfo?.props?.background_image?.image 
                                ? `${activityInfo?.props?.background_image?.image.startsWith('@@@') ? MEDIA_PREVIEW : ''}${activityInfo?.props?.background_image?.image}` 
                                : '/assets/images/default_open_gallery.svg'
                            }
                            onClick={() => setIsOpen(true)}
                            onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/default_open_gallery.svg' }}
                        />
                        {/* <GalleryModal
                            isOpen={isOpen}
                            onCloseModal={() => setIsOpen(false)}
                            onSelect={handleCustomBgImgChange}
                        /> */}
                        <XGallery
                            isGalleryOpen={isOpen}
                            setIsGalleryOpen={setIsOpen}
                            fetchMedia={getAllExistingMedia}
                            selectMedia={handleCustomBgImgChange}
                            addNewMedia={postMediaToCMS}
                            mediaPreview={MEDIA_PREVIEW} 
                        />
                    </>
                }
            </>}

        </XCard>
    )
}

export default ActivityProperties;
