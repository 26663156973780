import { useState } from 'react';
import USERSTATUS from '../types/userStatus';
import { COOKIE_NAME } from '../CONSTANTS';

const useAuth = () => {
    const [user, setUser] = useState({ status: USERSTATUS.NOT_LOADED })

    // const handleLogIn = async ({ userOrEmail = false, password = false, token = false }) => {
    //     let newUser = {}
    //     if (token) {
    //         setStorageToken(token);
    //         newUser.user = await whoami();
    //     } else {
    //         // TODO login with userOrEmail and password
    //         if (!userOrEmail && !password) throw new Error({ concept: "notInsertUserAndPassword" });

    //         newUser.user = await loginUser({ userOrEmail: userOrEmail, password });
    //         setStorageToken(newUser.user.token);
    //     }

    //     newUser.status = newUser ? USERSTATUS.LOADED : USERSTATUS.NONE;
    //     setUser(newUser)
    // }

    const handleLimitedLogin = async (email = '', password = '') => {
        if (email === process.env?.REACT_APP_EMAIL && password === process.env?.REACT_APP_PASSWORD) {
            const limitedUser = {
                is_connected: true,
                user: {
                    name: 'xEval User',
                    email: email
                }
            };
            return limitedUser;
        } else {
            return false;
        }
    }

    const handleLogOut = (cname = COOKIE_NAME) => {
        unsetCookie(cname);
        sessionStorage.removeItem(cname);
        sessionStorage.removeItem(`${cname}_XEVAL`);
    }

    const isAuthenticate = () => {
        return user.status === USERSTATUS.LOADED;
    }

    const isUserLoaded = () => {
        return user.status !== USERSTATUS.NOT_LOADED;
    }

    const getCookie = (cname = COOKIE_NAME) => {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return JSON.parse(c.substring(name.length, c.length));
          }
        }
        return null;
    }

    const setCookie = (cname = COOKIE_NAME, value) => {
        document.cookie = cname + "=" + JSON.stringify(value) + ";domain=.ximdex.net;path=/";
    }

    const unsetCookie = (cname) => {
        document.cookie = cname + '=;domain=.ximdex.net;path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    const isAuth = () => {
        const cookie = getCookie(COOKIE_NAME);
        const limitedUser = JSON.parse(sessionStorage.getItem(`${COOKIE_NAME}_XEVAL`))

        return (cookie?.xdam_token || !!limitedUser?.is_connected === true) ? true : false;
    }

    const getXDAMToken = () => {
        const cookie = getCookie(COOKIE_NAME);
        const storage = JSON.parse(sessionStorage.getItem(COOKIE_NAME));

        const token = storage?.xdam_token ?? cookie?.access_token ?? process.env?.REACT_APP_TOKEN_XDAM ?? null;
    
        return token;
    }

    return {
        // user,
        // handleLogIn,
        handleLimitedLogin,
        handleLogOut,
        isAuthenticate,
        isUserLoaded,
        getCookie,
        setCookie,
        isAuth,
        getXDAMToken
    }
};

export default useAuth;
