import React, { useState, useEffect } from 'react';
import { XButton, XTag, XInput, XDropdown, XRow, XRowContent, XRowDetails, XRowExtraDetails } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import InputAdornment from '@mui/material/InputAdornment';
import {
    StyledGrid,
    StyledActivitiesListContainer,
    StyledDivFlexBetween,
    StyledActivityId,
    StyledFontAwesomeIcon,
    StyledDivFlexStartWrap,
    StyledGreenXButton,
    StyledRedXButton,
    StyledDivCenterY,
} from '../../Styled-Components';
import {
    PAGINATION_OPTIONS,
    SORTING_OPTIONS,
    ACTIVITIES_LIST_ALL_TYPES,
    UNITS_OPTIONS,
} from '../.././CONSTANTS';
import {
    getAllActivities,
    createNewActivity,
    updateActivityStatus
} from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { StyledLink } from '../../Styled-Components/Links';
import ActivitiesSearchColumn from './ActivitiesSearchColumn';
import Pagination from '../../components/Pagination/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import ActivitiesTags from './ActivitiesTags';

const ActivitiesList = () => {
    const { showSpinner, hideSpinner } = useSpinner();
    const navigate = useNavigate();

    const handleValue = (type) => {
        let paramsStorage = JSON.parse(localStorage.getItem("activities-filters"))
        if (paramsStorage) {
            if (type === 'sortBy') {
                let val = SORTING_OPTIONS.find((option) => option.value === (`${paramsStorage.order_by}&${paramsStorage.sort}`))
                return val
            } else {
                let val = PAGINATION_OPTIONS.find((option) => option.label === paramsStorage.limit)
                return val
            }
        } else {
            if (type === 'sortBy') {
                let val = SORTING_OPTIONS.find((option) => option.value === SORTING_OPTIONS[0].value)
                return val
            } else {
                let val = PAGINATION_OPTIONS.find((option) => option.value === PAGINATION_OPTIONS[0].value)
                return val
            }
        }

    }

    const [searchParams, setSearchParams] = useSearchParams()
    const [limitSearchParam, setLimitSearchParam] = useState(() => handleValue('limit'))
    const [sortBySearchParam, setSortBySearchParam] = useState(() => handleValue('sortBy'))
    const [activitiesList, setActivitiesList] = useState([])
    const [paginationMetaInfo, setPaginationMetaInfo] = useState({
        current_page: 1,
        last_page: undefined,
        next_page: undefined,
        prev_page: undefined,
        total: undefined,
    });
    const [textBeforeSearch, setTextBeforeSearch] = useState('')
    const [errorData, setErrorData] = useState({
        message: '',
        error: false
    })
    const [activitiesValues, setActivitiesValues] = useState(() => {
        let value = []
        if (searchParams.has('type')) {
            const activitiesParams = searchParams.get('type').split(',')
            activitiesParams.forEach(activityParam => {
                let optionParam = ACTIVITIES_LIST_ALL_TYPES.find(option => option.value === activityParam)
                value.push(optionParam)
            });
        }
        return value
    })
    const [unitsValues, setUnitsValues] = useState(() => {
        let value = []
        if (searchParams.has('units')) {
            const unitsParams = searchParams.get('units').split(',')
            unitsParams.forEach((unitParam) => {
                let optionParam = UNITS_OPTIONS.find(option => option.value === unitParam)
                value.push(optionParam)
            })
        }
        return value
    })

    // Set default filters or Get Local Storage Filters
    useEffect(() => {
        if (localStorage.getItem("activities-filters")) {
            let paramsStorage = JSON.parse(localStorage.getItem("activities-filters"))
            if (!searchParams.has('limit')) searchParams.set('limit', paramsStorage.limit)
            if (!searchParams.has('order_by')) searchParams.set('order_by', paramsStorage.order_by)
            if (!searchParams.has('sort')) searchParams.set('sort', paramsStorage.sort)

        } else {
            if (!searchParams.has('limit')) searchParams.set('limit', 24)
            if (!searchParams.has('order_by')) searchParams.set('order_by', 'id')
            if (!searchParams.has('sort')) searchParams.set('sort', 'desc')

            localStorage.setItem("activities-filters", JSON.stringify({
                limit: 24,
                order_by: 'id',
                sort: 'desc',
            }))
        }
        setSearchParams(searchParams, { replace: true })
    }, [])

    // Set Page Params
    useEffect(() => {
        if (searchParams.get('page') !== paginationMetaInfo?.current_page) {
            searchParams.set('page', paginationMetaInfo?.current_page)
            setSearchParams(searchParams, { replace: false })
        }
    }, [paginationMetaInfo?.current_page]);


    // Set New Filters to Local Storage and call getActivities()
    useEffect(() => {
        localStorage.setItem("activities-filters", JSON.stringify({
            limit: searchParams.get('limit'),
            order_by: searchParams.get('order_by'),
            sort: searchParams.get('sort'),
        }))

        getActivities();
    }, [searchParams]);


    // Get All Activities
    const getActivities = async () => {
        showSpinner();
        await getAllActivities(searchParams).then(data => {
            data.data.forEach(activity => {
                activity.isCollapsed = false;
            })
            setActivitiesList(data.data);

            let cloneData = Object.assign({}, data);
            delete cloneData.data;
            setPaginationMetaInfo(cloneData);
        }).catch(err => {
            console.error(err);
            setErrorData({ message: 'It looks like something has happened. Try again later.', error: err })
        }).finally(() => {
            hideSpinner();
        });
    }

    const handleActivityStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED';
        if (window.confirm(`Are you sure you want to change the status of the activity with id ${id} to ${newStatus}?`)) {
            updateActivityStatus(id, newStatus).then((res) => {
                getActivities();
                XPopUp({
                    message:`Status changed to ${res?.data?.status}`,
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
            }).catch(err => {
                console.error(err);
                XPopUp({
                    message:"There's been an error. Please, try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }).finally(() => {
                hideSpinner();
            });
        }
        showSpinner();
    }

    // Update Search Params
    const updateSearchParams = (searchOption, value) => {
        if (searchOption === "sortBy") {
            let val = SORTING_OPTIONS.find((option) => option.label === SORTING_OPTIONS[value].label)
            setSortBySearchParam(val)
            val = val.value.split('&')
            searchParams.set('order_by', val[0])
            searchParams.set('sort', val[1])
        } else if (searchOption === 'limit') {
            let val = PAGINATION_OPTIONS.find((option) => option.label === PAGINATION_OPTIONS[value].label)
            setLimitSearchParam(val)
            searchParams.set('limit', val.value)
        } else if (value.toUpperCase() === 'ALL' || !value) {
            searchParams.delete(`${searchOption}`)
        } else {
            searchParams.set(`${searchOption}`, value)
        }
        setSearchParams(searchParams, { replace: false })
    }

    // On key press(Intro) Search
    const handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            updateSearchParams('search', textBeforeSearch);
            setTextBeforeSearch('')
        }
    }

    if (errorData.error) throw errorData.error
    return (
        <StyledGrid>
            <ActivitiesSearchColumn
                activitiesValues={activitiesValues}
                setActivitiesValues={setActivitiesValues}
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
                unitsValues={unitsValues}
                setUnitsValues={setUnitsValues}
            />

            <StyledActivitiesListContainer>
                <StyledDivFlexStartWrap>
                    <XInput
                        value={textBeforeSearch}
                        onChange={(e) => setTextBeforeSearch(e.target.value)}
                        onKeyDown={(e) => handleKeyPressSearch(e)}
                        type='search'
                        size="small"
                        style={{ width: '-webkit-fill-available', margin: '0', background: '#FBFBFB' }}
                        placeholder='Search in activity list'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <XDropdown
                        value={limitSearchParam}
                        onChange={(e) => updateSearchParams('limit', e.target.dataset.optionIndex)}
                        options={PAGINATION_OPTIONS}
                        labelOptions='label'
                        label='Per page'
                        width='150px'
                        size="small"
                        style={{ marginLeft: '0.5em', background: '#FBFBFB' }}
                        hasCheckboxes={false}
                        multiple={false}
                        disableClearable
                    />
                    <XDropdown
                        value={sortBySearchParam}
                        onChange={(e) => updateSearchParams('sortBy', e.target.dataset.optionIndex)}
                        options={SORTING_OPTIONS}
                        labelOptions='label'
                        label='Sort by'
                        width='250px'
                        size="small"
                        style={{ marginLeft: '0.5em', background: '#FBFBFB' }}
                        hasCheckboxes={false}
                        multiple={false}
                        disableClearable
                    />
                </StyledDivFlexStartWrap>

                <StyledDivFlexBetween>
                    <XTag
                        name={(paginationMetaInfo?.total || paginationMetaInfo?.total === 0) ? `${paginationMetaInfo.total} activities found` : 'searching...'}
                        style={{ backgroundColor: 'rgb(251, 251, 251)', border: '1px solid rgb(187, 187, 187)' }}
                    />
                    <StyledLink to={'/create-activity'}>
                        <XButton>
                            <StyledFontAwesomeIcon icon={['fa', 'plus']} title='Create a new activity.' size='1x' hasMarginRight />
                            new activity
                        </XButton>
                    </StyledLink>
                </StyledDivFlexBetween>
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <ActivitiesTags
                        setActivitiesValues={setActivitiesValues}
                        setUnitsValues={setUnitsValues}
                        searchParams={searchParams}
                        updateSearchParams={updateSearchParams}
                    />
                </div>
                {activitiesList.length > 0 ? (
                    <>
                        {activitiesList.map((activity, index) => (
                            <XRow
                                key={'row' + index}
                                identifier={activity.id}
                                isCollapsable={true}
                                labelButtonCollapsable={`Used in ${activity?.assessments?.length} ${activity?.assessments?.length === 1 || activity?.assessments?.length === 0 ? 'assessment' : 'assessments'}`}
                                controls={[
                                {
                                    component:
                                    activity?.status !== 'ARCHIVED' && (
                                        <>
                                            <StyledGreenXButton onClick={() => navigate('/duplicate-activity', {state: {activity}} )}>
                                                <StyledFontAwesomeIcon icon={['far', 'clone']} size='1x' title='Duplicate activity.' />
                                            </StyledGreenXButton>
                                            <StyledGreenXButton onClick={() => navigate(`/edit-activity?activity-id=${activity.id}&lang=${activity?.language_default}`)}>
                                                <StyledFontAwesomeIcon icon={['fa', 'pen-to-square']} size='1x' title='Edit activity.' />
                                            </StyledGreenXButton>
                                        </>
                                    )
                                },
                                {
                                    component:
                                    <StyledRedXButton onClick={() => handleActivityStatus(activity?.id, activity?.status)}>
                                        <StyledFontAwesomeIcon
                                            icon={activity.status === "ARCHIVED" ? ['fa', 'trash-can-arrow-up'] : ['far', 'trash-can']}
                                            title={activity?.status === "ARCHIVED" ? 'Unarchive and change status to active' : 'Archive activity'}
                                        />
                                    </StyledRedXButton>
                                }, 
                                ]}
                            >
                                {/* XRow Content */}
                                <XRowContent key={"XRowContent" + index}>
                                    <StyledDivCenterY>
                                         <StyledFontAwesomeIcon
                                            icon={['far', 'flag']}
                                            title={`Status: ${activity.status}`}
                                            hasMarginRight size='1x'
                                            isSuccess={activity.status === 'ACTIVE' ? true : false}
                                            isWarning={activity.status === 'DRAFT' ? true : false}
                                            isError={(activity.status !== 'ACTIVE' && activity?.status !== 'DRAFT') ? true : false}
                                        />
                                        <span>Id: {activity.id}</span>
                                    
                                        <div style={{marginLeft:'1em'}}>
                                            <p style={{ marginTop: '6px', marginBottom: '6px' }}>{activity?.name ?? 'Unnamed activity'} ({activity?.type})</p>
                                            {activity?.isbn?.length > 0 && 
                                            <p style={{ marginTop: '0px', marginBottom: '6px' }}>
                                                ISBN: {activity.isbn.join(', ')} 
                                                {"  "}
                                                UNITS: {activity?.units?.length > 0 && activity.units.join(', ')}
                                            </p>}
                                        </div>
                                    
                                    </StyledDivCenterY>
                                </XRowContent>
                                {/* XRow Details */}
                                {activity.status === 'ARCHIVED' ?
                                    <XRowDetails key={'XRowDetails'}>
                                        <div>
                                            <p><strong>Title: </strong>{activity?.title}</p>
                                            <p><strong>Text: </strong>{activity?.text}</p>
                                        </div>
                                    </XRowDetails>
                                : (activity?.assessments && activity.assessments?.length) > 0 ? 
                                    <React.Fragment key={'XRowDetails'}>
                                    {activity.assessments?.map((assessment, assessmentIndex) => 
                                        <XRowDetails 
                                            key={'XRowDetails' + assessmentIndex} 
                                            controlsDetails={[
                                            {
                                                component:
                                                <StyledGreenXButton
                                                    onClick={() => navigate(`/edit-assessment?assessment-id=${assessment?.id}`)}
                                                >
                                                    <StyledFontAwesomeIcon icon={['fa', 'pen-to-square']} size='1x' title='Edit assessment' />
                                                </StyledGreenXButton>
                                            },
                                        ]}
                                        >
                                            <StyledDivCenterY>
                                                <StyledActivityId>Id: {assessment?.id}</StyledActivityId>
                                                <p>{assessment?.name || assessment?.title}</p>
                                            </StyledDivCenterY>
                                        </XRowDetails>    
                                    )}
                                    </React.Fragment> 
                                : 
                                    <XRowDetails key={'XRowDetails'}></XRowDetails>
                                }
                                {/* XRow extra details */}
                                <XRowExtraDetails
                                    key={"XRowExtraDetails" + index}
                                    extraDetails={[
                                        {
                                            label: 'Languages',
                                            type: 'text',
                                            value: activity?.language_default + (activity?.available_languages?.length > 0 ? ", " + activity?.available_languages?.join(', ') : "")
                                        },
                                        {
                                            label: 'Created date',
                                            type: activity?.created_at ? "date" : 'text',
                                            value: activity?.created_at ?? 'data unavailable' 
                                        },
                                        {
                                            label: 'Last update',
                                            type: activity?.updated_at ? 'date' : 'text',
                                            value: activity?.updated_at ?? 'data unavailable'
                                        }
                                    ]}
                                />
                            </XRow>
                        ))}
                        <Pagination
                            paginationInfo={paginationMetaInfo}
                            setPaginationMetaInfo={setPaginationMetaInfo}
                        />
                    </>
                ) : paginationMetaInfo?.total === 0 ? (
                    <div>
                        <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em' }}>
                            No activities found with these filter settings.
                        </p>
                    </div>
                ) : errorData?.message ? (
                    <div>
                        <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em' }}>
                            {errorData.message}
                        </p>
                        <p style={{ margin: '1em', textAlign: 'center', fontSize: '1em' }}>
                            {errorData.message}
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </StyledActivitiesListContainer >


        </StyledGrid >
    )
}

export default ActivitiesList;
