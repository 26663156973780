import React, {useState,useEffect} from 'react'
import { getActivityLanguageById, updateActivity } from '../../services/xevalApi.service';
import { XCard } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import MultipleValueInput from '../../components/MultipleValueInput/MultipleValueInput';
import ContainedLoading from '../../components/Loading/ContainedLoading';
import StringTranslation from './ActivityEditTranslation/StringTranslation';
import FeedbackTranslations from './ActivityEditTranslation/FeedbackTranslations';
import OptionTranslations from './ActivityEditTranslation/OptionTranslations';
import TargetTranslations from './ActivityEditTranslation/TargetTranslations';
import { putActivityLanguage } from '../../services/xevalApi.service';
import useSortOptionsTargets from '../../hooks/useSortOptionsTargets';

const ActivityTranslations = ({
    langTabChecked, 
    activityInfo,
    setActivityInfo
}) => {
    //Activity Translated
    const [activityTranslated,setActivityTranslated] = useState(undefined);
    const [save,setSave] = useState(true)
    const [showIsbn, setShowIsbn] = useState(true)
    const [, sortOptionsAndTargets] = useSortOptionsTargets();

    // Get activity lang checked
    useEffect(() => {
        // Get Activity Language
        if(activityInfo?.available_languages.length > 0) {
            getActivityLanguageById(activityInfo.id,langTabChecked).then(res => {
                let newData = {...res}
                // newData = sortOptionsAndTargets(newData)
                // newData = sortOptions(newData)
                newData.available_languages = []
                newData["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]
                setActivityTranslated(newData);
            }).catch(err =>  console.log("Err while getting activity language",err));
        }
    }, [langTabChecked]);
   
    const getTitle = (field) => {
        switch (field) {
            case 'title': return 'Header'
            case 'text': return 'Content'
            case 'name': return 'Name'
            case 'feedbacks': return 'Global feedback'
            case 'options': return 'Options'
            case 'targets': return 'Targets'
          }
    }
    
    useEffect(() => {
        if(activityTranslated)
            saveTranslation()
    }, [save]);

    const saveTranslation = async () => {
        await putActivityLanguage(activityInfo.id, langTabChecked, activityTranslated)
            .then(data => {
                if (data?.id) {
                    XPopUp({
                        message:"Translation saved successfully",
                        iconType:'success',
                        timer:'2000',
                        popUpPosition:'top',
                        iconColor: 'ligthgreen',
                    })
                } else {
                    XPopUp({
                        message:"Looks like an error may have occured while updating this translation. Try again later.",
                        iconType:'error',
                        timer:'2000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
            })
            .catch(error => {
                console.error('There has been an error while updating this translation. Try again later.', error)
                XPopUp({
                    message:"Looks like an error may have occured while updating this translation. Try again later.",
                    iconType:'error',
                    timer:'2000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            })
    }

    const handleIsbnChange = async (e, keyToEdit, action, value) => {
        setShowIsbn(prevState => (!prevState))

        let newIsbnArray = activityTranslated?.isbn || []
        let activityData = {...activityInfo}
        
        if (action?.toLowerCase() === 'add') newIsbnArray.push(value)
        if (action?.toLowerCase() === 'remove') newIsbnArray.splice(value, 1)

        activityData.isbn = newIsbnArray

        await updateActivity(activityTranslated.id, activityData)
            .then(data => {
                const savedIsbn = data?.isbn || []

                setActivityTranslated(prevState => ({
                    ...prevState,
                    isbn: savedIsbn
                }))

                setActivityInfo(prevState => ({
                    ...prevState,
                    isbn: savedIsbn
                }))
            })
            .catch(error => console.error(error))
            .finally(() => setShowIsbn(prevState => (!prevState)))
    }

    if (!activityTranslated) return null

    return (
        <div style={{overflowY: 'auto', height: 'calc(100vh - 177px)'}}>
            {/* SPECIFIC FOR MHE */}
            {process.env?.REACT_APP_CLIENT === 'MHE' &&
                <XCard
                    isCollapsable={true}
                    isCollapsed={false}
                    title='Data'
                    marginXSize='m'
                >
                    {/* ISBNs */}
                    {showIsbn ? (
                        <MultipleValueInput 
                            labelName='All ISBNs: '
                            keyToEdit='isbn'
                            data={activityTranslated}
                            editData={handleIsbnChange}
                        />
                    ) : (
                        <ContainedLoading />
                    )}
                    
                </XCard>
            }
           {activityTranslated?.translatable_fields.map((translatableField,index) =>
            <div key={index}>
                {/* STRINGS FIELDS */}
                {typeof activityInfo[translatableField] === 'string' &&
                    activityInfo[translatableField] !== null &&
                    <StringTranslation
                        setSave={setSave}
                        save={save}
                        activityInfo={{...activityInfo}}
                        activityTranslated={{...activityTranslated}}
                        setActivityTranslated={setActivityTranslated}
                        translatableField={translatableField}
                        langTabChecked={langTabChecked}
                        getTitle={getTitle}
                    />
                }
                 {/* FEEDBACKS */}
                 {translatableField === 'feedbacks' && 
                activityInfo[translatableField] !== null &&
                activityTranslated[translatableField] !== null &&
                activityTranslated[translatableField].length > 0 &&
                    <FeedbackTranslations
                        setSave={setSave}
                        save={save}
                        langTabChecked= {langTabChecked}
                        activityInfo={{...activityInfo}}
                        activityTranslated={{...activityTranslated}}
                        setActivityTranslated={setActivityTranslated}
                        getTitle={getTitle}
                        translatableField={translatableField}
                    />
                }
                {/* OPTIONS AND TARGETS */}
                {/* OPTIONS */}
                {translatableField === 'options' &&
                activityInfo[translatableField] !== null &&
                activityTranslated[translatableField] !== null && 
                activityTranslated[translatableField].length > 0 &&
                    <OptionTranslations
                        setSave={setSave}
                        save={save}
                        activityInfo={{...sortOptionsAndTargets(activityInfo)}}
                        activityTranslated={{...sortOptionsAndTargets(activityTranslated)}}
                        setActivityTranslated={setActivityTranslated}
                        langTabChecked={langTabChecked}
                        getTitle={getTitle}
                        translatableField={translatableField}
                    />
                }
                {/* TARGETS */}
                {translatableField === 'targets' && 
                activityInfo[translatableField] !== null &&
                activityTranslated[translatableField] !== null &&
                activityTranslated[translatableField].length > 0 &&
                    <TargetTranslations
                        setSave={setSave}
                        save={save}
                        activityInfo={{...sortOptionsAndTargets(activityInfo)}}
                        activityTranslated={{...sortOptionsAndTargets(activityTranslated)}}
                        setActivityTranslated={setActivityTranslated}
                        langTabChecked={langTabChecked}
                        getTitle={getTitle}
                        translatableField={translatableField}
                    />
                }
                 
            </div> 

        )}
    </div>
    )
}

export default ActivityTranslations
