import React, { useState, useEffect } from 'react';
import { XCard, XInput, XButton, XTag } from '@ximdex/xui-react/material';
import {
    StyledXButtonS,
    StyledButtonCenterContainer,
    StyledSpaceAroundDiv,
    StyledFeedbackContainer,
    StyledWordCollectionText,
    StyledTagContainer,
    StyledFontAwesomeIcon
} from '../../../Styled-Components';

export const WordCollectionAnswers = ({
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated = undefined,
    setSave = undefined,
    ACTIVITY_TYPE,
    SETTINGS,
    isCollapsable,
    cardTitle,
}) => {

    useEffect(() => {
        activityInfo.options?.sort((x, y) => x.order - y.order);
        activityInfo.targets?.sort((x, y) => x.order - y.order);
    }, [activityInfo]);
    
    const initValuesToBeAdded = () => {
        let newValuesToBeAdded = { accepted_values: {}, unaccepted_values: {}}
        activityInfo.targets?.forEach(target => {
            newValuesToBeAdded.accepted_values[target.order] = ''
            newValuesToBeAdded.unaccepted_values[target.order] = ''
        })
        return newValuesToBeAdded
    }

    const [valuesToBeAdded, setValuesToBeAdded] = useState(() => initValuesToBeAdded())

    const editValuesToBeAdded = (valueType, order, value) => {
        const newValuesToBeAdded = { ...valuesToBeAdded }
        newValuesToBeAdded[valueType][order] = value
        setValuesToBeAdded(newValuesToBeAdded)
    }

    const addValueToBeAdded = (typeOfValue, targetOrder) => {
        // add to word collection
        const newInfo = { ...activityInfo };
        let indexOfTarget = newInfo.targets.findIndex(target => target.order === targetOrder)
        newInfo.targets[indexOfTarget][typeOfValue].push(valuesToBeAdded[typeOfValue][targetOrder].trim());
        setActivityInfo(newInfo);
        setIsActivityInfoUpdated && setIsActivityInfoUpdated(false);
        setSave && setSave(prevState => (!prevState));

        // clear input
        const newValuesToBeAdded = { ...valuesToBeAdded }
        newValuesToBeAdded[typeOfValue][targetOrder] = ''
        setValuesToBeAdded(newValuesToBeAdded)
    };

    const handleKeyDown = (e, typeOfValue, targetOrder) => {
        if (e.key === 'Enter') {
            addValueToBeAdded(typeOfValue, targetOrder)
        }
    };

    const removeValueFromTarget = (typeOfValue, targetIndex , tagIndex) => {
        const newInfo = { ...activityInfo };
        newInfo.targets[targetIndex][typeOfValue].splice(tagIndex, 1);
        setActivityInfo(newInfo);
        setIsActivityInfoUpdated && setIsActivityInfoUpdated(false);
        setSave && setSave(prevState => (!prevState));
    };

    const addNewTarget = () => {
        const newInfo = { ...activityInfo };
        const newTarget = {
            accepted_values: [],
            area: null,
            id: 0,
            image_url: null,
            order: activityInfo.targets.length + 1,
            title: null,
            unaccepted_values: []
        };
        newInfo.targets.push(newTarget);
        setActivityInfo(newInfo);
        setIsActivityInfoUpdated && setIsActivityInfoUpdated(false);
        setSave && setSave(prevState => (!prevState));
    };

    const deleteTarget = () => {
        const newInfo = { ...activityInfo };
        newInfo.targets.splice(activityInfo.targets.length - 1, 1);
        setActivityInfo(newInfo);
        setIsActivityInfoUpdated && setIsActivityInfoUpdated(false);
        setSave && setSave(prevState => (!prevState));
    };

    return (
        <XCard
            isCollapsable={isCollapsable ?? true}
            title={cardTitle ?? 'Answers'}
            marginXSize='m'
        >
            {activityInfo.targets?.map((target, targetIndex) => (
                <XCard
                    key={`${target.id}-targetCardInfo`}
                    title={SETTINGS.INDIVIDUAL_TITLE.hasOwnProperty(ACTIVITY_TYPE)
                        ? `${SETTINGS.INDIVIDUAL_TITLE[ACTIVITY_TYPE]} ${(target.order).toString()}`
                        : false}
                    isCollapsable={SETTINGS.INDIVIDUAL_COLLAPSABLE.includes(ACTIVITY_TYPE)}
                >
                    {/* ACCEPTED VALUES */}
                    {ACTIVITY_TYPE === 'fill-the-blanks' ?
                    <StyledWordCollectionText>Possible answers for blank {`{${target?.order}}`}</StyledWordCollectionText>
                    :
                    <StyledWordCollectionText>
                        Correct word collection
                        {activityInfo?.matrix?.props?.size?.width &&                             
                            <StyledFontAwesomeIcon 
                                icon={['fa', 'circle-info']} size='1x' isInfo hasMarginLeft 
                                title={`Word length limited to a maximum of ${activityInfo?.matrix?.props?.size?.width}, according to the current matrix size.`}
                            />
                        }
                    </StyledWordCollectionText>
                    }

                    <StyledFeedbackContainer>
                        <XInput
                            key={`accepted_value_${target?.order}`}
                            value={valuesToBeAdded.accepted_values[target?.order] || ''}
                            style={{ margin: '0 8px 0 8px' }}
                            id={`id_accepted_value${target.order}`}
                            size='small'
                            fullWidth
                            multiline={activityInfo?.type === 'input-long'}
                            rows={4}
                            inputProps={{ maxLength: activityInfo?.matrix?.props?.size?.width ?? null }}
                            onChange={(e) => editValuesToBeAdded('accepted_values', target.order, e.target.value) }
                            onKeyDown={(e) => handleKeyDown(e, 'accepted_values', target?.order)}
                        />
                        <StyledXButtonS variant='outlined' onClick={() => addValueToBeAdded('accepted_values', target?.order)}>
                            <StyledFontAwesomeIcon icon={['fa', 'plus']} size='1x' title='Add word to the collection.' />
                        </StyledXButtonS>
                    </StyledFeedbackContainer>

                    <StyledTagContainer>
                        {target.accepted_values && target?.accepted_values.map((acceptedValue, acceptedValueIndex) => (
                            <XTag
                                key={`correctWordCollectionTag_${acceptedValueIndex}`}
                                style={{ marginTop: '8px' }}
                                isRemovable
                                name={acceptedValue}
                                onClickRemove={() => removeValueFromTarget('accepted_values', targetIndex, acceptedValueIndex)}
                            />
                        ))}
                    </StyledTagContainer>
                    
                    {/* UNACCEPTED VALUES */}
                    {SETTINGS.TARGET_UNACCEPTED_WORDS.includes(ACTIVITY_TYPE) && (<>
                        <StyledWordCollectionText style={{ marginTop: '24px' }}>Incorrect word collection</StyledWordCollectionText>
                        <StyledFeedbackContainer>
                            <XInput
                                key={`unaccepted_value_${target?.order}`}
                                value={valuesToBeAdded.unaccepted_values[target?.order] || ''}
                                style={{ margin: '0 8px 0 8px' }}
                                id={`id_unaccepted_value_${target?.order}`}
                                size='small'
                                fullWidth
                                onChange={(e) => editValuesToBeAdded('unaccepted_values', target.order, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, 'unaccepted_values', target?.order)}
                            />
                            <StyledXButtonS variant='outlined' onClick={() => addValueToBeAdded('unaccepted_values', target?.order)}>
                                <StyledFontAwesomeIcon icon={['fa', 'plus']} size='1x' title='Add word to the collection.' />
                            </StyledXButtonS>
                        </StyledFeedbackContainer>
                        <StyledTagContainer>
                            {target.unaccepted_values && target.unaccepted_values.map((unacceptedValue, unacceptedValueIndex) => (
                                <XTag
                                    key={`incorrectWordCollectionTag_${unacceptedValueIndex}`}
                                    style={{ marginTop: '8px' }}
                                    status='incorrect'
                                    isRemovable
                                    name={unacceptedValue}
                                    onClickRemove={() => removeValueFromTarget('unaccepted_values', targetIndex, unacceptedValueIndex)}
                                />
                            ))}
                        </StyledTagContainer>
                    </>)}
                </XCard>
            ))}

            <StyledSpaceAroundDiv>
                {SETTINGS.ADD_NEW_TARGET.includes(ACTIVITY_TYPE) &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => addNewTarget('add')}>Add new target</XButton>
                    </StyledButtonCenterContainer>
                }

                {SETTINGS.DELETE_LAST.includes(ACTIVITY_TYPE) &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => deleteTarget('deleteLast')}>Delete last target</XButton>
                    </StyledButtonCenterContainer>
                }

            </StyledSpaceAroundDiv>
        </XCard >
    )
};
