import styled from "styled-components/macro";

export const StyledVersionText = styled.p`
    margin: -4px 8px 0px;
    font-size: 0.8em;
    text-align: right;
`;

export const StyledCardTitle = styled.p`
    font-size: 1.1rem;
    text-align: center;
    margin: 8px 0 0 0;
    color: #212529;
`;

export const StyledCardText = styled.p`
    font-size: 0.9rem;
    text-align: center;
    color: #212529;
`;

export const ExtraInfoText = styled.p`
    text-align: right;
    margin-right: 1rem;
    margin-top: 0.2rem;
    font-size: 0.9rem;
`;

export const StyledCenterParagraph = styled.p`
    text-align: center;
`;

export const StyledWordCollectionText = styled.p`
    margin: 0.5rem;
`;

export const StyledLabelInput = styled.p`
    margin: 0.5rem;
`;


export const StyledActivityId = styled.p`
    margin: 1rem;
    width: max-content;
`;

export const StyledDateDetails = styled.p`
    margin: 0.5em 0em;
    width: max-content;
    color: #555;
    font-size: smaller;
`;

export const StyledTranslationSpan = styled.span`
    color: black;
    font-weight: bold;
    font-style: normal;
`

export const StyledActDetailsParagraph = styled.p`
    margin: 6px 3px;
`

export const StyledTranslationp = styled.div`
    text-align: left;
    font-style: italic;
    margin-right: 10px;
`
