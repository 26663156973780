import styled from "styled-components/macro";
import { XLabel } from "@ximdex/xui-react/material";

export const StyledXLabel = styled(XLabel)`
    width: 100%;
    & label {
        min-width: 85px;
    }
`;

export const StyledXLabelLeft = styled(XLabel)`
    width: 100%;
    & .css-tbfrkj {
        width: 100%;
    }
`
