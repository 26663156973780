import ACTIVITY_TYPE from '../../default/activities/Activities'
import * as DEFAULT from '../../default/activities/ListSettings'

export const MHE_STATUS_OPTIONS = [
    ...DEFAULT.STATUS_OPTIONS,
];

export const MHE_PAGINATION_OPTIONS = [
    { value: '6', label: '6' },
    ...DEFAULT.PAGINATION_OPTIONS,
];

export const MHE_SORTING_OPTIONS = [
    ...DEFAULT.SORTING_OPTIONS,
    { value: 'isbn&asc', label: 'ISBN: low to high' },
    { value: 'isbn&desc', label: 'ISBN: high to low' },
];

// refactor later: create an assessment folder and move there
export const MHE_SORTING_OPTIONS_ASSESSMENTS = [
    { value: 'id&asc', label: 'Id: low to high' },
    { value: 'id&desc', label: 'Id: high to low' },
    { value: 'name&asc', label: 'Name: A to Z' },
    { value: 'name&desc', label: 'Name: Z to A' },
];

export const MHE_LANGUAGES_OPTIONS_SHOW_ALL = [
    ...DEFAULT.LANGUAGES_OPTIONS_SHOW_ALL,
    { value: 'ca-ES', label: 'Català' },
];

export const MHE_LANGUAGES_OPTIONS = [
    ...DEFAULT.LANGUAGES_OPTIONS,
    { value: 'ca-ES', label: 'Català' },
];

export const MHE_UNITS_OPTIONS = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
];

export const MHE_ACTIVITIES_LIST_ALL_TYPES = [
    { value: 'exam', label: 'Exam' },
    ...DEFAULT.ACTIVITIES_LIST_ALL_TYPES,
    { value: ACTIVITY_TYPE.MATCH, label: 'Match' },
    { value: ACTIVITY_TYPE.INPUT, label: 'Input' },
    { value: ACTIVITY_TYPE.INPUT_LONG, label: 'Input long' },
    { value: ACTIVITY_TYPE.FIT_WORD, label: 'Fit word' },
    { value: ACTIVITY_TYPE.RANK, label: 'Rank' },
];
