import HTMLReactParser from 'html-react-parser';
import React, {useState} from 'react';
import { XCard, XInput, XButton, XBox, XTag } from '@ximdex/xui-react/material';
import { faEdit, faSave,faXmark} from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledActionsEditTranslations, StyledTranslationSpan, StyledTargetsContainer, StyledDivFlexWrap} from '../../../Styled-Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditorTinyMCE from '../../../components/TinyMCE/EditorTinyMCE';
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS, OPTIONS_WITH_TINY_EDITOR } from '../../../CONSTANTS';
import { WordCollectionAnswers } from '../Answers/WordCollectionAnswers';

const TargetTranslations = ({
        save,
        setSave,
        activityInfo, 
        activityTranslated, 
        setActivityTranslated, 
        langTabChecked,
        translatableField,
        getTitle,
    }) => 

    {
    const [isTranslationUpdated, setIsTranslationUpdate] = useState(true);
    const [editTranslation,setEditTranslation] =  useState(() => {
        let edits = {}
        activityInfo.available_languages.forEach(lang => {
            edits = {...edits, [lang]: []}
            activityInfo?.targets.forEach((element,i) => {
                var copy = {}
                element.translatable_fields.forEach(translatableField => {
                    copy = {
                        ...copy,
                        [translatableField]: false
                    }
                })
                edits[lang].push(copy)
            })
        })
        return edits
    })
    // // Lang Targets Template
    const [targetsValues, setTargetsValues] =  useState(()=>{
        let inputs = {}
        activityInfo.available_languages.forEach(lang => {
            inputs = {...inputs, [lang]: []}
            if(activityInfo.targets) {
                activityInfo?.targets.forEach((element,i) => {
                    let target = element
                    element.translatable_fields.forEach(translatableField => {
                        if(translatableField === 'unaccepted_values' || translatableField === 'accepted_values')
                            target = {...target,[translatableField]: []}
                        else
                            target = {...target,[translatableField]: ''}
                    })
                    inputs[lang].push(target)
                })
            }
        })
        return inputs
    })
    const [hasTargets,setHasTargets] =  useState(() => {
        let hasvalues = []
        if(activityInfo.targets && activityInfo.targets.length > 0){

            activityInfo?.targets.forEach((element,i) => {
                hasvalues.push(false)
                element.translatable_fields.forEach(translatableField => {
                    if(element[translatableField] !=null && element[translatableField].length > 0)
                        hasvalues.splice(hasvalues.length-1,1,true);
                })
            })
        }
        return hasvalues
    })

    const handleTranslate = (e,fieldOption,position,arrayTargetPosition) => {
        const input = (OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && (fieldOption === 'title' || fieldOption === 'text')) ? e : e.target.value
        if(fieldOption === 'title' || fieldOption === 'text'){
            let targetsValuesCopy = targetsValues[langTabChecked];
            let item = targetsValuesCopy[position]
            item[fieldOption] = input
            targetsValuesCopy[position] = item
            setTargetsValues({...targetsValues,[langTabChecked]:targetsValuesCopy})
        }else{
            let targetsValuesCopy = targetsValues[langTabChecked]
            let item = targetsValuesCopy[position]
            item[fieldOption][arrayTargetPosition] = input
            targetsValuesCopy[position] = item
            setTargetsValues({...targetsValues,[langTabChecked]:targetsValuesCopy})
        }
        setIsTranslationUpdate(false)
    }

    const handleConfirmTranslation = (position,fieldOption) => {
        let activityTranslatedCopy = activityTranslated
        activityTranslatedCopy = {...activityTranslatedCopy,targets:targetsValues[langTabChecked]}

        let editCopy = editTranslation[langTabChecked]
        editCopy[position][fieldOption] = false
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})

        setActivityTranslated(activityTranslatedCopy)
        setIsTranslationUpdate(true)

        setSave(!save)
    }

    const handleCancel = (position,fieldOption) => {

        // Set input invisible
       let editCopy = editTranslation[langTabChecked]
       editCopy[position][fieldOption] = false
       setEditTranslation({...editTranslation,[langTabChecked]: editCopy})
       setTargetsValues({...targetsValues, [langTabChecked]: activityTranslated[translatableField]})
       setIsTranslationUpdate(true)
   }

   const handleEditState = (position,fieldOption) =>{
       // Set input visible
       let editCopy = editTranslation[langTabChecked]
       editCopy[position][fieldOption] = true
       setEditTranslation({...editTranslation,[langTabChecked]: editCopy})
       setTargetsValues({...targetsValues, [langTabChecked]: activityTranslated[translatableField]})
   }

    return (
        <>
            {hasTargets.includes(true) &&
            <XCard
                isCollapsable={true}
                isCollapsed={false}
                title={getTitle(translatableField)}
                marginXSize='m'
            >
                {activityInfo?.targets?.map((target,key) => 
                <div key={key}>
                    {hasTargets[key] && <XCard 
                        style={{border: 'solid 1px rgba(0, 0, 0, 0.12)',borderRadius: '10px'}}
                        // isCollapsable={true}
                        isCollapsed={false}
                        title={`Target ${key+1}`}
                        marginXSize='m'
                    >
                        {target.translatable_fields.map((translatable_field,j) => 
                            <React.Fragment key={j}>
                                {/* STRINGS */}
                                {target[translatable_field] !== null &&
                                    typeof target[translatable_field] === 'string' &&
                                    target[translatable_field] !== '' &&
                                    <XBox>
                                    {
                                        activityInfo.targets[key][translatable_field] &&
                                        <p style={{textAlign:'left', color: 'grey'}}><StyledTranslationSpan>{`Original ${translatable_field.charAt(0).toUpperCase() + translatable_field.slice(1)}: `}</StyledTranslationSpan>{HTMLReactParser(String(activityInfo.targets[key][translatable_field]))}</p>
                                    }

                                    {
                                        editTranslation[langTabChecked][key][translatable_field] 
                                        ? 
                                            <div style={{display: 'flex', alignItems: 'center'}} key={j}>
                                                {OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type)
                                                ?
                                                    <EditorTinyMCE 
                                                        value={targetsValues && targetsValues[langTabChecked][key][translatable_field]} 
                                                        handleEditorChange={(e) => handleTranslate(e, translatable_field,key)} 
                                                        initialValue={{...activityTranslated.targets[key][translatable_field]}}
                                                        config={{
                                                            max_num_characters: 450,
                                                            plugins: [
                                                                'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                                                'FMathEditor, codesample, fontselect',
                                                                'damloader'
                                                            ],
                                                            toolbar: [`
                                                                undo redo | bold italic forecolor backcolor fontsizeselect | 
                                                                alignleft aligncenter alignright alignjustify | 
                                                                bullist numlist outdent indent | 
                                                                link damloader list table | 
                                                                charmap FMathEditorButton | 
                                                                removeformat
                                                            `],
                                                        }}
                                                    />
                                                :
                                                    <XInput
                                                        placeholder="Enter translation"
                                                        style={{ margin: '8px', width: '95%' }}
                                                        size='normal'
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                        value={targetsValues && targetsValues[langTabChecked][key][translatable_field]}
                                                        onChange={(e) => handleTranslate(e, translatable_field,key)}
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                }
                                                <StyledActionsEditTranslations>
                                                    <XButton
                                                        onClick={() => handleConfirmTranslation(key,translatable_field)}
                                                        size='large' style={{margin:'0.2em'}}
                                                        disabled={isTranslationUpdated}
                                                    >   
                                                        <FontAwesomeIcon 
                                                            icon={faSave}
                                                            title="Save new translation" />
                                                    </XButton>
                                                    <XButton
                                                        onClick={() => handleCancel(key,translatable_field)} 
                                                        size='large' style={{margin:'0.2em'}}
                                                    >
                                                        <StyledFontAwesomeIcon size='1x'
                                                            icon={faXmark} 
                                                            title={"Cancel translation"}
                                                        />
                                                    </XButton>
                                                </StyledActionsEditTranslations>
                                            </div>
                                        : 
                                            <>  
                                                {activityInfo.targets[key][translatable_field] &&
                                                    <div style={{display:'flex', alignItems:'flex-start', flexDirection: 'column'}}>                                                           
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                    
                                                            {typeof activityTranslated.targets[key][translatable_field] === 'string' &&
                                                                    activityTranslated.targets[key][translatable_field] !== ''  ? 
                                                                <p style={{textAlign:'center',fontStyle: 'italic', marginRight:'10px'}}><StyledTranslationSpan>Translation:</StyledTranslationSpan> {HTMLReactParser(String(activityTranslated.targets[key][translatable_field]))}</p>
                                                            :
                                                                <p style={{textAlign:'center', marginRight:'10px',fontStyle: 'italic', color: '#F6AB0E'}}><StyledTranslationSpan>Translation:</StyledTranslationSpan> Nothing translated</p>
                                                            }
                                                            <XButton 
                                                                onClick={() => handleEditState(key,translatable_field)} 
                                                                size='small' style={{minWidth: 'unset', height:'100%'}}
                                                            >
                                                                <StyledFontAwesomeIcon size='1x' 
                                                                    icon={faEdit} 
                                                                    title={'Edit translation'}
                                                                />
                                                            </XButton> 
                                                        </div> 
                                                    </div>
                                                }   
                                            </>
                                    }
                                    </XBox>
                                }

                                {/* ARRAYS */}
                                {target[translatable_field] !== null &&
                                 typeof target[translatable_field] === 'object' &&
                                 target[translatable_field].length > 0  && 
                                    <StyledTargetsContainer>
                                        <XCard
                                            isCollapsable={false}
                                            title={translatable_field === "accepted_values" ? 'Original correct word collection: ' : 'Original incorrect word collection: '}
                                            marginXSize='m'
                                        >
                                            <StyledDivFlexWrap>
                                                {target[translatable_field].map((value) => 
                                                    <XTag
                                                        key={translatable_field === "accepted_values" ? `correctWordCollectionOriginalTag_${value}` : `incorrectWordCollectionOriginalTag__${value}`}
                                                        style={{ marginTop: '8px', color: 'grey', opacity: '0.75'}}
                                                        status={translatable_field === "accepted_values" ? "correct" : "incorrect"}
                                                        name={value}
                                                    />   
                                                )}
                                            </StyledDivFlexWrap>
                                            {
                                                ((translatable_field === "accepted_values" && !ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TARGET_UNACCEPTED_WORDS.includes(activityTranslated.type))
                                                || (translatable_field === "unaccepted_values" && ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TARGET_UNACCEPTED_WORDS.includes(activityTranslated.type))
                                                ) &&
                                                    <WordCollectionAnswers
                                                        activityInfo={activityTranslated}
                                                        setActivityInfo={setActivityTranslated}
                                                        setSave={setSave}
                                                        ACTIVITY_TYPE={activityInfo.type}
                                                        SETTINGS={ACTIVITIES_ANSWERS_OPTIONS_SETTINGS}
                                                        cardTitle={'Translations:'}
                                                        isCollapsable={false}
                                                        fieldType={translatable_field}
                                                    />
                                            }
                                        </XCard>
                                    </StyledTargetsContainer>
                                }
                            </React.Fragment>
                        )}
                    </XCard>}
                </div>)}
            </XCard>}
       </>
    );
}

export default TargetTranslations;
