import Swal from "sweetalert2";
import './sweetAlertClasses.css'

export default function useModals () {

    const modal = async (text,title, onConfirmFunction) => {
        Swal.fire({
            text: text,
            title: title,
            showCancelButton: true,
            confirmButtonText: 'Accept',
            color: 'black',
            confirmButtonColor: '#43a1a2',
            background: '#e0e0e0',
            focusConfirm: false,
            customClass: {
                popup: 'border',
            }
        }).then(async(result) => {
            if (result.isConfirmed) {
                onConfirmFunction()
            }
        })
    }

   
    return {
        modal,
    }
}
