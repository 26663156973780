import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { getAllAssessments, updateAssessmentStatus } from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { XButton, XTag, XInput, XDropdown, XRow, XRowContent, XRowExtraDetails, XRowDetails } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import InputAdornment from '@mui/material/InputAdornment';
import {
    StyledDivFlexBetween,
    StyledGrid,
    StyledActivitiesListContainer,
    StyledActivityId,
    StyledFontAwesomeIcon,
    StyledDivFlexStartWrap,
    StyledGreenXButton,
    StyledRedXButton,
    StyledDivCenterY,
} from '../../Styled-Components';
import { StyledLink } from '../../Styled-Components/Links';
import SearchIcon from '@mui/icons-material/Search';
import AssessmentsSearchColumn from './AssessmentsSearchColumn';
import AssessmentsTags from './AssessmentsTags';
import Pagination from '../../components/Pagination/Pagination';
import { PAGINATION_OPTIONS, SORTING_OPTIONS_ASSESSMENTS,UNITS_OPTIONS } from '../../CONSTANTS';

const AssessmentsList = () => {
    const { showSpinner, hideSpinner } = useSpinner();
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();

    const handleValue = (type) => {
        let paramsStorage = JSON.parse(localStorage.getItem("assessments-filters"))
        if(paramsStorage){
            if(type === 'sortBy'){
                let val = SORTING_OPTIONS_ASSESSMENTS.find((option) => option.value === (`${paramsStorage.order_by}&${paramsStorage.sort}`))
                return val
            }else{
                let val = PAGINATION_OPTIONS.find((option) => option.value === paramsStorage.limit)
                return val
            }
        }else{
            if(type === 'sortBy'){
                console.log(SORTING_OPTIONS_ASSESSMENTS)

                let val = SORTING_OPTIONS_ASSESSMENTS.find((option) => option.value === SORTING_OPTIONS_ASSESSMENTS[0].value)
                return val
            }else{
                let val = PAGINATION_OPTIONS.find((option) => option.value === PAGINATION_OPTIONS[0].value)
                return val
            }
        }
    }

    const [assessmentsList, setAssessmentsList] = useState([]);
    const [limitSearchParam, setLimitSearchParam] = useState(() => handleValue('limit'))
    const [sortBySearchParam, setSortBySearchParam] = useState(() => handleValue('sortBy'))
    const [paginationMetaInfo, setPaginationMetaInfo] = useState({
        current_page: parseInt(searchParams.get('page')) || 1,
        last_page: undefined,
        next_page: undefined,
        prev_page: undefined,
        total: undefined,
        isLoading: true,
    })
    const [textBeforeSearch, setTextBeforeSearch] = useState('');
    const [errorData, setErrorData] = useState({
        message: undefined,
        error: undefined
    })
    const [unitsValue, setUnitsValues] = useState(() => {
        let value = []
        if (searchParams.has('units')) {
            const unitsParams = searchParams.get('units').split(',')
            unitsParams.forEach((unitParam) => {
                let optionParam = UNITS_OPTIONS.find(option => option.value === unitParam)
                value.push(optionParam)
            })
        }
        return value
    })

    useEffect(() => {
        if(localStorage.getItem("assessments-filters")){
            let paramsStorage = JSON.parse(localStorage.getItem("assessments-filters"))
            if (!searchParams.has('limit')) searchParams.set('limit', paramsStorage.limit)
            if (!searchParams.has('order_by')) searchParams.set('order_by', paramsStorage.order_by)
            if (!searchParams.has('sort')) searchParams.set('sort', paramsStorage.sort)

        }else{
            if (!searchParams.has('limit')) searchParams.set('limit', 24)
            if (!searchParams.has('order_by')) searchParams.set('order_by', 'id')
            if (!searchParams.has('sort')) searchParams.set('sort', 'desc')

            localStorage.setItem("assessments-filters", JSON.stringify({
                page:paginationMetaInfo.current_page,
                limit: 24,
                order_by: 'id',
                sort:'desc',
            }))
        } 

        setSearchParams(searchParams, {replace: true})
    }, [])

    // Set Page Params
    useEffect(() => {
        if (searchParams.get('page') !== paginationMetaInfo?.current_page) {
            searchParams.set('page', paginationMetaInfo?.current_page )
            setSearchParams(searchParams, {replace: false})
        }
    }, [paginationMetaInfo?.current_page]);

    // Set New Filters to Local Storage and call getAssessments()
    useEffect(() => {
        localStorage.setItem("assessments-filters", JSON.stringify({
            page: searchParams.get('page'),
            limit: searchParams.get('limit'),
            order_by: searchParams.get('order_by'),
            sort: searchParams.get('sort'),
        }))

        getAssessments();
    }, [searchParams])

    // Get All Assessments
    const getAssessments = async () => {
        showSpinner();
        await getAllAssessments(searchParams.toString()).then(data => {
            data.data.map(assessment => {
                assessment.isCollapsed = false;
            })
            setAssessmentsList(data.data);

            let cloneData = Object.assign({}, data);
            delete cloneData.data;
            setPaginationMetaInfo({ ...cloneData, isLoading: false});
            return cloneData.current_page
        }).catch(err => {
            console.error(err);
            setPaginationMetaInfo({ ...paginationMetaInfo, isLoading: false });
            setErrorData({message: 'It looks like something has happened. Try again later.', error: err })
        }).finally(() => {
            hideSpinner();
        });
    }

    const updateSearchParams = (searchOption, value) => {
        if(searchOption === "sortBy"){
            let val = SORTING_OPTIONS_ASSESSMENTS.find((option) => option.label === SORTING_OPTIONS_ASSESSMENTS[value].label)
            setSortBySearchParam(val)
            val = val.value.split('&') 
            searchParams.set('order_by', val[0])
            searchParams.set('sort', val[1])
        }else if (searchOption === 'limit'){
            let val = PAGINATION_OPTIONS.find((option) => option.label === PAGINATION_OPTIONS[value].label)
            setLimitSearchParam(val)
            searchParams.set('limit', val.value)
        }else if(value.toUpperCase() === 'ALL' || !value) {
            searchParams.delete(`${searchOption}`)
        } else{
            searchParams.set(`${searchOption}`, value )       
        }
        setSearchParams(searchParams, {replace: false})
    }

    const handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            updateSearchParams('search', textBeforeSearch)  
        }
    }

    const handleAssessmentStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED';
        if (window.confirm(`Are you sure you want to change the status of the assessment with id ${id} to ${newStatus}?`)) {
            showSpinner();
            updateAssessmentStatus(id, newStatus).then((res) => {
                getAssessments();
            }).catch(err => {
                console.error(err);
                XPopUp({
                    message:"There's been an error. Please, try again later",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }).finally(() => {
                hideSpinner();
            });
        }   
    }

    return (
        <StyledGrid>
            <AssessmentsSearchColumn
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
                unitsValue={unitsValue}
                setUnitsValues={setUnitsValues}
            />
            <StyledActivitiesListContainer>
            <StyledDivFlexStartWrap>
                <XInput
                    value={textBeforeSearch}
                    onChange={(e) => setTextBeforeSearch(e.target.value)}
                    onKeyDown={(e) => handleKeyPressSearch(e)}
                    fullWidth
                    type='search'
                    size="small"
                    style={{width:'-webkit-fill-available', margin: '0', background: '#FBFBFB' }}
                    placeholder='Search in assessment list'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }} 
                />
                <XDropdown
                    value={limitSearchParam}
                    onChange={(e) => updateSearchParams('limit',e.target.dataset.optionIndex)}
                    options={PAGINATION_OPTIONS}
                    labelOptions='label'
                    label='Per page'
                    width='150px'
                    size="small"
                    style={{ marginLeft: '1em', background: '#FBFBFB' }} 
                    hasCheckboxes={false}
                    multiple={false}
                    disableClearable
                />
                <XDropdown
                    value={sortBySearchParam}
                    onChange={(e) => updateSearchParams('sortBy',e.target.dataset.optionIndex)}
                    options={SORTING_OPTIONS_ASSESSMENTS}
                    labelOptions='label'
                    label='Sort by'
                    width='250px'
                    size="small"
                    style={{ marginLeft: '0.5em', background: '#FBFBFB' }}
                    hasCheckboxes={false}
                    multiple={false}
                    disableClearable
                />
                
            </StyledDivFlexStartWrap>

                
                <StyledDivFlexBetween>
                    <XTag
                        name={(paginationMetaInfo?.total || paginationMetaInfo?.total === 0) ? `${paginationMetaInfo?.total} assessments found` : 'searching...'}
                        style={{backgroundColor: 'rgb(251, 251, 251)', border: '1px solid rgb(187, 187, 187)'}}
                    />
                    <StyledLink to={'/create-assessment'} style={{ marginLeft: '12px' }}>
                        <XButton>
                            <StyledFontAwesomeIcon hasMarginRight icon={['fa', 'plus']} title='Create a new assessment' /> 
                            new assessment
                        </XButton>
                    </StyledLink>
                </StyledDivFlexBetween>
                <div style={{display: 'flex', marginBottom: '1rem' }}>
                    <AssessmentsTags 
                        searchParams={searchParams}
                        updateSearchParams={updateSearchParams}
                        setUnitsValues={setUnitsValues}
                    />
                </div>
               
                {assessmentsList?.length > 0 ? ( 
                    <>
                        {assessmentsList.map((assessment, index) => (
                            <XRow
                                key={'row' + index}
                                identifier={assessment?.id}
                                isCollapsable={true}
                                labelButtonCollapsable={`Has ${assessment?.activities?.length} ${assessment?.activities?.length === 1 ? 'activity' : 'activities'}`}
                                controls={[
                                {
                                    component:  assessment?.status !== "ARCHIVED" && (
                                        <>
                                            <StyledGreenXButton onClick={() => navigate(`/duplicate-assessment`, {state: {assessment}} )}>
                                                <StyledFontAwesomeIcon icon={['far', 'clone']} size='1x' title='Duplicate assessment.' />
                                            </StyledGreenXButton>
                                            <StyledGreenXButton onClick={() => navigate(`/edit-assessment?assessment-id=${assessment.id}`)}>
                                                <StyledFontAwesomeIcon icon={['fa', 'pen-to-square']} size='1x' title='Edit assessment.' />
                                            </StyledGreenXButton>
                                        </>  
                                    )
                                },
                                {
                                    component:
                                        <StyledRedXButton onClick={() => handleAssessmentStatus(assessment?.id, assessment?.status)}>
                                            <StyledFontAwesomeIcon 
                                                icon={assessment?.status === "ARCHIVED" ? ['fa', 'trash-can-arrow-up'] : ['far', 'trash-can']} 
                                                title={assessment?.status === "ARCHIVED" ? 'Unarchive and change status to active' : 'Archive assessment'} 
                                            />
                                        </StyledRedXButton>
                                }
                            ]}
                            >
                                {/* XRow Content */}
                                <XRowContent key={"XRowContent" + index}>
                                    <StyledDivCenterY>
                                        <span>Id: {assessment?.id}</span>
                                        <span style={{marginLeft: '1em'}}>{assessment?.title ?? assessment?.name ?? 'Unnamed assessment'}</span>
                                        {assessment?.isbn?.length > 0 && <p style={{ margin: '0 0.5em' }}>ISBN: {assessment?.isbn?.join(', ')}</p>}
                                    </StyledDivCenterY>
                                </XRowContent>

                                {/* XRow Details */}
                                    {assessment?.activities?.length > 0 ?
                                        <React.Fragment key={'XRowDetails'}>
                                            {assessment.activities.map((activity, activityIndex) => 
                                                <XRowDetails 
                                                    key={'XRowDetails' + activityIndex}
                                                    controlsDetails={[{
                                                        component:
                                                        <StyledGreenXButton
                                                            onClick={() => navigate(`/edit-activity?activity-id=${activity?.id}`)}
                                                        >
                                                            <StyledFontAwesomeIcon icon={['fa', 'pen-to-square']} size='1x' title='Edit activity' />
                                                        </StyledGreenXButton>
                                                    }
                                                ]}
                                                >
                                                    <StyledDivCenterY>
                                                        <StyledActivityId>Id: {activity?.id}</StyledActivityId>
                                                        <p>{activity?.title ?? activity?.name ?? 'Unnamed activity'}</p>
                                                    </StyledDivCenterY>
                                                </XRowDetails>
                                            )}
                                        </React.Fragment>
                                        : <XRowDetails key={"XRowDetails"}></XRowDetails>
                                    }


                                {/* XRow Extra Details */}
                                <XRowExtraDetails
                                    key={"XRowExtraDetails" + index}
                                    extraDetails={[
                                        {
                                            label: 'Created date',
                                            type: assessment?.created_at ? "date" : 'text',
                                            value: assessment?.created_at ?? 'data unavailable' 
                                        },
                                        {
                                            label: 'Last update',
                                            type: assessment?.created_at ? "date" : 'text',
                                            value: assessment?.created_at ?? 'data unavailable' 
                                        },]}
                                />
                            </XRow>
                        ))}
                        <Pagination
                            paginationInfo={paginationMetaInfo}
                            setPaginationMetaInfo={setPaginationMetaInfo}
                        />
                    </>  
                ) : paginationMetaInfo?.total === 0 ? (
                    <div>
                        <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em'}}>
                            No activities found with these filter settings.
                        </p>
                    </div>
                ) : (assessmentsList?.length === 0 && errorData?.message) ? (
                    <div>
                        <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em'}}>
                            {errorData?.message}
                        </p>
                        <p style={{ margin: '1em', textAlign: 'center', fontSize: '1em'}}>
                            {errorData?.error}
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </StyledActivitiesListContainer>
        </StyledGrid>
    )
}

export default AssessmentsList;
