import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faCopy as faCopyRegular, 
    faFile as faFileRegular,
    faPlus, 
    faPenToSquare,
    faAngleDoubleLeft, 
    faAngleLeft, 
    faAngleDoubleRight, 
    faAngleRight,
    faTrashCanArrowUp,
    faSearch,
    faFloppyDisk, 
    faCheck,
    faCircleInfo,
    faImages,
    faEraser,
    faXmark,
    faSplotch,
    faArrowsRotate,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'

import {
    faCopy as faCopySolid, 
    faFile as faFileSolid,
    faClone, 
    faTrashCan,
    faFlag,
    faCircle,
} from '@fortawesome/free-regular-svg-icons'


library.add(
    faCopyRegular, 
    faFileRegular,
    faPlus, 
    faPenToSquare,
    faAngleDoubleLeft, 
    faAngleLeft, 
    faAngleDoubleRight, 
    faAngleRight,
    faTrashCanArrowUp,
    faSearch,
    faFloppyDisk, 
    faCheck,
    faCircleInfo,
    faImages,
    faEraser,
    faXmark,
    faSplotch,
    faArrowsRotate,
    faTriangleExclamation,
    // solid icons go above this line
    faCopySolid, 
    faFileSolid, 
    faClone,
    faTrashCan,
    faFlag,
    faCircle,
    // regular icons go above this line
)

// Example:
// I. <StyledFontAwesomeIcon hasMarginLeft 
//          icon={['fa', 'plus']} 
//          title='Create a new assessment' 
//     />
// II. <StyledFontAwesomeIcon size='1x' 
//          icon={isActivityInfoUpdated ? ['fa', 'check'] : ['fa', 'floppy-disk']} 
//          title={isActivityInfoUpdated ? 'Activity is upToDate' : 'Save activity'}
//     />
