import styled from "styled-components/macro";

export const StyledPoweredByImg = styled.img`
    display: block; 
    width: 100%; 
    margin: 0 auto; 
    cursor: pointer;
`;

export const StyledOptionImg = styled.img`
    max-height: 50px;
    width: 100%;
    // remove after xui update
    // margin-top: -38px;
`

export const StyledPageStatusImg = styled.img`
    height: 50vh;
    width: auto;
`