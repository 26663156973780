import HTMLReactParser from 'html-react-parser';
import React, { useState } from 'react';
import { XCard, XInput, XButton, XBox } from '@ximdex/xui-react/material';
import { faEdit, faSave,faXmark} from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledActionsEditTranslations, StyledTranslationSpan, StyledTranslationp} from '../../../Styled-Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditorTinyMCE from '../../../components/TinyMCE/EditorTinyMCE';


const StringTranslation = ({
    save,
    setSave,
    langTabChecked,
    activityInfo, 
    activityTranslated, 
    setActivityTranslated,
    translatableField, 
    getTitle,
    }) => {

    const [isTranslationUpdated, setIsTranslationUpdate] = useState(true);
    const [editTranslation,setEditTranslation] =  useState(() => {
        let edits = {}
        activityInfo.available_languages.map(lang => {
            return edits = {...edits, [lang]: false}
        })
        return edits
    })
    const [inputsValues, setInputsValue] = useState(() => {
        let inputs = {}
        activityInfo.available_languages.map(lang => {
            return inputs = {...inputs, [lang]: ''}
        })
        return inputs
    })

    const handleTranslate = (e, translatableField) => {
        const input = translatableField === 'text' ? e : e.target.value
        let values = inputsValues
        values = {...values,[langTabChecked]: input}
        setInputsValue(values)
        setIsTranslationUpdate(false)
    }

    const handleConfirmTranslation = (field) => {

        let activityTranslatedCopy = activityTranslated
        activityTranslatedCopy = {...activityTranslatedCopy,[field]:inputsValues[langTabChecked]}
        setEditTranslation({...editTranslation,[langTabChecked]: !editTranslation[langTabChecked]})
        setIsTranslationUpdate(true)
        
        setActivityTranslated(activityTranslatedCopy)
        setSave(!save)
    }



    return (<>
        {activityInfo[translatableField] && <XCard
            isCollapsable={true}
            isCollapsed={false}
            title={getTitle && getTitle(translatableField)}
            marginXSize='m'
        >
            <XBox>
                <p style={{textAlign:'left', color: 'grey'}}><StyledTranslationSpan style={{color:'black', fontWeight:'bold'}}>Original:</StyledTranslationSpan> {HTMLReactParser(String(activityInfo[translatableField]))}</p>

                {editTranslation[langTabChecked]
                ?    <div style={{display: 'flex', alignItems: 'center'}}>
                        {translatableField === 'text' 
                        ?
                            <EditorTinyMCE 
                                value={inputsValues[langTabChecked]} 
                                handleEditorChange={(e) => handleTranslate(e, translatableField)} 
                                initialValue={activityTranslated.text}
                                config={{
                                    max_num_characters: 450,
                                    plugins: [
                                        'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                        'FMathEditor, codesample, fontselect',
                                        'damloader'
                                    ],
                                    toolbar: [`
                                        undo redo | bold italic forecolor backcolor fontsizeselect | 
                                        alignleft aligncenter alignright alignjustify | 
                                        bullist numlist outdent indent | 
                                        link damloader list table | 
                                        charmap FMathEditorButton | 
                                        removeformat
                                    `],
                                }}
                            />
                        :
                            <XInput
                                placeholder="Enter translation"
                                style={{ margin: '8px', width: '95%' }}
                                size='normal'
                                fullWidth
                                multiline
                                rows={2}
                                value={inputsValues[langTabChecked]}
                                onChange={(e) => handleTranslate(e)}
                                inputProps={{ maxLength: 200 }}
                            />
                        }
                        <StyledActionsEditTranslations>
                            <XButton
                                onClick={() => handleConfirmTranslation(translatableField)}
                                size='large' style={{margin:'0.2em'}}
                                disabled={isTranslationUpdated}
                            >   
                                <FontAwesomeIcon 
                                    icon={faSave}
                                    title="Save new translation" />
                            </XButton>
                            <XButton
                                onClick={() => {
                                    setIsTranslationUpdate(true)
                                    setEditTranslation({...editTranslation,[langTabChecked]: !editTranslation[langTabChecked]})
                                    setInputsValue({...inputsValues, [langTabChecked]: activityTranslated[translatableField]})
                                }} 
                                size='large' style={{margin:'0.2em'}}
                            >
                                <StyledFontAwesomeIcon size='1x'
                                    icon={faXmark} 
                                    title={"Cancel translation"}
                                />
                            </XButton>
                        </StyledActionsEditTranslations>
                        
                    </div>
                :   
                    <div style={{display:'flex', alignItems:'flex-start', flexDirection: 'column'}}>
                        
                            <div style={{display:'flex',alignItems:'center'}}>
                                {activityTranslated[translatableField] ? 
                                    <StyledTranslationp><StyledTranslationSpan>Translation:</StyledTranslationSpan> {HTMLReactParser(String(activityTranslated[translatableField]))}</StyledTranslationp>
                                :
                                    <StyledTranslationp style={{color: '#F6AB0E'}}><StyledTranslationSpan>Translation:</StyledTranslationSpan> Nothing translated</StyledTranslationp>     
                                } 
                                <XButton 
                                    onClick={() => {
                                        setInputsValue({...inputsValues, [langTabChecked]: activityTranslated[translatableField]})
                                        setEditTranslation({...editTranslation,[langTabChecked]: !editTranslation[langTabChecked]})}} 
                                    size='small' style={{minWidth: 'unset', height:'100%'}}
                                >
                                    <StyledFontAwesomeIcon size='1x' 
                                        icon={faEdit} 
                                        title={'Edit translation'}
                                    />
                                </XButton>
                            </div>
                        
                    </div>
                }
            </XBox>
        </XCard>}
</>);
}

export default StringTranslation;
