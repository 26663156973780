import React, { useState } from 'react';
import { 
    XBox, 
    XCard, 
    XRadio, 
    XDateRangeCalendar, 
    XDropdown,
    XInput
} from '@ximdex/xui-react/material';
import { 
    StyledSearchContainer,
    StyledXButtonS,
    StyledFontAwesomeIcon,
} from '../../Styled-Components';
import {
    CLIENT, 
    PAGINATION_OPTIONS, 
    SORTING_OPTIONS_ASSESSMENTS, 
    UNITS_OPTIONS,
    LANGUAGES_OPTIONS_SHOW_ALL, 
    STATUS_OPTIONS
} from '../../CONSTANTS';

const AssessmentsSearchColumn = ({ unitsValue, setUnitsValues, searchParams, updateSearchParams }) => {
    const [temporaryISBN, setTemporaryISBN] = useState('')
   
    const handleKeyPress = (e, param, value) => {
        if (e.key === 'Enter') {
            updateSearchParams(param, value)
            setTemporaryISBN("")
        }
    }

    const handleClickISBNSearch = (temporaryISBN) => {
        updateSearchParams('isbn', temporaryISBN)
        setTemporaryISBN("")
    }

    const handleChangeUnits = (values) => {
        const valuesArray = values.map( value => value.value)
        updateSearchParams('units', valuesArray.join(','))

        const valuesOptionsArray = valuesArray.map((unitParam) => {
            return UNITS_OPTIONS.find(option => option.value === unitParam)
        })
        setUnitsValues(valuesOptionsArray)
    }

    return (
        <StyledSearchContainer>


            {/* DATE RANGE */}
            {/* <XCard
                style={{ padding: '0.25em 1em'}}
                isCollapsable={true}
                isCollapsed={true}
                title='Date range'
                marginXSize='m'
            >
                <p>Under Construction</p>
                <XDateRangeCalendar
                    value={searchParams.date}
                    onChange={(newValue) => handleChange('date', [newValue.selection])}
                />
            </XCard> */}

            {/* STATUS */}
            <XCard
                style={{ padding: '0 0.75em'}}
                isCollapsable={true}
                isCollapsed={false}
                title='Status'
            >
                <XRadio
                    direction='column'
                    value={searchParams.get('status') ? searchParams.get('status') : 'ACTIVE'}
                    onChange={(e) => updateSearchParams('status', e.target.value)}
                    options={[STATUS_OPTIONS[1],STATUS_OPTIONS[3]]}
                    paddingXSize='s'
                    style={{paddingBottom: '0.5em'}}
                />
            </XCard>

            {/* LANGUAGE*/}
            <XCard
                style={{ padding: '0 0.75em'}}
                isCollapsable={true}
                isCollapsed={false}
                title='Language'
            >
                <XRadio
                    direction='column'
                    value={searchParams.get('language_default') || 'ALL'}
                    onChange={(e) => updateSearchParams('language_default', e.target.value)}
                    options={LANGUAGES_OPTIONS_SHOW_ALL}
                    paddingXSize='s'
                    style={{paddingBottom: '0.5em'}}
                />
            </XCard>

          
            {CLIENT === 'MHE' && 
                (<>
                    {/* ISBN */}
                    <XCard
                        style={{ padding: '0 0.75em'}}
                        isCollapsable={true}
                        isCollapsed={false}
                        title='ISBN'
                    >
                        <div style={{ display: 'inline-flex', width: '100%', marginBottom: '0.5em'}}>
                            <XInput
                                value={temporaryISBN}
                                id='isbn'
                                size='small'
                                fullWidth
                                style={{ margin: '0 6px 0 0' }}
                                onChange={(e) => setTemporaryISBN(e.target.value)}
                                onKeyDown={(e) => handleKeyPress(e, 'isbn', temporaryISBN)}
                            />
                            <StyledXButtonS onClick={() => {handleClickISBNSearch(temporaryISBN)}} variant='outlined'>
                                <StyledFontAwesomeIcon icon={['fa','search']} size='1x' title='Search by ISBN.' />
                            </StyledXButtonS>
                        </div>
                    </XCard>
                    {/* UNITS */}
                    <XCard
                        style={{ padding: '0 0.75em'}}
                        isCollapsable={true}
                        isCollapsed={false}
                        title='Units'
                    >
                        <XDropdown
                            onChange={(e, value) => handleChangeUnits(value)}
                            value={unitsValue}
                            options={UNITS_OPTIONS}
                            multiple={true}
                            hasCheckboxes={true}
                            labelOptions='label'
                            width='100%'
                            bgColor='100'
                            size='small'
                            style={{ paddingBottom: '0.5em' }} 
                        />
                    </XCard>
                </>)
            }
        </StyledSearchContainer>
    )
}

export default AssessmentsSearchColumn;
