import styled from 'styled-components/macro';
import { XButton } from "@ximdex/xui-react/material";

export const StyledXButtonS = styled(XButton)`
    height: 39px;
    min-width: unset;
    width: 40px;
`;

export const StyledGreenXButton = styled(XButton)`
    color: #ffffff;
    font-size: 1em;
    min-width: unset;
    width: 2em;
    margin-left: 0.5em;
`;

export const StyledRedXButton = styled(StyledGreenXButton)`
    // background: #e13144;

    &:hover {
        background: #e13144;
        // background: #cb2c3c;
    }
`;


export const StyledAddActivitiesButton = styled(XButton)`
    display: inline-block;
    border-radius: 50%;
    transition: all .2s ease-in-out;
    font-size: 1em;
    padding: 0.2em 0.8em;
    min-width: 0;
    &:hover {
       border-radius: 5px;
       > span {
          display: inline-block;
       }
    }

    span {
        transition: all .2s ease-in-out;
        display: none;
     }

`
