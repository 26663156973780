import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { XButton } from '@ximdex/xui-react/material'
import { 
    StyledCenteredXYDiv, 
    StyledPageStatusHeader,
    StyledPageStatusImg,
    StyledFontAwesomeIcon, 
} from '../../Styled-Components'

const Error = (props) => {
    const [showError, setShowError] = useState(false)
    const navigate = useNavigate()
    
    const handleShowError = () => setShowError(!showError)
    
    return (
        <StyledCenteredXYDiv>
            <StyledPageStatusImg src={"/assets/images/undraw-warning.svg"} />  
            <StyledPageStatusHeader>Opps! Something went wrong.</StyledPageStatusHeader>
            <div
                style={{
                    height: showError ? 'initial' : 0,
                    width: '50vw',
                    borderBottom: showError ? '1px solid #cdcdcd' : 'none',
                    borderLeft: showError ? '1px solid #cdcdcd' : 'none',
                    borderRight: showError ? '1px solid #cdcdcd' : 'none',
                    backgroundColor: '#fefefe',
                    borderTop: '5px solid #3d9293',
                    marginBottom: 20
                }}
                onClick={handleShowError}
            >
                {showError && (
                    <>
                        <h3>{props.error.message}</h3>
                        <pre
                            style={{
                                padding: 10,
                                textAlign: 'left'
                            }}
                        >{props.error.stack}</pre>
                    </>
                )}

            </div>
            <p style={{ margin: '0'}}>We are sorry for any inconvenience this may cause you.</p>        
            <p>Please go back and try again later.</p>       
            <div style={{ margin: '5em auto 0.5em'}}>
                <XButton onClick={() => navigate(-1)} size='small'>
                    <StyledFontAwesomeIcon icon={['fa', 'angle-left']} title="Go to previous page" hasMarginRight />
                    Go Back
                </XButton>
            </div>
        </StyledCenteredXYDiv>
    )
}

export default Error