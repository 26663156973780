import { Link } from 'react-router-dom';
import styled from "styled-components/macro";

export const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
