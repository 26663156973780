import ACTIVITY_TYPE from '../../default/activities/Activities'
import * as DEFAULT from '../../default/activities/EditSettings'

export const MHE_ACTIVITIES_PREVIEWS = {
    ...DEFAULT.ACTIVITIES_PREVIEWS,
}

export const MHE_ACTIVITIES_TOOLTIPS = {
    ...DEFAULT.ACTIVITIES_TOOLTIPS,
}

export const MHE_ACTIVITIES_CONTENT_SETTINGS = {
    ...DEFAULT.ACTIVITIES_CONTENT_SETTINGS,
    SHOW_CONTENT: [
        ...DEFAULT.ACTIVITIES_CONTENT_SETTINGS.SHOW_CONTENT,
        ACTIVITY_TYPE.WORD_SEARCH,
        ACTIVITY_TYPE.WORD_CATCHER,
        ACTIVITY_TYPE.SEEK_AND_FIND,
    ],
}

export const MHE_ANSWERS_TEMPLATE = {
    ...DEFAULT.ANSWERS_TEMPLATE,
}

export const MHE_ACTIVITIES_ANSWERS_OPTIONS_SETTINGS = {
    ...DEFAULT.ACTIVITIES_ANSWERS_OPTIONS_SETTINGS,
    ACTIVITIES: [
        ...DEFAULT.ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.ACTIVITIES,
        ACTIVITY_TYPE.MATCH,
        ACTIVITY_TYPE.INPUT,
        ACTIVITY_TYPE.FIT_WORD,
        ACTIVITY_TYPE.RANK,
    ],
}

export const MHE_ACTIVITIES_PROPERTIES_SETTINGS = {
    ...DEFAULT.ACTIVITIES_PROPERTIES_SETTINGS,
}

export const MHE_ACTIVITIES_GLOBAL_FEEDBACK = [
    ...DEFAULT.ACTIVITIES_GLOBAL_FEEDBACK,
    ACTIVITY_TYPE.SINGLE_CHOICE,
    ACTIVITY_TYPE.MULTIPLE_CHOICE,
    ACTIVITY_TYPE.TRUE_FALSE,
]


export const MHE_OPTIONS_WITH_TINY_EDITOR  = [
    ...DEFAULT.OPTIONS_WITH_TINY_EDITOR,
]
