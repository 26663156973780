import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { XNav } from '@ximdex/xui-react/material';
import useAuth from '../../../hooks/useAuth';
import { CLIENT, XEDIT_SEK_URL, XPANEL_SEK_URL, COOKIE_NAME} from '../../../CONSTANTS';

const Navbar = () => {
    const navigate = useNavigate();
    const { handleLogOut } = useAuth();

    const externalLinks = [
        {
            icon: <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" title="Go to xPanel" />,
            text: 'xPanel',
            url: XPANEL_SEK_URL,
            target: '_self'
        },
        {
            icon: <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" title="Go to xEdit" />,
            text: 'xEdit',
            url: XEDIT_SEK_URL,
            target: '_self'
        }
    ];

    const internalLinks = [
        {
            text: 'Activities',
            url: '/list-activities',
            target: '_self'
        },
        {
            text: 'Assessments',
            url: '/list-assessments',
            target: '_self'
        }
    ];

    const userLink = [
        {
            text: sessionStorage.hasOwnProperty(COOKIE_NAME) ? JSON.parse(sessionStorage.getItem(`${COOKIE_NAME}`))?.user?.name : JSON.parse(sessionStorage.getItem(`${COOKIE_NAME}_XEVAL`))?.user?.name ?? 'xeval user',
            icon: <FontAwesomeIcon icon={faUser} size="1x" title="" />,
            items: [
                {
                    text: 'Sign out',
                    icon: <FontAwesomeIcon icon={faSignOutAlt} size="1x" style={{ marginRight: '8px'}} />,
                    onClick: () => {
                        handleLogOut(COOKIE_NAME);
                        navigate('/login', 'logout')
                    }
                }
            ]
        },
    ];

    const links = CLIENT === 'SEK' ? [ internalLinks, externalLinks, userLink] : [internalLinks, userLink];

    const logoLink = {
        logoImgSrc: '/assets/images/logotipo_ximdex-eval-white-small.png'
    }

    return (
        <XNav
            color='secondary'
            style={{backgroundColor:'#214F61'}}
            logoLink={logoLink}
            links={links}
        />
    );
};

export default Navbar;
