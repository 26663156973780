import React, { useEffect, useState } from 'react';
import { useSpinner } from '@ximdex/xui-react/hooks';
import {
    XCard,
    XInput,
    XDropdown,
    XTag,
    XRadio
} from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { 
    PlusIcon, 
    StyledXDropdown,
    StyledXLabel,
    StyledXButtonS,
    StyledTagContainerForIdentifyingData,
    StyledFontAwesomeIcon
} from '../../Styled-Components';
import MultipleValueInput from '../../components/MultipleValueInput/MultipleValueInput';
import { STATUS_OPTIONS, UNITS_OPTIONS, LANGUAGES_OPTIONS } from '../../CONSTANTS';
import { updateActivityStatus } from '../../services/xevalApi.service';

export const IdentifyingData = ({ 
    activityInfo,
    setActivityInfo,
    editActivityInfo,
    addNewTag,
    tags,
    currentTag,
    setCurrentTag
}) => {
    const { REACT_APP_CLIENT } = process.env;
    const { showSpinner, hideSpinner } = useSpinner();

    const categories = [
        {
            title: 'Classroom Management',
            id: 'CM'
        },
        {
            title: 'Category 2',
            id: 'C2'
        },
        {
            title: 'Music and Arts',
            id: 'MA'
        },
        {
            title: 'Testing',
            id: 'T'
        }
    ];

    const [newIsbn, setNewIsbn] = useState('')
    const [selectedUnits, setSelectedUnits] = useState([])
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return LANGUAGES_OPTIONS.find(langOption => langOption.value === activityInfo.current_language)
    })

    useEffect(() => {
        const handleSetSelectedUnits = (unitsArray) => {
            let newUnitsArray = []
            if (unitsArray.length > 0) {
                unitsArray?.forEach(unit => {
                    let option = UNITS_OPTIONS.find(unitOption => unit === unitOption.value)
                    if (option) newUnitsArray.push(option)
                })
            }
            setSelectedUnits(newUnitsArray)
        }
        if (activityInfo?.units) handleSetSelectedUnits(activityInfo.units)
    }, [activityInfo?.units])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addNewTag();
        };
    }

    const handleStatusChange = (newStatus) => {
        if (activityInfo.assessments?.length === 0 || (
            activityInfo.assessments?.length > 0 && newStatus === 'ACTIVE') || (
            activityInfo.assessments?.length > 0 && newStatus === 'DRAFT' && window.confirm(`This activity is being used in ${activityInfo.assessments?.length} assessment${activityInfo.assessments?.length > 1 ? 's' : ''}. Are you sure you want to change the status to "${newStatus}"?`)
        )) {
            showSpinner()
            updateActivityStatus(activityInfo.id, newStatus)
                .then((res) => {
                    setActivityInfo(activityState => ({ ...activityState, status: res.data.status }))
                    XPopUp({
                        message:`Status changed to ${res?.data?.status}`,
                        iconType:'success',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'ligthgreen',
                    })
                }).catch(err => {
                    console.error("There's been an error. Please, try again later.",err)
                    XPopUp({
                        message:"There's been an error. Please, try again later.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }).finally(() => {
                    hideSpinner()
                })
        }
    }

    const handleLanguageChange = (e, value) => { 
        editActivityInfo(undefined, 'current_language', undefined, value.value)
        setSelectedLanguage(value)
    }

    const handleAddNewIsbn = () => {
        setNewIsbn('')
        editActivityInfo( undefined, 'isbn' , 'add', newIsbn)
    }

    const handleIsbnKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAddNewIsbn();
        };
    }

    return (
        <XCard
            isCollapsable={true}
            isCollapsed={false}
            title='Data'
            marginXSize='m'
        >
            <XRadio
                label='Status: '
                options={[STATUS_OPTIONS[1], STATUS_OPTIONS[2]]}
                value={activityInfo.status}
                paddingXSize='s'
                id='status'
                onChange={(e) => handleStatusChange(e.target.value)}
            />
            <StyledXLabel
                label={'Name: '}
                paddingSize='s'
                component=
                {<XInput
                    id='name'
                    size='small'
                    value={activityInfo.name}
                    placeholder='Name used for identifying purposes in xEval.'
                    fullWidth
                    style={{ margin: '0' }}
                    onChange={(e) => editActivityInfo(e)}
                />}
                htmlFor={'name'}
                labelLocation='left'
            />
            <StyledXLabel
                label={'Id:'}
                paddingSize='s'
                component=
                {
                    <>
                        <XInput
                            disabled
                            value={activityInfo.id}
                            style={{ width: '25%', minWidth: '80px', margin: '0' }}
                            id='id'
                            size='small'
                            fullWidth
                        />
                        <p style={{ margin: '8px', alignItems: 'center' }}>-</p>
                        <XInput
                            value={activityInfo.custom_client_id}
                            placeholder='Optional custom id input.'
                            style={{ margin: '0' }}
                            id='custom_client_id'
                            size='small'
                            fullWidth
                            onChange={(e) => editActivityInfo(e)}
                        />
                    </>}
                htmlFor={'id'}
                labelLocation='left'
            />
            {/* LANGUAGE */}
            <StyledXLabel
                label={'Language:'}
                paddingSize='s'
                component=
                {<XDropdown
                    options={LANGUAGES_OPTIONS}
                    value={selectedLanguage}
                    disableClearable
                    labelOptions='label'
                    width='100%'
                    bgColor='100'
                    size='small'
                    style={{ margin: '0' }}
                    onChange={(e, value) => handleLanguageChange(e, value)}
                    hasCheckboxes={false}
                    multiple={false}
                    disabled
                />}
                htmlFor={'units'}
                labelLocation='left'
            />

            {/* SPECIFIC FIELDS FOR SEK */}
            {/* {REACT_APP_CLIENT === 'SEK'
                &&
                <>
                    <StyledXLabel
                        label={'Category:'}
                        paddingSize='s'
                        component=
                        {<StyledXDropdown
                            options={categories}
                            labelOptions='title'
                            style={{ margin: '0 0 0 8px' }}
                            // width='calc(100% - 48px)'
                            bgColor='100'
                        />}
                        htmlFor={'tags'}
                        labelLocation='left'
                    />
                    <StyledXLabel
                        label={'Tags:'}
                        paddingSize='s'
                        component=
                        {
                            <>
                                <PlusIcon onClick={addNewTag} />
                                <XInput
                                    value={currentTag}
                                    style={{ margin: '0 8px 0 8px' }}
                                    id='tags'
                                    size='small'
                                    fullWidth
                                    onChange={(e) => setCurrentTag(e.currentTarget.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </>}
                        htmlFor={'tags'}
                        labelLocation='left'
                    />
                </>
            } */}

            {/* SPECIFIC FIELDS FOR MHE */}
            {REACT_APP_CLIENT === 'MHE'
                &&
                <>
                    {/* UNITS */}
                    <StyledXLabel
                        label={'Units:'}
                        paddingSize='s'
                        component=
                        {<XDropdown
                            options={UNITS_OPTIONS}
                            displayEmpty
                            value={selectedUnits}
                            // placeholder='Unit selector (allows multiple values).'
                            hasCheckboxes={true}
                            multiple={true}
                            disableClearable
                            labelOptions='label'
                            width='100%'
                            bgColor='100'
                            size='small'
                            style={{ margin: '0' }}
                            onChange={(e, value) => editActivityInfo(e, 'units', 'edit-multiple-values', value)}
                        />}
                        htmlFor={'units'}
                        labelLocation='left'
                    />
                    {/* ISBNs */}
                    <MultipleValueInput 
                        labelName='ISBNs: '
                        keyToEdit='isbn'
                        data={activityInfo}
                        editData={editActivityInfo}
                    />
                    {/* <StyledXLabel
                        label={'Category:'}
                        paddingSize='s'
                        component=
                        {<XInput
                            id='category'
                            size='small'
                            fullWidth
                            style={{ margin: '0' }}
                        // onChange={editActivityInfo}
                        />}
                        htmlFor={'category'}
                        labelLocation='left'
                    /> */}
                </>
            }
            <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '24px'}}>
                {tags.map((tag, index) => (
                    <XTag
                        key={'tag_' + index}
                        name={'#' + tag.name}
                        status={tag.status || 'correct'}
                        style={{marginTop: '8px'}}
                        isRemovable
                        onClickRemove={() => tag.onClick(tags, index)}
                    />
                ))}
            </div>
        </XCard>
    )
};
