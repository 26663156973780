import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { XContainer, XLogin } from '@ximdex/xui-react/material';
import useAuth from '../../hooks/useAuth';
import { loginUserKakuma } from '../../services/xevalApi.service';
import { COOKIE_NAME, CLIENT } from '../../CONSTANTS';

const Login = () => {
    const navigate = useNavigate();

    const { handleLimitedLogin, isAuth, getCookie, setCookie } = useAuth();

    useEffect(() => {
        const userData = getCookie(COOKIE_NAME);
        
        if (userData) {
            const isStudent = userData?.user?.roles?.some((role) => role?.title === 'Student') ?? true;

            if (isStudent && userData?.user?.roles?.length < 2) {
                return;
            } else {
                sessionStorage.setItem(COOKIE_NAME, JSON.stringify(userData));
                navigate('/');
            };
        } else if (isAuth()) {
            navigate('/');
        };
    }, []);

    const handleLogin = async (email, password) => {
        const isClientSEK = CLIENT === 'SEK'
        try {
            const userData = isClientSEK ? await loginUserKakuma(email, password) : await handleLimitedLogin(email, password);

            if (userData?.user && !isClientSEK) {
                sessionStorage.setItem(`${COOKIE_NAME}_XEVAL`, JSON.stringify(userData));
                navigate('/');
            };

            if (userData?.user && isClientSEK) {
                const isStudent = userData?.user?.roles?.some((role) => role?.title === 'Student') ?? true;

                if (isStudent && userData?.user?.roles?.length < 2) {
                    return { error: 'Students are unauthorized from using this tool.'};
                } else {
                    sessionStorage.setItem(`${COOKIE_NAME}_XEVAL`, JSON.stringify({
                        is_connected: true,
                        user: {
                            name: 'xEval User',
                            email: email
                        }
                    }));
                    sessionStorage.setItem(COOKIE_NAME, JSON.stringify(userData)); 
                    setCookie(COOKIE_NAME, userData);
              
                    navigate('/');
                } ;
            };
            
            return {error: userData ? '' : 'Check your credentials and try again later.'};
        } catch(error) {
            console.error(error);
            return {error: 'Check your credentials and try again later.'};
        };
    };

    return (
        <XContainer
            style={{
                width: "100%",
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <XLogin 
                hasLogo 
                handleLogin={handleLogin}
                setErrorMessage={() => {}}
            />
        </XContainer>
    )
}

export default Login
