import ACTIVITY_TYPE from '../../default/activities/Activities'
import * as DEFAULT from '../../default/activities/EditSettings'

export const SEK_ACTIVITIES_PREVIEWS = {
    ...DEFAULT.ACTIVITIES_PREVIEWS,
}

export const SEK_ACTIVITIES_TOOLTIPS = {
    ...DEFAULT.ACTIVITIES_TOOLTIPS,
}

export const SEK_ACTIVITIES_CONTENT_SETTINGS = {
    ...DEFAULT.ACTIVITIES_CONTENT_SETTINGS,
}

export const SEK_ANSWERS_TEMPLATE = {
    ...DEFAULT.ANSWERS_TEMPLATE,
}

export const SEK_ACTIVITIES_ANSWERS_OPTIONS_SETTINGS = {
    ...DEFAULT.ACTIVITIES_ANSWERS_OPTIONS_SETTINGS,
    ACTIVITIES: [
        ...DEFAULT.ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.ACTIVITIES,
        ACTIVITY_TYPE.DEDUCTION,
        ACTIVITY_TYPE.SHORT_QUESTION,
        ACTIVITY_TYPE.OPINION_SCALE,
        ACTIVITY_TYPE.TEXT_CHOICE,
        ACTIVITY_TYPE.IMAGE_CHOICE,
        ACTIVITY_TYPE.IMAGE_PAIRING,
    ],
    INDIVIDUAL_FEEDBACK: {
        ...DEFAULT.ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.INDIVIDUAL_FEEDBACK,
        [ACTIVITY_TYPE.SINGLE_CHOICE]: { LIMIT_FEEDBACK_TEXT: 550 },
        [ACTIVITY_TYPE.MULTIPLE_CHOICE]: { LIMIT_FEEDBACK_TEXT: 550 },
        [ACTIVITY_TYPE.TRUE_FALSE]: { LIMIT_FEEDBACK_TEXT: 550 },
        [ACTIVITY_TYPE.SHORT_QUESTION]: { LIMIT_FEEDBACK_TEXT: 550 },
        [ACTIVITY_TYPE.CARD_CHOICE]: { LIMIT_FEEDBACK_TEXT: 550 },
    },
}

export const SEK_ACTIVITIES_PROPERTIES_SETTINGS = {
    ...DEFAULT.ACTIVITIES_PROPERTIES_SETTINGS,
}

export const SEK_ACTIVITIES_GLOBAL_FEEDBACK = [
    ...DEFAULT.ACTIVITIES_GLOBAL_FEEDBACK,
]

export const SEK_OPTIONS_WITH_TINY_EDITOR  = [
    ...DEFAULT.OPTIONS_WITH_TINY_EDITOR,
]
