import React from 'react';
import { XBox, XLabel } from '@ximdex/xui-react/material';
import { PoweredBy } from '../../components/index';
import { StyledDivFlexAroundWrap, StyledHomeXBox } from '../../Styled-Components/index';
import { StyledLink } from '../../Styled-Components/Links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy as regularCopy, faFile as regularFile } from '@fortawesome/free-regular-svg-icons';
import { faCopy as solidCopy, faFile as solidFile } from '@fortawesome/free-solid-svg-icons';

const Home = (props) => {
    const activityElements = [
        {
            icon: regularFile,
            id: 'create-activity',
            name: 'Create new activity'
        },
        {
            icon: solidFile,
            id: 'list-activities',
            name: 'List activities'
        }
    ];
    const assessmentElements = [
        {
            icon: regularCopy,
            id: 'create-assessment',
            name: 'Create assessment'
        },
        {
            icon: solidCopy,
            id: 'list-assessments',
            name: 'List assessments'
        }
    ];
    return (
        <>
            <StyledDivFlexAroundWrap>
                <StyledDivFlexAroundWrap>
                    {activityElements.map((element, index) => (
                        <StyledLink
                            key={'regularElement' + index}
                            to={'/' + element.id}
                            style={{ color: '#214F61' }}>
                            <XLabel
                                style={{ width: '100%' }}
                                label={element.name}
                                paddingSize='s'
                                component=
                                {<StyledHomeXBox id={element.id}>
                                    <FontAwesomeIcon icon={element.icon} size='6x' />
                                </StyledHomeXBox>}
                                htmlFor={element.name}
                                labelLocation='bottom'
                            />
                        </StyledLink>
                    ))}

                </StyledDivFlexAroundWrap>
                <StyledDivFlexAroundWrap>
                    {assessmentElements.map((element, index) => (
                        <StyledLink
                            key={'solidElement' + index}
                            to={'/' + element.id}
                            style={{ color: '#214F61' }}>
                            <XLabel
                                label={element.name}
                                isCentered
                                paddingSize='s'
                                component=
                                {<StyledHomeXBox id={element.id}>
                                    <FontAwesomeIcon icon={element.icon} size='6x' />
                                </StyledHomeXBox>}
                                htmlFor={element.name}
                                labelLocation='bottom'
                            />
                        </StyledLink>
                    ))}
                </StyledDivFlexAroundWrap>
            </StyledDivFlexAroundWrap>
            <PoweredBy />
        </>
    )
}

export default Home;
