const theme = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#306F70',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#303030',
            paper: '#424242',
        },
        padding: {
            sm: '1rem'
        }
    }
}

export default theme;
