import ACTIVITY_TYPE from '../../default/activities/Activities'

export const MHE_ACTIVITIES_INIT = 
[{"es-ES": [
    {
        "type": "single-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escoge la respuesta correcta.",
        "text": "¿Cómo se llama el río más largo del mundo?",
        "options": [
            { "id": 0, "is_right": "FALSE", "title": "Yangtsé​" },
            { "id": 0, "is_right": "FALSE", "title": "Amazonas" },
            { "id": 0, "is_right": "TRUE", "title": "Nilo" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "multiple-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Marca las respuestas que son correctas.",
        "text": "¿Cuáles son planetas del sistema solar?",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Neptuno" },
            { "id": 0, "is_right": "FALSE", "title": "Luna" },
            { "id": 0, "is_right": "TRUE", "title": "Júpiter" },
            { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
            { "id": 0, "is_right": "FALSE", "title": "Draugr" },

        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    // only for MHE
    {
        "type": "match",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Empareja las opciones como corresponda",
        "text": "Ordena cronológicamente estas edades de la historia (de más a menos reciente).",
        "options": [
            { "id": 0, "target_order": 1, "title": "Edad Moderna", "is_right": "TRUE" },
            { "id": 0, "target_order": 3, "title": "Edad Antigua", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "Edad Media", "is_right": "TRUE" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "Primera" },
            { "id": 0, "order": 2, "title": "Segunda" },
            { "id": 0, "order": 3, "title": "Tercera" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "true-false",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Marca si la afirmación es verdadera o falsa.",
        "text": "Las sustancias iniciales de una reacción química se denominan reactivos.",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Verdadero" },
            { "id": 0, "is_right": "FALSE", "title": "Falso" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    // only for MHE
    {
        "type": "input",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escribe tu respuesta a continuación.",
        "text": "¿Cómo se llama la unidad más pequeña de la vida?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["célula"] },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    {
        "type": "input-long",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escribe tu respuesta a continuación.",
        "text": "¿Cómo se llama la unidad más pequeña de la vida?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": [] },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    // only for MHE
    {
        "type": "fit-word",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Arrastra cada palabra a la casilla correspondiente.",
        "text": "La ecología es el estudio de las relaciones entre los {2} vivos, incluidos los humanos, y su {1} físico.",
        "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
        "options": [
            { "id": 0, "target_order": 2, "title": "organismos", "is_right": "TRUE"},
            { "id": 0, "target_order": 1, "title": "entorno", "is_right": "TRUE" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    {
        "type": "fill-the-blanks",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Rellena los espacios en blanco para completar la frase siguiente:",
        "text": "Un {1} no es más que una serie de de pasos que se siguen para completar una determinada {2}.",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["algoritmo"] },
            { "id": 0, "order": 2, "accepted_values": ["tarea"] },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "order-chronology",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Ordena las imágenes según la cantidad de criterios que cumplen: facilitan tu movilidad, la movilidad de tus alumnos, trabajar en grupos y mirar la pizarra.",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Permite trabajar en grupos, pueden moverse, pero algunos están de espaldas a la pizarra." },
            { "id": 0, "order": 1, "title": "Permite trabajar en grupos, mirar la pizarra y facilita la movilidad de la mayoría de los alumnos." },
            { "id": 0, "order": 2, "title": "Permite trabajar en grupos, pueden moverse bien, pero no todos verán bien la pizarra." }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "word-search",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Busca estas frutas: plátano, pera y coco.",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["platano", "pera", "coco"] },
        ],
        "props": {
            "time": 120, "background_image": { }
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "word-catcher",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Atrapa la fruta.",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["Platano"],
                "unaccepted_values": ["Pizza", "Cebolla", "Bocadillo", "Queso", "Mantequilla", "Sopa", "Huevo", "Pasta", "Tomate", "Lechuga", "Pan"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "seek-and-find",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Toca donde está corazón en la imagen.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    {
        "type": "correspondence",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Título de actividad de tipo correspondencia",
        "text": "Contenido de actividad de tipo correspondencia",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 1, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    // only for MHE
    {
        "type": ACTIVITY_TYPE.RANK,
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Pon las opciones en orden.",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "Primera" },
            { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Segunda" },
            { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Tercera" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Permite trabajar en grupos, pueden moverse, pero algunos están de espaldas a la pizarra." },
            { "id": 0, "order": 1, "title": "Permite trabajar en grupos, mirar la pizarra y facilita la movilidad de la mayoría de los alumnos." },
            { "id": 0, "order": 2, "title": "Permite trabajar en grupos, pueden moverse bien, pero no todos verán bien la pizarra." }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    }
]},


{"ca-ES": [
    {
        "type": "single-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Com es diu el riu més llarg del món?",
        "text": "Escull la resposta correcta.",
        "options": [
            { "id": 0, "is_right": "FALSE", "title": "Iang-Tsé" },
            { "id": 0, "is_right": "FALSE", "title": "Amazones" },
            { "id": 0, "is_right": "TRUE", "title": "Nil" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "multiple-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Marca les respostes que són correctes.",
        "text": "Quins són planetes del sistema solar?",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Neptú" },
            { "id": 0, "is_right": "FALSE", "title": "Lluna" },
            { "id": 0, "is_right": "TRUE", "title": "Júpiter" },
            { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
            { "id": 0, "is_right": "FALSE", "title": "Draugr" },

        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    // only for MHE
    {
        "type": "match",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Emparella les opcions com correspongui.",
        "text": "Ordena cronològicament aquestes edats de la història (de més a menys recent).",
        "options": [
            { "id": 0, "target_order": 1, "title": "Edat Moderna", "is_right": "TRUE" },
            { "id": 0, "target_order": 3, "title": "Edat Antiga", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "Edat Mitjana", "is_right": "TRUE" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "Primera" },
            { "id": 0, "order": 2, "title": "Segona" },
            { "id": 0, "order": 3, "title": "Tercera" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "true-false",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Marca si l'afirmació és vertadera o falsa.",
        "text": "Les substàncies inicials d'una reacció química es denominen reactius.",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Vertader" },
            { "id": 0, "is_right": "FALSE", "title": "Fals" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    // only for MHE
    {
        "type": "input",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escriu la teva resposta a continuació.",
        "text": "Com es diu la unitat més petita de la vida?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["cèl·lula"] },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ],
    },
    {
        "type": "input-long",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escriu la teva resposta a continuació.",
        "text": "Com es diu la unitat més petita de la vida?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": [] },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ],
    },
    // only for MHE
    {
        "type": "fit-word",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Arrossega cada paraula a la casella corresponent.r",
        "text": "L'ecologia és l'estudi de les relacions entre els {2} vius, inclosos els humans, i el seu {1} físic.",
        "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
        "options": [
            { "id": 0, "target_order": 2, "title": "organismes", "is_right": "TRUE" },
            { "id": 0, "target_order": 1, "title": "entorn", "is_right": "TRUE" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ],
    },
    {
        "type": "fill-the-blanks",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Omple els espais en blanc per completar la frase següent:",
        "text": "Un {1} no és més que una sèrie de passos que se segueixen per completar una determinada {2}.",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["algoritme"] },
            { "id": 0, "order": 2, "accepted_values": ["tasca"] },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "order-chronology",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Ordena les imatges segons la quantitat de criteris que compleixen: faciliten la teva mobilitat, la mobilitat dels alumnes, treballar en grups i mirar la pissarra.",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Permet treballar en grups, es poden moure, però alguns estan d'esquena a la pissarra." },
            { "id": 0, "order": 1, "title": "Permet treballar en grups, mirar la pissarra i facilitar la mobilitat de la majoria dels alumnes." },
            { "id": 0, "order": 2, "title": "Permet treballar en grups, es poden moure bé, però no tots veuran bé la pissarra." }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "word-search",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Busca aquestes fruites: plàtan, pera i coco.",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["platan", "pera", "coco"] },
        ],
        "props": {
            "time": 120, "background_image": { }
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "word-catcher",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Atrapa la fruita.",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["Platan"],
                "unaccepted_values": ["Pizza", "Ceba", "Entrepà", "Formatge", "Mantega", "Sopa", "Ou", "Pasta", "Tomàquet", "Enciam", "Pa"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "seek-and-find",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Toca on està el cor a la imatge.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    {
        "type": "correspondence",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Títol d'activitat de tipus correspondència",
        "text": "Contingut d'activitat de tipus correspondència",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 1, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    },
    // only for MHE
    {
        "type": ACTIVITY_TYPE.RANK,
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Posa les opcions en ordre.",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "Primera" },
            { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Segona" },
            { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Tercera" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Permet treballar en grups, es poden moure, però alguns estan d'esquena a la pissarra." },
            { "id": 0, "order": 1, "title": "Permet treballar en grups, mirar la pissarra i facilita la mobilitat de la majoria dels alumnes." },
            { "id": 0, "order": 2, "title": "Permet treballar en grups, es poden moure bé, però no tots veuràn bé la pissarra." }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
            { "success_percentage": 100, "text": "Molt bé!" }
        ]
    }
]},


{"en-EN": [
    {
        "type": "single-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "What is the name of the longest river in the world?",
        "text": "Select the correct answer",
        "options": [
            { "id": 0, "is_right": "FALSE", "title": "Yangtze" },
            { "id": 0, "is_right": "FALSE", "title": "Amazon" },
            { "id": 0, "is_right": "TRUE", "title": "Nile" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "multiple-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Select the answers that are correct",
        "text": "Which are planets of the Solar System?",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Neptune" },
            { "id": 0, "is_right": "FALSE", "title": "Moon" },
            { "id": 0, "is_right": "TRUE", "title": "Jupiter" },
            { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
            { "id": 0, "is_right": "FALSE", "title": "Draugr" },

        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    // only for MHE
    {
        "type": "match",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Match the options with their correct target",
        "text": "Order these Ages of History chronologically (more recent first)",
        "options": [
            { "id": 0, "target_order": 1, "title": "Modern Age", "is_right": "TRUE" },
            { "id": 0, "target_order": 3, "title": "Ancient Age", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "Medieval Age", "is_right": "TRUE" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "First" },
            { "id": 0, "order": 2, "title": "Second" },
            { "id": 0, "order": 3, "title": "Third" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "true-false",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Select if the statement is true or false",
        "text": "The starting substances of a chemical reaction are called the reactants",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "True" },
            { "id": 0, "is_right": "FALSE", "title": "False" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    // only for MHE
    {
        "type": "input",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Write your answer below",
        "text": "What is the name of the smallest unit of life?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["cell"] },
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ],
    },
    {
        "type": "input-long",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Write your answer below",
        "text": "What is the name of the smallest unit of life?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": [] },
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ],
    },
    // only for MHE
    {
        "type": "fit-word",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Drag each word into its container",
        "text": "Ecology is the study of the relationships between living {2}, including humans, and their physical {1}.",
        "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
        "options": [
            { "id": 0, "target_order": 2, "title": "organism", "is_right": "TRUE" },
            { "id": 0, "target_order": 1, "title": "environment", "is_right": "TRUE"},
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ],
    },
    {
        "type": "fill-the-blanks",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Fill in the blanks to complete the following sentence:",
        "text": "An {1} is simply a set of steps used to complete a specific {2}.",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["algorithm"] },
            { "id": 0, "order": 2, "accepted_values": ["task"] },
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    },
    {
        "type": "order-chronology",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Sort the images according to the highest number of these criteria: facilitating your mobility, your students' mobility, group work and looking at the board",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@", "image_url": "https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93d8ad0f-6652-4b6c-a347-1882db4c20f5@ce21e5a6-fa93-40d0-b064-9e87e4783b85@@@" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Allows working in groups, they can move, but some of them have their back to the blackboard." },
            { "id": 0, "order": 1, "title": "Allows working in groups, looking at the blackboard and facilitates the mobility of most of the students." },
            { "id": 0, "order": 2, "title": "Allows working in groups, they can move well, but not all will see the blackboard well." }
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    },
    {
        "type": "word-search",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Look for these fruits: banana, pear, and coconut.",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["banana", "pear", "coconut"] },
        ],
        "props": {
            "time": 120, "background_image": { }
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    },
    {
        "type": "word-catcher",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Catch the fruit.",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["Banana"],
                "unaccepted_values": ["Pizza", "Onion", "Sandwich", "Cheese", "Butter", "Soup", "Egg", "Pasta", "Tomato", "Lettuce", "Bread"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    },
    {
        "type": "seek-and-find",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Touch where the heart is in the image.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    },
    {
        "type": "correspondence",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Título de actividad prueba correspondence",
        "text": "Contenido de actividad prueba correspondence",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 1, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 }
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    },
    // only for MHE
    {
        "type": ACTIVITY_TYPE.RANK,
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Sort the options in order",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "First" },
            { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Second" },
            { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Third" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Allows working in groups, they can move, but some of them have their back to the blackboard." },
            { "id": 0, "order": 1, "title": "Allows working in groups, looking at the blackboard and facilitates the mobility of most of the students." },
            { "id": 0, "order": 2, "title": "Allows working in groups, they can move well, but not all will see the blackboard well." }
        ],
        "feedbacks": [
            { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
            { "text": "Well done!", "success_percentage": 100 }
        ]
    }
]}
]
