import * as SETTINGS from './constants/index'

const { 
    REACT_APP_CLIENT, 
    REACT_APP_API_ENV, 
    REACT_APP_XEVAL_API, 
    REACT_APP_XDAM_API 
} = process.env;

const isProduction = () => {
    return REACT_APP_API_ENV.toLowerCase() === 'production'
}

const isSEK = () => {
    return REACT_APP_CLIENT.toUpperCase() === 'SEK'
}

const isMHE = () => {
    return REACT_APP_CLIENT.toUpperCase() === 'MHE'
}

const isProSEK = () => {
    return REACT_APP_API_ENV.toLowerCase() === 'production' && REACT_APP_CLIENT.toUpperCase() === 'SEK'
}

const isProMHE = () => {
    return (REACT_APP_API_ENV.toLowerCase() === 'production' && REACT_APP_CLIENT.toUpperCase() === 'MHE')
}

export const CLIENT = REACT_APP_CLIENT.toUpperCase()
export const ENVIRONMENT = REACT_APP_API_ENV.toLowerCase().startsWith('pro') ? 'PRO' : 'PRE' 
export const COOKIE_NAME = `USERDATA_${CLIENT}_${ENVIRONMENT}`

export const XEDIT_SEK_URL = isProduction() ? 'http://editorv1.cloud.ximdex.net/' : 'http://editor.pre-cloud.ximdex.net/'

export const XPANEL_SEK_URL = isProduction() ? 'http://xpanel.cloud.ximdex.net/' : 'http://xpanel.pre-cloud.ximdex.net/'

const API = 'api/v1.0';
export const XEVAL_URI_API = REACT_APP_XEVAL_API
export const XDAM_URI_API = REACT_APP_XDAM_API

export const XDAM_URI_RESOURCE = XDAM_URI_API + '/resource/render/'

export const FETCH_ACTIVITY_TYPES = `${XEVAL_URI_API}/${API}/activity_type`;
export const DELETE_TRANSLATION = `${XEVAL_URI_API}/${API}/translation/delete`
export const ACTIVITIES = `${XEVAL_URI_API}/${API}/activities`;
export const ASSESSMENTS = `${XEVAL_URI_API}/${API}/assessments`;
export const MEDIA_PREVIEW = `${XDAM_URI_API}/resource/render/`;
export const BASE_API_DAM_MEDIA = `${XDAM_URI_API}`;
export const LOGIN_KAKUMA = 'http://kakumav1.pre-cloud.ximdex.net/api/v1/login';
export const USER_ACTIVITY_ID = `${XEVAL_URI_API}/${API}/user-activities/preview`;
export const USER_ASSESSMENT_ID = `${XEVAL_URI_API}/${API}/user-assessments/preview`;

export const CORRECT_OPTIONS = [
    { value: 'TRUE', label: 'Yes' },
    { value: 'FALSE', label: 'No' }
];

export const MATRIX_SIZE_OPTIONS = [
    { label: 'Small (8 x 8)', value: 8, width: 8, height: 8 },
    { label: 'Regular (10 x 10)', value: 10, width: 10, height: 10 },
    { label: 'Large (12 x 12)', value: 12, width: 12, height: 12 },
];

export const STATUS_OPTIONS = isSEK() ? SETTINGS.SEK_STATUS_OPTIONS : SETTINGS.MHE_STATUS_OPTIONS

export const ACTIVITIES_PREVIEWS = isSEK() ? SETTINGS.SEK_ACTIVITIES_PREVIEWS : SETTINGS.MHE_ACTIVITIES_PREVIEWS

export const ACTIVITIES_GLOBAL_FEEDBACK = isSEK() ? SETTINGS.SEK_ACTIVITIES_GLOBAL_FEEDBACK : SETTINGS.MHE_ACTIVITIES_GLOBAL_FEEDBACK

export const ACTIVITIES_CONTENT_SETTINGS = isSEK() ? SETTINGS.SEK_ACTIVITIES_CONTENT_SETTINGS : SETTINGS.MHE_ACTIVITIES_CONTENT_SETTINGS 

export const ACTIVITIES_ANSWERS_TEMPLATE = isSEK() ? SETTINGS.SEK_ANSWERS_TEMPLATE : SETTINGS.MHE_ANSWERS_TEMPLATE

export const ACTIVITIES_ANSWERS_OPTIONS_SETTINGS = isSEK() ? SETTINGS.SEK_ACTIVITIES_ANSWERS_OPTIONS_SETTINGS : SETTINGS.MHE_ACTIVITIES_ANSWERS_OPTIONS_SETTINGS

export const ACTIVITIES_PROPERTIES_SETTINGS = isSEK() ? SETTINGS.SEK_ACTIVITIES_PROPERTIES_SETTINGS : SETTINGS.MHE_ACTIVITIES_PROPERTIES_SETTINGS

export const ACTIVITIES_TOOLTIPS = isSEK() ? SETTINGS.SEK_ACTIVITIES_TOOLTIPS : SETTINGS.MHE_ACTIVITIES_TOOLTIPS

export const ACTIVITIES_INIT = isSEK() ? SETTINGS.SEK_ACTIVITIES_INIT : SETTINGS.MHE_ACTIVITIES_INIT

export const PAGINATION_OPTIONS = isSEK() ? SETTINGS.SEK_PAGINATION_OPTIONS : SETTINGS.MHE_PAGINATION_OPTIONS

export const SORTING_OPTIONS = isSEK() ? SETTINGS.SEK_SORTING_OPTIONS : SETTINGS.MHE_SORTING_OPTIONS

export const SORTING_OPTIONS_ASSESSMENTS = isSEK() ? SETTINGS.SEK_SORTING_OPTIONS : SETTINGS.MHE_SORTING_OPTIONS_ASSESSMENTS

export const LANGUAGES_OPTIONS = isSEK() ? SETTINGS.SEK_LANGUAGES_OPTIONS : SETTINGS.MHE_LANGUAGES_OPTIONS

export const LANGUAGES_OPTIONS_SHOW_ALL = isSEK() ? SETTINGS.SEK_LANGUAGES_OPTIONS_SHOW_ALL : SETTINGS.MHE_LANGUAGES_OPTIONS_SHOW_ALL

export const ACTIVITIES_LIST_ALL_TYPES = isSEK() ? SETTINGS.SEK_ACTIVITIES_LIST_ALL_TYPES : SETTINGS.MHE_ACTIVITIES_LIST_ALL_TYPES

export const OPTIONS_WITH_TINY_EDITOR  = isSEK() ? SETTINGS.SEK_OPTIONS_WITH_TINY_EDITOR : SETTINGS.MHE_OPTIONS_WITH_TINY_EDITOR

export const UNITS_OPTIONS = SETTINGS.MHE_UNITS_OPTIONS

export const PREVIEW_CHANNELS = [{ value: 'mobile', label: 'mobile' }, { value: 'web', label: 'web' }]
