import styled from "styled-components/macro";
import { XDropdown } from "@ximdex/xui-react/material";

export const StyledXDropdown = styled(XDropdown)`
    &.css-1nxfwx6-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: unset;
    }
    &.MuiOutlinedInput-root {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }
`;
