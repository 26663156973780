import React from 'react';
import { XCard, XInput, XButton } from '@ximdex/xui-react/material';
import { 
    TrashIcon, 
    StyledFeedbackContainer, 
    StyledButtonCenterContainer,
    ExtraInfoText,
    StyledFontAwesomeIcon
} from '../../Styled-Components';

export const GlobalFeedback = ({
    TOOLTIPS,
    activityInfo,
    handleInfoUpdated
}) => {  
    const charLimit = 550

    const modifyFeedbacks = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo };

        switch(action) {
            case 'add':
                if (!newInfo.feedbacks || newInfo.feedbacks.length === 0) {
                    newInfo.feedbacks = [{ "success_percentage": 0, "text": "Mmmm, try again." }];
                } else if (newInfo.feedbacks.length === 1) {
                    newInfo.feedbacks.push({ "success_percentage": 100, "text": "Well done!" });
                } else if (newInfo.feedbacks.length >= 2) {
                    const newPercentage = (newInfo.feedbacks[newInfo.feedbacks.length - 1].success_percentage + newInfo.feedbacks[newInfo.feedbacks.length - 2].success_percentage) / 2; 
                    const newFeedback = { 
                        success_percentage: parseInt(newPercentage), 
                        text: `You have completed this activity with a score of at least ${newPercentage}%` 
                    };
                    newInfo.feedbacks.splice(-1, 0, newFeedback);
                } 
                break;
            case 'delete':
                newInfo.feedbacks.splice(index, 1);
                break;
            case 'edit':
                newInfo.feedbacks[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        handleInfoUpdated(newInfo, false)
    };
   
    return (
        <XCard
            isCollapsable={true}
            title='Global feedback'
            marginXSize='s'
            tooltip={
                <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.GLOBAL_FEEDBACK} size='1x' isInfo  />
            }
        >
            {activityInfo?.feedbacks && activityInfo?.feedbacks?.length > 0 && activityInfo?.feedbacks.map((feedback, index) => (
                <StyledFeedbackContainer key={index}> 
                    <XInput
                        label='Success (%)'
                        name='Success (%)'
                        value={feedback?.success_percentage ?? ''}
                        type='number'
                        inputProps={{ min: 0, max: 100 }}
                        size='small'
                        style={{ margin: '8px', minWidth: '92px', width: '20%' }}
                        fullWidth
                        disabled={feedback?.success_percentage === 0 ? true : false}
                        onChange={(e) => modifyFeedbacks('edit', index, 'success_percentage', parseInt(e.target.value))}
                    />
                    <div style={{ width: 'calc(80% - 32px)'}}>
                        <XInput
                            label='Comment'
                            value={feedback?.text || ''}
                            style={{ margin: '8px 8px 8px 8px'  }}
                            type='text'
                            size='small'
                            fullWidth
                            multiline
                            rows={5}
                            onChange={(e) => modifyFeedbacks('edit', index, 'text', e.target.value)}
                            inputProps={{ maxLength: charLimit || 200 }}
                        />
                        <ExtraInfoText>{feedback.text?.length || 0} of {charLimit || 200} characters</ExtraInfoText>
                    </div>
                    {feedback.success_percentage !== 0 &&
                        <TrashIcon 
                            style={{margin: '8px 0px 8px 16px'}}
                            onClick={() => modifyFeedbacks('delete', index)} 
                        />
                    }
                </StyledFeedbackContainer>
            ))}
        
            <StyledButtonCenterContainer>
                <XButton onClick={() => modifyFeedbacks('add')}>
                    Add new feedback
                </XButton>
            </StyledButtonCenterContainer>
        </XCard>
    )
}
