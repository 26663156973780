import React from 'react';
import { XCard, XInput } from '@ximdex/xui-react/material';
import { ExtraInfoText } from '../../Styled-Components';

const ActivityHeader = ({
    activityTitle,
    editActivityInfo
}) => {

    return (
        <XCard
            isCollapsable
            title='Header'
            marginXSize='m'
        >
            <XInput
                style={{ margin: '8px', width: '95%' }}
                id='title'
                size='small'
                fullWidth
                multiline
                rows={4}
                value={activityTitle}
                onChange={(e) => editActivityInfo(e)}
                inputProps={{ maxLength: 150 }}
            />
            <ExtraInfoText>{activityTitle?.length || 0} of 150 characters</ExtraInfoText>
        </XCard>
    )
}

export default ActivityHeader;
