import HTMLReactParser from 'html-react-parser';
import React, {useState} from 'react';
import { XCard, XInput, XButton, XBox } from '@ximdex/xui-react/material';
import { faEdit, faSave,faXmark} from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledActionsEditTranslations, StyledTranslationSpan, StyledTranslationp} from '../../../Styled-Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeedbackTranslations = ({
    save,
    setSave,
    activityInfo, 
    activityTranslated, 
    setActivityTranslated, 
    langTabChecked,
    translatableField,
    getTitle,
    }) => 
    {
    const [isTranslationUpdated, setIsTranslationUpdate] = useState(true);
    const [editTranslation,setEditTranslation] =  useState(() => {
        let edits = {}
        activityInfo.available_languages.forEach(lang => {
            edits = {...edits, [lang]: []}
            if(activityInfo.feedbacks)
                activityInfo?.feedbacks.forEach((value,index) => {
                    let copy = {
                        edit: false
                    }
                    edits[lang].push(copy)
                })
        })
        return edits
    })
    // Lang Global Feedback Template
    const [feedbacksValues, setFeedbacksValues] = useState(() => {
        let inputs = {}
        activityInfo.available_languages.forEach(lang => {
            inputs = {...inputs, [lang]: []}
            if(activityInfo?.feedbacks)
                activityInfo?.feedbacks.forEach((value,index) => {
                    let copy = {
                        ...value,
                        text: ''
                    }
                    inputs[lang].push(copy)
                })
        })
        return inputs
    })

    const handleTranslate = (e,position) => {
        setIsTranslationUpdate(false)
        const input = e.target.value
        // Copy and reeplace value on state
        let feedbacksTraductionsCopy = feedbacksValues[langTabChecked]
        let item = feedbacksTraductionsCopy[position];
        item['text'] = input;
        feedbacksTraductionsCopy[position] = item;
        setFeedbacksValues({...feedbacksValues,[langTabChecked]:feedbacksTraductionsCopy});
    }

    const handleConfirmTranslation = (position) => {
        let activityTranslatedCopy = activityTranslated
        activityTranslatedCopy = {...activityTranslatedCopy,feedbacks:feedbacksValues[langTabChecked]}
        
        // Set input invisible
        let editCopy = editTranslation[langTabChecked]
        editCopy[position].edit = false
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})

        setActivityTranslated(activityTranslatedCopy)
        setIsTranslationUpdate(true)
        setSave(!save)
    }

    const handleCancel = (position) => {
        setIsTranslationUpdate(true)
         // Set input invisible
        let editCopy = editTranslation[langTabChecked]
        editCopy[position].edit = false
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})

        setFeedbacksValues({...feedbacksValues, [langTabChecked]: activityTranslated[translatableField]})
    }

    const handleEditState = (position) =>{
        // Set input visible
        let editCopy = editTranslation[langTabChecked]
        editCopy[position].edit = true
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})
        setFeedbacksValues({...feedbacksValues, [langTabChecked]: activityTranslated[translatableField]})
    }

    return (<>
        <XCard
            isCollapsable={true}
            isCollapsed={false}
            title={getTitle(translatableField)}
            marginXSize='m'
        >   
            {activityInfo?.feedbacks.map((element,i) => 
                <XBox key={i}>
                    {element && <p style={{textAlign:'left', color: 'grey'}}><StyledTranslationSpan>Original: </StyledTranslationSpan>{`${element.success_percentage}%: `}{HTMLReactParser(String(element.text))}</p>}

                    {editTranslation[langTabChecked][i].edit 
                    ?   <div style={{display: 'flex', alignItems: 'center'}}>
                            <XInput
                                placeholder="Enter translation"
                                style={{ margin: '8px', width: '95%' }}
                                size='normal'
                                fullWidth
                                multiline
                                rows={2}
                                value={feedbacksValues && feedbacksValues[langTabChecked][i].text}
                                onChange={(e) => handleTranslate(e,i)}
                                inputProps={{ maxLength: 200 }}
                            />
                            <StyledActionsEditTranslations>
                                <XButton
                                    onClick={() => handleConfirmTranslation(i)}
                                    size='large' style={{margin:'0.2em'}}
                                    disabled={isTranslationUpdated}
                                >   
                                    <FontAwesomeIcon 
                                        icon={faSave}
                                        title="Save new translation" />
                                </XButton>
                                <XButton
                                    onClick={() => handleCancel(i)} 
                                    size='large' style={{margin:'0.2em'}}
                                >
                                    <StyledFontAwesomeIcon size='1x'
                                        icon={faXmark} 
                                        title={"Cancel translation"}
                                    />
                                </XButton>
                            </StyledActionsEditTranslations>
                        </div>
                    : 
                        <div style={{display:'flex', alignItems:'flex-start', flexDirection: 'column'}}>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {activityTranslated?.feedbacks &&
                                activityTranslated?.feedbacks.length > 0 &&
                                activityTranslated?.feedbacks[i].text !== ''
                                ? 
                                    <StyledTranslationp>
                                        <StyledTranslationSpan>Translation: </StyledTranslationSpan>{HTMLReactParser(String(activityTranslated.feedbacks[i].text))}</StyledTranslationp>
                                :
                                    <StyledTranslationp style={{color: '#F6AB0E'}}><StyledTranslationSpan>Translation: </StyledTranslationSpan>Nothing translated</StyledTranslationp>
                                } 
                                <XButton 
                                    onClick={() => handleEditState(i)} 
                                    size='small' style={{minWidth: 'unset', height:'100%'}}
                                >
                                    <StyledFontAwesomeIcon size='1x' 
                                        icon={faEdit} 
                                        title={'Edit translation'}
                                    />
                                </XButton>
                            </div>
                        </div>
                    }
                </XBox>
            )}
        </XCard>   

    </>);
}

export default FeedbackTranslations;
