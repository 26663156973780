import HTMLReactParser from 'html-react-parser';
import React, {useState} from 'react';
import { XCard, XInput, XButton, XBox} from '@ximdex/xui-react/material';
import { faEdit, faSave,faXmark} from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledActionsEditTranslations, StyledTranslationSpan, StyledTranslationp} from '../../../Styled-Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditorTinyMCE from '../../../components/TinyMCE/EditorTinyMCE';
import { OPTIONS_WITH_TINY_EDITOR } from '../../../CONSTANTS';

const OptionTranslations = ({   
        save,
        setSave,
        activityInfo, 
        activityTranslated, 
        setActivityTranslated, 
        langTabChecked,
        translatableField,
        getTitle,
    }) => 

    {
    const [isTranslationUpdated, setIsTranslationUpdate] = useState(true);
    const [editTranslation,setEditTranslation] =  useState(() => {
        let edits = {}
        activityInfo.available_languages.forEach(lang => {
            edits = {...edits, [lang]: []}
            activityInfo?.options.forEach((element,i) => {
                var copy = {}
                element.translatable_fields.forEach(translatableField => {
                    copy = {
                        ...copy,
                        [translatableField]: false
                    }
                })
                edits[lang].push(copy)
            })
        })
        return edits
    })
    // // Lang Options Template
    const [optionsValues, setOptionsValues] =  useState(()=>{
        let inputs = {}
        activityInfo.available_languages.forEach(lang => {
            inputs = {...inputs, [lang]: []}
            activityInfo?.options.forEach((element,i) => {
                var copy = element
                element.translatable_fields.forEach(translatableField => {
                    copy = {
                        ...copy,
                        [translatableField]: ''
                    }
                })
                inputs[lang].push(copy)
            })
        })
        return inputs
    })
    const [hasOptions,setHasOptions] =  useState(() => {
        let hasvalues = []
        if(activityInfo.options && activityInfo.options.length > 0){
            activityInfo?.options.forEach((element,i) => {
                hasvalues.push(false)
                element.translatable_fields.forEach(translatableField => {
                    if(element[translatableField])
                        hasvalues.splice(hasvalues.length-1,1,true);
                })
            })
        }
        return hasvalues
    })
    
    const handleTranslate = (e,fieldOption,position) => {
        const input = (OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && fieldOption !== 'feedback') ? e : e.target.value
        // Copy translated
        let optionValuesCopy = optionsValues[langTabChecked];
        let item = optionValuesCopy[position]
        item[fieldOption] = input
        optionValuesCopy[position] = item
        setOptionsValues({...optionsValues, [langTabChecked]: optionValuesCopy})
        setIsTranslationUpdate(false)
    }


    const handleConfirmTranslation = (position,fieldOption) => {
        let activityTranslatedCopy = activityTranslated

        // copy translated
        let activityOptionsTranslated = activityTranslatedCopy.options //array options
        let itemOptionTranslated = activityOptionsTranslated[position] // save item in position to edit

        itemOptionTranslated[fieldOption] = optionsValues[langTabChecked][position][fieldOption] // edit item
        activityOptionsTranslated[position] = itemOptionTranslated //save item

        activityTranslatedCopy = {...activityTranslatedCopy,options:activityOptionsTranslated} //save it to copy

        // Set input invisible
        let editCopy = editTranslation[langTabChecked]
        editCopy[position][fieldOption] = false
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})


        setActivityTranslated(activityTranslatedCopy) //save it transalted
        setIsTranslationUpdate(true)
        setSave(!save)
    }

    const handleCancel = (position,fieldOption) => {

         // Set input invisible
        let editCopy = editTranslation[langTabChecked]
        editCopy[position][fieldOption] = false
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})
        
        let optionsValuesCopy = optionsValues
        optionsValuesCopy[langTabChecked] = activityTranslated[translatableField]
        
        setOptionsValues(optionsValuesCopy)

        // setOptionsValues({...optionsValues, [langTabChecked]: activityTranslated[translatableField]})
        setIsTranslationUpdate(true)
    }

    const handleEditState = (position,fieldOption) =>{
        // Set input visible
        let editCopy = editTranslation[langTabChecked]
        editCopy[position][fieldOption] = true
        setEditTranslation({...editTranslation,[langTabChecked]: editCopy})
        setOptionsValues({...optionsValues, [langTabChecked]: activityTranslated[translatableField]})
    }
    return (<>
            {hasOptions.includes(true) && 
            <XCard
                isCollapsable={true}
                isCollapsed={false}
                title={getTitle(translatableField)}
                marginXSize='m'
            >
                {activityInfo?.options?.map((option,key) => 
                <div key={key}>   
                    {hasOptions[key] &&
                    <XCard 
                        style={{border: 'solid 1px rgba(0, 0, 0, 0.12)',borderRadius: '10px'}}
                        key={key}
                        // isCollapsable={true}
                        isCollapsed={false}
                        title={`Option ${key+1}`}
                        marginXSize='m'
                    >
                    {option.translatable_fields.map((translatable_field,j) => 
                        <div key={j}>
                            {activityInfo.options[key][translatable_field] && 
                            <XBox>

                                {activityInfo.options[key][translatable_field] && <p style={{textAlign:'left', color: 'grey'}}><StyledTranslationSpan>{`Original ${translatable_field.charAt(0).toUpperCase() + translatable_field.slice(1)}: `}</StyledTranslationSpan>{HTMLReactParser(String(activityInfo.options[key][translatable_field]))}</p>}
                                
                                {editTranslation[langTabChecked][key][translatable_field] 
                                ?  <div style={{display: 'flex', alignItems: 'center'}}>
                                        {OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && translatable_field !== 'feedback'
                                        ?
                                            <EditorTinyMCE 
                                                value={optionsValues && optionsValues[langTabChecked][key][translatable_field]} 
                                                handleEditorChange={(e) => handleTranslate(e, translatable_field,key)} 
                                                initialValue={{...activityTranslated.options[key][translatable_field]}}
                                                config={{
                                                    max_num_characters: 450,
                                                    plugins: [
                                                        'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                                        'FMathEditor, codesample, fontselect',
                                                        'damloader'
                                                    ],
                                                    toolbar: [`
                                                        undo redo | bold italic forecolor backcolor fontsizeselect | 
                                                        alignleft aligncenter alignright alignjustify | 
                                                        bullist numlist outdent indent | 
                                                        link damloader list table | 
                                                        charmap FMathEditorButton | 
                                                        removeformat
                                                    `],
                                                }}
                                            />
                                        :
                                            <XInput
                                            placeholder="Enter translation"
                                            style={{ margin: '8px', width: '95%' }}
                                            size='normal'
                                            fullWidth
                                            multiline
                                            rows={2}
                                            value={optionsValues && optionsValues[langTabChecked][key][translatable_field]}
                                            onChange={(e) => handleTranslate(e, translatable_field,key)}
                                            inputProps={{ maxLength: 200 }}
                                            />
                                        }
                                        <StyledActionsEditTranslations>
                                            <XButton
                                                onClick={() => handleConfirmTranslation(key,translatable_field)}
                                                size='large' style={{margin:'0.2em'}}
                                                disabled={isTranslationUpdated}
                                            >   
                                                <FontAwesomeIcon 
                                                    icon={faSave}
                                                    title="Save new translation" />
                                            </XButton>
                                            <XButton
                                                onClick={() => handleCancel(key,translatable_field)} 
                                                size='large' style={{margin:'0.2em'}}
                                            >
                                                <StyledFontAwesomeIcon size='1x'
                                                    icon={faXmark} 
                                                    title={"Cancel translation"}
                                                />
                                            </XButton>
                                        </StyledActionsEditTranslations>
                                    </div> 

                                : 
                                <>
                                    {activityInfo.options[key][translatable_field] &&
                                    
                                        <div style={{display:'flex', alignItems:'flex-start', flexDirection: 'column'}} >
                                            
                                            <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                                                    {typeof activityTranslated.options[key][translatable_field] === 'string' &&
                                                        activityTranslated.options[key][translatable_field] !== '' 
                                                    ? 
                                                        <StyledTranslationp><StyledTranslationSpan>Translation:</StyledTranslationSpan> {HTMLReactParser(String(activityTranslated.options[key][translatable_field]))}</StyledTranslationp>
                                                    :
                                                        <StyledTranslationp style={{color: '#F6AB0E'}}><StyledTranslationSpan>Translation:</StyledTranslationSpan> Nothing translated</StyledTranslationp>
                                                    }
                                                    <XButton 
                                                        onClick={() => handleEditState(key,translatable_field)} 
                                                        size='small' style={{minWidth: 'unset', height:'100%'}}
                                                    >
                                                        <StyledFontAwesomeIcon size='1x' 
                                                            icon={faEdit} 
                                                            title={'Edit translation'}
                                                        />
                                                    </XButton> 
                                            </div>
                                        </div>
                                    }
                                </> 
                                }

                            </XBox>}
                        </div>
                    )}
                    </XCard>}
                </div>)}
            </XCard>}           
        </>
    )
}


export default OptionTranslations;
