import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Layout/Navbar/Navbar';
import useAuth from '../hooks/useAuth';

const PrivateRoute = ({ component }) => {
    const location = useLocation();
    const { isAuth } = useAuth();

    const isAuthPrivateRoute = isAuth();

    if (isAuthPrivateRoute) {
        return (
            <>
                <Navbar />
                {component}
            </>
        ) 
    } else {
        return (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    };
};

export default PrivateRoute;
