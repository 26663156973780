import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    StyledDivFlexWrap, 
    StyledActivityCardsContainer, 
    StyledPageContainer,
    StyledFontAwesomeIcon
} from '../../Styled-Components/index';
import { StyledCardTitle, StyledCardText } from '../../Styled-Components/Paragraph';
import { fetchActivityTypes, createNewActivity, getActivityById } from '../../services/xevalApi.service';
import { XBox, XButton, XDropdown } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { ACTIVITIES_ANSWERS_TEMPLATE, LANGUAGES_OPTIONS } from '../../CONSTANTS';
import { FormControl, InputLabel } from '@mui/material';
import useModals from '../../hooks/useSweetAlert';

const ActivitySelector = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const {modal} = useModals()

    const activityToDuplicate = location?.state?.activity || undefined
    const { showSpinner, hideSpinner } = useSpinner()
    const [activities, setActivities] = useState([])
    const [defaultLang, setDefaultLang] = useState({label:'Select language', value: 'default'})

    useEffect(() => {
        if (!activityToDuplicate) getActivityTypes()
    }, []);

    const getActivityTypes = async () => {
        showSpinner();
        await fetchActivityTypes().then(data => {
            setActivities(data);
        }).catch(err => {
            console.error(err)
            XPopUp({
                message:"There's been an error loading your activities. Please, try again.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner();
        });
    }

    const handleLangChange = (event) => {
        const newLang = LANGUAGES_OPTIONS.find((option) => option.label === event.target.textContent)
        setDefaultLang(newLang)
    }

    const createActivity = async (activityType) => {
        showSpinner()
        await createNewActivity({activityType: activityType, activityLang: defaultLang?.value})
        .then(data => {
            if (data?.id) {
                return data?.id
            } else {
                XPopUp({
                    message:"There has been an error while creating this activity. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
        }).then(activityId =>{
            if (activityId) navigate(`/edit-activity?activity-id=${activityId}&lang=${defaultLang.value}`);
        }).catch(err => {
            console.error("error", err);
            XPopUp({
                message:"There's been an error. Please, try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner()
        })
    }

    const handleActivityTypeSelect = (activityType) => {
        modal(
            `Are you sure you wish to create an activity type ${activityType} in ${defaultLang.label}?`,
            `Create activity`, 
            () => createActivity(activityType)
        )
    }

    const getActivityInfo = async (activityId) => {
        showSpinner()

        return await getActivityById(activityId).then(data => {
            return data
        }).catch(err => {
            console.log(err)
            XPopUp({
                message:"There's been an error getting your activity. Please, try again.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner()
        });
    }

    const handleDuplicateActivity = async (activityToDuplicate) => {

        let activityInfoCopy = await getActivityInfo(activityToDuplicate.id)
        const filteredOptions = activityInfoCopy?.options ? activityInfoCopy?.options.map((option) => {
            return { ...option, id: 0}
        }) : []

        const filteredTargets = activityInfoCopy?.targets ? activityInfoCopy?.targets.map((target) => {
            return { ...target, id: 0}
        }) : []

        activityInfoCopy.id = 0
        activityInfoCopy.name = `Copy: ${activityToDuplicate?.name || 'Activity #' + activityToDuplicate.id}`
        activityInfoCopy.assessments = []
        activityInfoCopy.status = "DRAFT"
        activityInfoCopy.language_default = defaultLang?.value
        activityInfoCopy.options = filteredOptions
        activityInfoCopy.targets = filteredTargets
        activityInfoCopy.available_languages = []
        delete activityInfoCopy.current_language
        delete activityInfoCopy.custom_client_id
        delete activityInfoCopy.isCollapsed
        delete activityInfoCopy.criterion_id
        delete activityInfoCopy.created_at
        delete activityInfoCopy.updated_at

        showSpinner()
        createNewActivity(null, activityInfoCopy).then(data => {
            navigate(`/edit-activity?activity-id=${data.id}&lang=${data.language_default}`)
        }).catch(err => {
            console.error(err)
            XPopUp({
                message:"There's been an error. Please, try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner()
        })
    }
    console.log(activities)
    return (
        <StyledPageContainer>
            <h2 style={{ margin: '16px', textAlign: 'center' }}>
                {activityToDuplicate ? `Duplicate activity #${activityToDuplicate.id}` : 'Create a new activity'}
            </h2>
            {activityToDuplicate && <p style={{ textAlign: 'center', margin: '-16px 0 16px 0' }}>{activityToDuplicate?.name} ({activityToDuplicate?.language_default})</p>}
            <div style={{display: 'flex', placeItems: 'center', placeContent: 'center', flexDirection:'column'}}>
                <p style={{ margin: '8px', textAlign: 'center' }}>
                    First, select the default language of the new activity.
                </p>

                <XDropdown
                    onChange={(e) => handleLangChange(e)}
                    value={defaultLang}
                    options={LANGUAGES_OPTIONS}
                    multiple={false}
                    hasCheckboxes={false}
                    disableClearable
                    label="Language"
                    labelOptions='label'
                    width='100%'
                    bgColor='100'
                    style={{maxWidth: '200px', marginBottom: '20px'}}
                />
            </div>

            {defaultLang.value !== 'default' && 
            <>
                <p style={{ margin: '8px', textAlign: 'center' }}>
                    {activityToDuplicate ? 'Second, select the activity to be duplicated down below.' : 'Second, select the type of activity from the options down below.'}
                </p>
                
                <StyledDivFlexWrap>
                    {activityToDuplicate && 
                        <XButton
                            key={`activity-duplicate-selector`}
                            id={activityToDuplicate.id}
                            style={{ width: '225px', minHeight: '250px', margin: '1em', fontWeight: 'unset', textTransform: 'unset'}}
                            variant='outlined'
                            onClick={() => {handleDuplicateActivity(activityToDuplicate)}}
                        >
                            <StyledActivityCardsContainer>
                                <img style={{ width: '80px', height: '80px' }} src='./assets/images/activity_icon.png' />
                                <StyledCardTitle>Duplicate activity</StyledCardTitle>
                                <StyledCardText style={{ margin: '0'}}>{activityToDuplicate?.name || activityToDuplicate?.id}</StyledCardText>
                                <StyledCardText style={{ marginTop: '0'}}>{activityToDuplicate?.title}</StyledCardText>
                            </StyledActivityCardsContainer>
                        </XButton>
                    }
                    {!activityToDuplicate && activities.map((activity, index) => (
                        ACTIVITIES_ANSWERS_TEMPLATE.FINISHED.includes(activity.label) ? 
                        (<XButton
                            key={`activity-selector-${index}`}
                            id={activity?.id ?? index}
                            style={{ width: '225px', minHeight: '250px', margin: '1em', fontWeight: 'unset', textTransform: 'unset'}}
                            variant='outlined'
                            onClick={() => {handleActivityTypeSelect(activity.label)}}
                        >
                            <StyledActivityCardsContainer>
                                <img style={{ width: '80px', height: '80px' }} src='./assets/images/activity_icon.png' />
                                <StyledCardTitle>{activity?.title}</StyledCardTitle>
                                <StyledCardText>{activity?.description}</StyledCardText>
                                {(activity?.title === "Input Long" || activity?.label === "input-long" || activity?.id === 21) && 
                                    <StyledCardText style={{fontSize: '0.8rem', margin: 0, color: '#f6ab0e'}}>
                                        <StyledFontAwesomeIcon
                                            icon={['fa', 'triangle-exclamation']}
                                            hasMarginRight size='1x'
                                            isWarning
                                        />
                                        This is not an interactive activity.
                                    </StyledCardText>
                                }
                            </StyledActivityCardsContainer>
                        </XButton>)
                        :
                        (<XBox
                            key={`activity-selector-${index}`}
                            id={activity?.id ?? index}
                            style={{ width: '200px' }}>
                            <StyledActivityCardsContainer>
                                <img style={{ width: '80px', height: '80px' }} src='./assets/images/activity_icon.png' />
                                <StyledCardTitle>{activity?.title}</StyledCardTitle>
                                <StyledCardText>{activity?.description}</StyledCardText>
                                <XButton disabled>Coming Soon</XButton>
                            </StyledActivityCardsContainer>
                        </XBox>)
                    ))}
                </StyledDivFlexWrap>
            </>
            }
        </StyledPageContainer>

    )
}

export default ActivitySelector;
