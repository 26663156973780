import React, { useMemo } from 'react';
import { XCard, XInput, XBox, XButton, XRadio } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import {
    ExtraInfoText,
    StyledButtonCenterContainer,
    CopyIcon,
    TrashIcon,
} from '../../../Styled-Components';
import { CORRECT_OPTIONS } from '../../../CONSTANTS';

export const CorrespondenceAnswers = ({
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated,
    ACTIVITY_TYPE,
    SETTINGS
}) => {
    const targets = useMemo(() => {
        return activityInfo.targets ? [...activityInfo.targets].sort((a,b) => a.order - b.order) : []
    }, [activityInfo])
    /* FOR CARD */ 
    const modifyTargets = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo, targets };
        const activeOrder = index !== undefined ? newInfo.targets[index].order : undefined
        const currentNumOfOrders = Math.max(...targets.map(target => target.order));
        const newOrder = currentNumOfOrders + 1;

        switch (action) {
            case 'add':
                const newTarget = { id: 0, order: newOrder };
                newInfo.targets.push(newTarget);

                const newOptionTrue = { id: 0, target_order: newOrder, is_right: "TRUE" };
                const newOptionFalse = { id: 0, target_order: newOrder, is_right: "FALSE" };
                newInfo.options.push(newOptionTrue, newOptionFalse);
                break;
            case 'copy':
                const copyTarget = { ...newInfo.targets[index], order: newOrder, id: 0 };
                newInfo.targets.push(copyTarget);

                const options = newInfo.options.filter(option => option.target_order === activeOrder);
                const copyOptions = options.map(option => Object.assign({}, option, { target_order: newOrder, id: 0 }));
                const optionsPlusCopyOptions = newInfo.options.concat(copyOptions);
                newInfo.options = optionsPlusCopyOptions;
                break;
            case 'delete':
                newInfo.targets.splice(index, 1);
                newInfo.options = newInfo.options.filter((option) => option.target_order !== activeOrder)
                break;
            case 'edit':
                newInfo.targets[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    }

    /* FOR ANSWER BOX */ 
    const modifyOptions = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo, targets };
        const activeOrder = action === 'add' ? newInfo.targets[index]?.order : newInfo.options[index]?.target_order;
        const activeOrderOptionsAmount = newInfo.options.filter(option => option.target_order === activeOrder).length;

        switch (action) {
            case 'add':
                if (activeOrderOptionsAmount < 4) {
                    const newOption = { id: 0, target_order: activeOrder, is_right: "FALSE" };
                    newInfo.options.push(newOption);
                } else {
                    XPopUp({
                        message:"You can't have more than 4 possible answers for each card.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
                break;
            case 'delete':
                if (activeOrderOptionsAmount > 2) {
                    newInfo.options.splice(index, 1);
                } else {
                    XPopUp({
                        message:"You can't have less than 2 possible answers for each card.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
                break;
            case 'edit':
                newInfo.options[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    }
    
    return (
        <>
            <XCard
                isCollapsable={true}
                title='Cards'
                marginXSize='s'
            >
                {/* CARD */}
                {targets.map((target, targetIndex) => (
                    <XBox key={target?.id !== 0 ? `target-${target.id}` : `target-${targetIndex}-${target.order}`}>
                        <XCard
                            key={`${target?.id}-targetCardInfo`}
                            title={`${SETTINGS.INDIVIDUAL_TITLE[ACTIVITY_TYPE]} ${(targetIndex + 1).toString()}`}
                            controls={[
                                {
                                    icon: <CopyIcon />,
                                    isMaterialUI: true,
                                    onClick: () => modifyTargets('copy', targetIndex)
                                },
                                {
                                    icon: <TrashIcon />,
                                    isMaterialUI: true,
                                    onClick: () => modifyTargets('delete', targetIndex)
                                }
                            ]}
                            isCollapsable={SETTINGS.INDIVIDUAL_COLLAPSABLE?.includes(ACTIVITY_TYPE)}
                        >
                            <XInput
                                label='Text'
                                style={{ margin: '8px', width: '95%', textAlignLast: SETTINGS.ALIGN_CENTER_TEXT?.includes(ACTIVITY_TYPE) ? 'center' : 'unset' }}
                                id={`${target.id}-title`}
                                size='small'
                                fullWidth
                                multiline
                                rows={SETTINGS.ROWS_TEXT[ACTIVITY_TYPE] || 4}
                                value={target?.title}
                                onChange={(e) => modifyTargets('edit', targetIndex, 'title', e.target.value)}
                                inputProps={{ maxLength: SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200 }}
                            />
                            <ExtraInfoText>{target?.title?.length || 0} of {SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200} characters</ExtraInfoText>

                            {/* ANSWER BOX */}
                            {activityInfo.options.map((option, optionIndex) => (
                                option.target_order === target.order &&
                                <XBox key={option?.id !== 0 ? `option-${option.id}` : `option-${optionIndex}`} style={{ padding: '0' }}>
                                    <XCard
                                        title={`Possible answer for card ${targetIndex + 1}`}
                                        controls={[
                                            {
                                                icon: <TrashIcon />,
                                                isMaterialUI: true,
                                                onClick: () => modifyOptions('delete', optionIndex)
                                            }
                                        ]}
                                        marginXSize='xxs'
                                        marginYSize='xxs'
                                    >
                                        <XInput
                                            label='Text'
                                            style={{ margin: '8px', width: '95%', textAlignLast: SETTINGS.ALIGN_CENTER_TEXT?.includes(ACTIVITY_TYPE) ? 'center' : 'unset' }}
                                            id={`${option?.id}-title`}
                                            size='small'
                                            fullWidth
                                            multiline
                                            rows={SETTINGS.ROWS_TEXT[`${ACTIVITY_TYPE}-options`] || 4}
                                            value={option?.title}
                                            onChange={(e) => modifyOptions('edit', optionIndex, 'title', e.target.value)}
                                            inputProps={{ maxLength: SETTINGS.LIMIT_OPTION_TEXT[`${ACTIVITY_TYPE}-options`] || 200 }}
                                        />
                                        <ExtraInfoText>{option?.title?.length || 0} of {SETTINGS.LIMIT_OPTION_TEXT[`${ACTIVITY_TYPE}-options`] || 200} characters</ExtraInfoText>
                                        {SETTINGS.IS_RIGHT.includes(ACTIVITY_TYPE) &&
                                            <XRadio
                                                label="Mark as correct:"
                                                id={`${option.id}-is_right`}
                                                options={CORRECT_OPTIONS}
                                                value={option?.is_right || false}
                                                paddingXSize='s'
                                                onChange={(e) => modifyOptions('edit', optionIndex, 'is_right', e.target.value)}
                                            />
                                        }

                                    </XCard>
                                </XBox>
                            ))}
                            {!SETTINGS.EXCLUDE_ADD_ANOTHER_ANSWER?.includes(ACTIVITY_TYPE) &&
                                < StyledButtonCenterContainer >
                                    <XButton onClick={() => modifyOptions('add', targetIndex)}>Add another answer for card {target?.order}</XButton>
                                </StyledButtonCenterContainer>
                            }

                        </XCard>
                    </XBox>
                ))}

                {SETTINGS.ADD_NEW_OPTION.includes(ACTIVITY_TYPE) &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => modifyTargets('add')}>Add new card</XButton>
                    </StyledButtonCenterContainer>
                }
            </XCard >
        </>)
}
