import React, { useState, useEffect } from 'react';
import { getAllExistingMedia, postMediaToCMS } from '../../../services/xevalApi.service';
import { XCard, XInput, XBox, XButton, XRadio, XGallery } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import EditorTinyMCE from '../../../components/TinyMCE/EditorTinyMCE';
import {
    ExtraInfoText,
    StyledButtonCenterContainer,
    CopyIcon,
    TrashIcon,
    StyledOptionImg,
    StyledSpaceAroundDiv,
    StyledImgPairingContainer,
    StyledFontAwesomeIcon
} from '../../../Styled-Components';
import { CORRECT_OPTIONS, MEDIA_PREVIEW } from '../../../CONSTANTS';
// import GalleryModal from '../../../components/Gallery/GalleryModal';

export const StandardAnswers = ({
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated,
    ACTIVITY_TYPE,
    SETTINGS,
    TOOLTIPS
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeImgIndex, setActiveImgIndex] = useState(0);
    const [modifyImgFrom, setModifyImgFrom] = useState('options')

    useEffect(() => {
        activityInfo.options?.sort((x, y) => x.order - y.order);
        activityInfo.targets?.sort((x, y) => x.order - y.order);
    }, [activityInfo]);

    const toggleModal = (toModifyFrom) => {
        if (!isOpen && toModifyFrom) setModifyImgFrom(toModifyFrom);
        setIsOpen(!isOpen);
    };


    const editActivityImg = (media) => {
        let newInfo = { ...activityInfo }

        if (media.type.startsWith('image')) {
            newInfo[modifyImgFrom][activeImgIndex].image = media?.path;
            newInfo[modifyImgFrom][activeImgIndex].image_url = media?.url;
    
            setIsActivityInfoUpdated(false)
            setActivityInfo(newInfo)
            return media
        }
        
        return false
    }

    const modifyOptions = (action, index, keyToEdit, valueToEdit) => {
        const numOfOptions = Object.keys(activityInfo.options).length;

        if (
            SETTINGS.ADD_NEW_OPTION_LIMITS.hasOwnProperty(ACTIVITY_TYPE) &&
            SETTINGS.ADD_NEW_OPTION_LIMITS[ACTIVITY_TYPE].MIN &&
            (action === 'delete' || action === 'deleteLast') &&
            (numOfOptions <= SETTINGS.ADD_NEW_OPTION_LIMITS[ACTIVITY_TYPE].MIN)
        ) {
            XPopUp({
                message:"Removing another option might not look good in the app. Sorry.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }
        else if (
            SETTINGS.ADD_NEW_OPTION_LIMITS.hasOwnProperty(ACTIVITY_TYPE) &&
            SETTINGS.ADD_NEW_OPTION_LIMITS[ACTIVITY_TYPE].MAX &&
            (action === 'add' || action === 'copy') &&
            (numOfOptions >= SETTINGS.ADD_NEW_OPTION_LIMITS[ACTIVITY_TYPE].MAX)
        ) {
            XPopUp({
                message:"You are attempting to add to many options and it might not look good in the app. Sorry.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        } else if (SETTINGS.MODIFY_OPTIONS_STANDARD.includes(ACTIVITY_TYPE)) {
            modifyOptionsStandard(action, index, keyToEdit, valueToEdit)
        } else if (SETTINGS.MODIFY_OPTIONS_WITH_SINGLE_TARGET.includes(ACTIVITY_TYPE)) {
            modifyOptionsWithSingleTarget(action, index, keyToEdit, valueToEdit)
        }
    }

    const modifyOptionsStandard = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo };
        switch (action) {
            case 'add':
                const newOption = { id: 0 };
                newInfo.options.push(newOption);
                break;
            case 'copy':
                const copyOption = { ...newInfo.options[index], id: 0 };
                newInfo.options.push(copyOption);
                break;
            case 'delete':
                newInfo.options.splice(index, 1);
                break;
            case 'edit':
                newInfo.options[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    };

    const modifyOptionsWithSingleTarget = (action, index, keyToEdit, valueToEdit, limit) => {
        let newInfo = { ...activityInfo };

        const optionsSortedByOrder = [ ...newInfo.options].sort((a, b) => b.order - a.order);
        const newOptionOrder = (optionsSortedByOrder[0]?.order ?? 0) + 1;

        const targetsSortedByOrder = [ ...newInfo.targets].sort((a, b) => b.order - a.order);
        const newTargetOrder = (targetsSortedByOrder[0]?.order ?? 0) + 1;

        const targetOrderToModify = newInfo.options[index]?.target_order;
        const targetIndex = newInfo.targets.findIndex(target => target.order === targetOrderToModify);

        switch (action) {
            case 'add':
                const newOption = { id: 0, order: newOptionOrder, target_order: newTargetOrder };
                const newTarget = { id: 0, order: newTargetOrder }
                newInfo.options.push(newOption);
                newInfo.targets.push(newTarget);
                break;
            case 'copy':
                let copyOption = Object.assign({}, newInfo.options[index]);
                copyOption.order = newOptionOrder;
                copyOption.target_order = newTargetOrder;
                copyOption.id = 0;

                let copyTarget = Object.assign({}, newInfo.targets[targetIndex]);
                copyTarget.order = newTargetOrder;
                copyTarget.id = 0;

                newInfo.options.push(copyOption);
                newInfo.targets.push(copyTarget);
                break;
            case 'delete':
                newInfo.options.splice(index, 1);
                newInfo.targets.splice(targetIndex, 1);
                break;
            case 'deleteLast':
                newInfo.options.splice(-1, 1);
                newInfo.targets.splice(-1, 1);
                break;
            case 'edit':
                newInfo.options[index][keyToEdit] = valueToEdit;
                break;
            case 'editTarget':
                newInfo.targets[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    };

    const switchThreeFive = () => {
        let newInfo = { ...activityInfo };
        const currentNumOfOptions = activityInfo.options.length;

        if (ACTIVITY_TYPE === 'opinion-scale') {
            if (currentNumOfOptions === 3) {
                const newOption0 = {
                    "id": 0,
                    "is_right": "FALSE",
                    "image": "StronglyAgree_Colour.svg",
                    "title": "Strongly agree",
                    "order": 5
                };
                newInfo.options.splice(4, 0, newOption0);

                const newOption4 = {
                    "id": 0,
                    "is_right": "FALSE",
                    "image": "StronglyDisagree_Colour.svg",
                    "title": "Strongly disagree",
                    "order": 1
                };
                newInfo.options.splice(0, 0, newOption4);
            } else if (currentNumOfOptions === 5) {
                newInfo.options.splice(4, 1);
                newInfo.options.splice(0, 1);
            };
        };

        if (ACTIVITY_TYPE === 'order-chronology') {
            if (currentNumOfOptions === 3) {
                const newOption3 = { "id": 0, "target_order": 4, "is_right": "FALSE" };
                const newTarget3 = { "id": 0, "order": 4 };
                newInfo.options.splice(3, 0, newOption3);
                newInfo.targets.splice(3, 0, newTarget3);

                const newOption4 = { "id": 0, "target_order": 5, "is_right": "FALSE" };
                const newTarget4 = { "id": 0, "order": 5 };
                newInfo.options.splice(4, 0, newOption4);
                newInfo.targets.splice(4, 0, newTarget4);
            } else if (currentNumOfOptions === 5) {
                newInfo.options.splice(3, 2);
                newInfo.targets.splice(3, 2);
            };
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    };

    return (
        <XCard
            isCollapsable={true}
            title='Answers'
            marginXSize='m'
            tooltip={
                TOOLTIPS.INSTRUCTIONS.IMAGES.hasOwnProperty(activityInfo?.type) ?
                <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.IMAGES[activityInfo?.type]} size='1x' isInfo />
                : false
            }
        >
            {activityInfo.options?.map((option, index) => (
                <XBox key={'option' + index}>
                    <XCard
                        key={`${option.id}-optionCardInfo`}
                        title={SETTINGS.INDIVIDUAL_TITLE.hasOwnProperty(ACTIVITY_TYPE)
                            ? (ACTIVITY_TYPE === 'deduction' || ACTIVITY_TYPE === 'fit-word')
                                ? `${SETTINGS.INDIVIDUAL_TITLE[ACTIVITY_TYPE]} {${option.target_order}}`
                                : `${SETTINGS.INDIVIDUAL_TITLE[ACTIVITY_TYPE]} ${(index + 1).toString()}`
                            : false}
                        controls={SETTINGS.INDIVIDUAL_CONTROLS.includes(ACTIVITY_TYPE) ?
                            [
                                {
                                    icon: <CopyIcon />,
                                    isMaterialUI: true,
                                    onClick: () => modifyOptions('copy', index)
                                },
                                {
                                    icon: <TrashIcon />,
                                    isMaterialUI: true,
                                    onClick: () => modifyOptions('delete', index)
                                }
                            ]
                            : SETTINGS.INDIVIDUAL_CONTROLS_TRASHCAN.includes(ACTIVITY_TYPE) ?
                                [
                                    {
                                        icon: <TrashIcon />,
                                        isMaterialUI: true,
                                        onClick: () => modifyOptions('delete', index)
                                    }
                                ]
                                : false
                        }
                        isCollapsable={SETTINGS.INDIVIDUAL_COLLAPSABLE.includes(ACTIVITY_TYPE)}
                    >
                        {((ACTIVITY_TYPE === "true-false" && option?.image) ||
                            (ACTIVITY_TYPE === "opinion-scale" && option?.image)) &&
                            <StyledOptionImg
                                alt={`${option?.title.split(".").shift()} icon`}
                                src={`/assets/images/${ACTIVITY_TYPE}/${option?.image}`}
                            />
                        }
                        {ACTIVITY_TYPE === "opinion-scale" && !option?.image &&
                            <>
                                {activityInfo.options?.length === 5
                                    ?
                                    <StyledOptionImg
                                        alt={`${option?.title} icon`}
                                        src={`/assets/images/${ACTIVITY_TYPE}/${option.order === 1 ? 'StronglyDisagree_Colour.svg' :
                                            option.order === 2 ? 'Disagree_Colour.svg' :
                                                option.order === 3 ? 'Neutral_Colour.svg' :
                                                    option.order === 4 ? 'Agree_Colour.svg' :
                                                        option.order === 5 ? 'StronglyAgree_Colour.svg' :
                                                            'Neutral.svg'
                                            }`}
                                    />
                                    :
                                    <StyledOptionImg
                                        alt={`${option?.title} icon`}
                                        src={`/assets/images/${ACTIVITY_TYPE}/${option.order === 2 ? 'Disagree_Colour.svg' :
                                        option.order === 3 ? 'Neutral_Colour.svg' :
                                            option.order === 4 ? 'Agree_Colour.svg' :
                                                    'Neutral.svg'
                                            }`}
                                    />
                                }
                            </>
                        }
                        {SETTINGS.MEDIA.includes(ACTIVITY_TYPE) &&
                            <StyledSpaceAroundDiv>
                                {SETTINGS.MEDIA_OPTIONS_WITH_SINGLE_TARGET.includes(ACTIVITY_TYPE) && activityInfo.targets.map((target, targetIndex) => (
                                    option.target_order === target.order &&
                                        <StyledImgPairingContainer onClick={() => setActiveImgIndex(targetIndex)} key={targetIndex}>
                                            <p>Target image</p>
                                            <img
                                                style={{ cursor: 'pointer', maxWidth: '95%', height: '175px', objectFit: 'contain' }}
                                                src={target?.image ? `${target.image.startsWith('@@@') ? MEDIA_PREVIEW : ''}${target.image}` : '/assets/images/default_open_gallery.svg'}
                                                onClick={() => toggleModal('targets')}
                                                onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/default_open_gallery.svg' }}
                                            />
                                        </StyledImgPairingContainer>
                                ))}
                                <StyledImgPairingContainer onClick={() => setActiveImgIndex(index)}>
                                    {SETTINGS.MEDIA_OPTIONS_WITH_SINGLE_TARGET.includes(ACTIVITY_TYPE) ? <p>Answer to target</p> : null}
                                    <img
                                        style={{ cursor: 'pointer', maxWidth: '95%', height: '175px', objectFit: 'contain' }}
                                        src={option?.image ? `${option.image.startsWith('@@@') ? MEDIA_PREVIEW : ''}${option.image}` : '/assets/images/default_open_gallery.svg'}
                                        onClick={() => toggleModal('options')}
                                        onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/default_open_gallery.svg' }}
                                    />
                                </StyledImgPairingContainer>
                            </StyledSpaceAroundDiv>
                        }
                        {(SETTINGS.MEDIA.includes(ACTIVITY_TYPE) || SETTINGS.MEDIA_OPTIONS_WITH_SINGLE_TARGET.includes(ACTIVITY_TYPE)) &&
                            // <GalleryModal
                            //     isOpen={isOpen}
                            //     onCloseModal={toggleModal}
                            //     onSelect={editActivityImg}
                            // />
                            <XGallery
                                isGalleryOpen={isOpen}
                                setIsGalleryOpen={setIsOpen}
                                fetchMedia={getAllExistingMedia}
                                selectMedia={editActivityImg}
                                addNewMedia={postMediaToCMS}
                                mediaPreview={MEDIA_PREVIEW} 
                            />
                        }
                        {SETTINGS.ROWS_TEXT.hasOwnProperty(ACTIVITY_TYPE) &&
                            <StyledSpaceAroundDiv>
                                {SETTINGS.ROWS_TEXT_WITH_SINGLE_TARGET.includes(ACTIVITY_TYPE) && activityInfo.targets.map((target, targetIndex) => (
                                    option.target_order === target.order &&
                                        <div style={{ width: '95%' }} key={targetIndex}>
                                            <XInput
                                                label='Target Text'
                                                style={{ margin: '8px', width: '95%', textAlignLast: SETTINGS.ALIGN_CENTER_TEXT.includes(ACTIVITY_TYPE) ? 'center' : 'unset' }}
                                                id={`${target?.id || targetIndex}-target-title`}
                                                size='normal'
                                                fullWidth
                                                multiline
                                                rows={SETTINGS.ROWS_TEXT[ACTIVITY_TYPE] || 4}
                                                value={target?.title}
                                                onChange={(e) => modifyOptions('editTarget', targetIndex, 'title', e.target.value)}
                                                inputProps={{ maxLength: SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200 }}
                                            />
                                            <ExtraInfoText>{target?.title?.length || 0} of {SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200} characters</ExtraInfoText>
                                        </div>
                                ))}
                                <div style={{ width: '95%' }}>
                                    {SETTINGS.USE_TINY_MCE.includes(ACTIVITY_TYPE) ? (
                                        <>
                                            <EditorTinyMCE
                                                value={option?.title}
                                                handleEditorChange={(evt, editor) => modifyOptions('edit', index, 'title', evt)}
                                                config={{
                                                    max_num_characters: SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200,
                                                    height: '10em',
                                                    plugins: [
                                                        'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                                        'FMathEditor, codesample, fontselect, damloader',
                                                    ],
                                                    toolbar: [
                                                        "bold italic forecolor backcolor | " +
                                                        "alignleft aligncenter alignright alignjustify | " +
                                                        "charmap FMathEditorButton damloader| " +
                                                        "removeformat"
                                                    ],
                                                }}
                                            />
                                            <ExtraInfoText>{SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200} characters max</ExtraInfoText>
                                        </>
                                    ) : (
                                        <>
                                            <XInput
                                                label={SETTINGS.ROWS_TEXT_WITH_SINGLE_TARGET.includes(ACTIVITY_TYPE) ? 'Answer to target' : 'Text'}
                                                style={{ margin: '8px', width: '95%', textAlignLast: SETTINGS.ALIGN_CENTER_TEXT.includes(ACTIVITY_TYPE) ? 'center' : 'unset' }}
                                                id={`${option.id || index}-option-title`}
                                                size='normal'
                                                fullWidth
                                                multiline
                                                rows={SETTINGS.ROWS_TEXT[ACTIVITY_TYPE] || 4}
                                                value={option?.title}
                                                onChange={(e) => modifyOptions('edit', index, 'title', e.target.value)}
                                                inputProps={{ maxLength: SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200 }}
                                            />
                                            <ExtraInfoText>{option?.title?.length || 0} of {SETTINGS.LIMIT_OPTION_TEXT[ACTIVITY_TYPE] || 200} characters</ExtraInfoText>
                                        </>
                                    )}
                                </div>
                            </StyledSpaceAroundDiv>
                        }

                        {SETTINGS.INDIVIDUAL_FEEDBACK.hasOwnProperty(ACTIVITY_TYPE) &&
                            <>
                                <XInput
                                    label='Individual feedback'
                                    style={{ margin: '8px', width: '95%' }}
                                    id={`${option.id}-feedback`}
                                    size='normal'
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={option?.feedback}
                                    onChange={(e) => modifyOptions('edit', index, 'feedback', e.target.value)}
                                    inputProps={{ maxLength: SETTINGS.INDIVIDUAL_FEEDBACK[ACTIVITY_TYPE].LIMIT_FEEDBACK_TEXT || 150 }}
                                />
                                <ExtraInfoText>
                                    {option?.feedback?.length || 0} of {SETTINGS.INDIVIDUAL_FEEDBACK[ACTIVITY_TYPE].LIMIT_FEEDBACK_TEXT || 150} characters
                                </ExtraInfoText>
                            </>
                        }

                        {SETTINGS.IS_RIGHT.includes(ACTIVITY_TYPE) &&
                            <XRadio
                                label="Mark as correct:"
                                id={`${option?.id}-is_right`}
                                options={CORRECT_OPTIONS}
                                value={option?.is_right ? option.is_right : false}
                                paddingXSize='s'
                                onChange={(e) => modifyOptions('edit', index, 'is_right', e.target.value)}
                            />
                        }
                    </XCard>
                </XBox>
            ))}

            <StyledSpaceAroundDiv>
                {SETTINGS.ADD_NEW_OPTION.includes(ACTIVITY_TYPE) &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => modifyOptions('add')}>Add new option</XButton>
                    </StyledButtonCenterContainer>
                }

                {SETTINGS.DELETE_LAST.includes(ACTIVITY_TYPE) &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => modifyOptions('deleteLast')}>Delete last option</XButton>
                    </StyledButtonCenterContainer>
                }

                {SETTINGS.SWITCH_THREE_FIVE.includes(ACTIVITY_TYPE) &&
                    <StyledButtonCenterContainer>
                        <XButton onClick={() => switchThreeFive()}>
                            Switch to {activityInfo.options.length === 3 ? 'five' : 'three'} options
                        </XButton>
                    </StyledButtonCenterContainer>
                }
            </StyledSpaceAroundDiv>
        </XCard >
    )
}
