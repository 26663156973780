import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAssessmentById, getUserAssessmentById, updateAssessment } from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { XContainerPage, XContainerSidebar } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { IdentifyingData } from './IdentifyingData';
import { ActivitiesList } from './ActivitiesList';
import { AssessmentAddActivities } from './AssessmentAddActivities';
import { LANGUAGES_OPTIONS, CLIENT, XEVAL_URI_API } from '../.././CONSTANTS';
import { getAllActivities } from '../../services/xevalApi.service';
import Preview from '../../components/Preview/Preview'

import { 
    StyledXContainerPreview,
    StyledHeaderContainer,
    StyledGreenXButton,
    StyledFontAwesomeIcon,
} from '../../Styled-Components';
import ReactModal from 'react-modal';

const AssessmentCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const { showSpinner, hideSpinner } = useSpinner();
    const [assessmentInfo, setAssessmentInfo] = useState(null);
    const [userAssessmentId, setUserAssessmentId] = useState(null);
    const [activitiesToBeAdded, setActivitiesToBeAdded] = useState([]);
    const [upToDate, setUpToDate] = useState(true);
    const [addActivities,setAddActivities] = useState({status: false, from_position: 0});
    const [potentialActivitiesList, setPotentialActivitiesList] = useState(undefined);
    const [activitiesListTotal, setActivitiesListTotal] = useState(undefined);
    const initActivitiesParams = 
    `only=${CLIENT === 'MHE' ? 'name,title,id,type,label,isbn,units' : 'name,title,id,type,label'}` +
    '&limit=400' +
    '&status=ACTIVE' +
    '&order_by=id&sort=desc'

    const [activitiesParams,setActivitiesParams] = useState(initActivitiesParams)

    const assessmentId = parseInt(params.get('assessment-id'));

    useEffect(() => {
        !assessmentId ? handleNoAssessmentIdFound() : getAssessmentInfo(assessmentId);
    }, []);

    const getActivities = async () => {
        setPotentialActivitiesList(undefined)
        setActivitiesListTotal(undefined)
        await getAllActivities(activitiesParams).then(data => {
            assessmentInfo?.activities?.forEach(activity => {
                let indexOfActivityToRemove = undefined
                indexOfActivityToRemove = data.data.findIndex(potentialActivity => activity.id === potentialActivity.id)
                if (indexOfActivityToRemove > 0) {
                    data.data.splice(indexOfActivityToRemove, 1) 
                    data.total--
                }
            })
            setPotentialActivitiesList(data?.data ?? undefined)
            setActivitiesListTotal(data?.total ?? data?.data?.length ?? undefined)
        }).catch(err => {
            console.error(err)
        })
    }

    const getUserAssessmentId = async () => {
        setUserAssessmentId(null)
        if (assessmentInfo?.id) {
            const data = await getUserAssessmentById(assessmentInfo.id);
            setUserAssessmentId(data)
        }
    }

    useEffect(() => {
        if (assessmentInfo && !userAssessmentId) {
            if (assessmentInfo.activities.length > 0) {
                getUserAssessmentId();
            }
        }
    }, [assessmentInfo]);

    const handleNoAssessmentIdFound = () => {
        console.error("The assessment id seems to be missing. Try again later.")
        XPopUp({
            message:"The assessment id seems to be missing. Try again later.",
            iconType:'error',
            timer:'3000',
            popUpPosition:'top',
            iconColor: 'red',
        })
        navigate.goBack();
    }

    const getAssessmentInfo = (currentId) => {
        showSpinner();
        getAssessmentById(currentId).then(data => {
            const newData = {...data};
            if (data?.language_default) {
                const selectedLanguage = LANGUAGES_OPTIONS.find(language => language.value === data.language_default);
                newData.language_default = selectedLanguage;           
            }
            if (!data?.units) newData.units= []
            if (!data?.isbn) newData.isbn = []
            setAssessmentInfo(newData);
        }).catch(err => {
            console.error('getAssessmentInfo', err);
            XPopUp({
                message:"There has been an error while getting this assessment's data. Please, try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner();
        });
    }

    const handleActivities = (activitiesMinusOne) => {
        const allActivities = []
        if (activitiesMinusOne) {
            // Remove an activity. Send the rest without the weights so that it all gets recalculated by the BE
            activitiesMinusOne.forEach((activity, index) => allActivities.push({ id: activity?.id, order: index + 1  }))
        } else if (activitiesToBeAdded.length > 0) {
            // Add new activities. All without the weights so that it all gets recalculated by the BE
            assessmentInfo.activities.splice(addActivities.from_position, 0, ...activitiesToBeAdded)
            assessmentInfo.activities.forEach((activity, index) => allActivities.push({ id: activity?.id, order: index + 1 }))
        } else {
            assessmentInfo.activities.forEach(activity => allActivities.push({ id: activity?.id, order: activity?.order, weight: activity?.weight }))
        }
        return allActivities
    }

    const updateAssessmentInfo = async (activitiesMinusOne) => {
        showSpinner()
        const activitiesWithIdOrderWeight = handleActivities(activitiesMinusOne)

        await updateAssessment(assessmentInfo.id, { ...assessmentInfo, activities: activitiesWithIdOrderWeight, language_default: assessmentInfo?.language_default?.value || null })
            .then(data => {
                const selectedLanguage = LANGUAGES_OPTIONS.find(language => language.value === data.language_default)
                data.language_default = selectedLanguage
                setActivitiesToBeAdded([])
                setAssessmentInfo(data)
                return data
            })
            .then(() => {
                XPopUp({
                    message:"Assessment update has been successful",
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
            })
            .catch(err => {
                console.error(err)
                XPopUp({
                    message:"Assessment update has failed. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            })
            .finally(async () => {
                await getUserAssessmentId()
                setUpToDate(true)
                hideSpinner()
            })
    }

    return (
        <XContainerPage navbarSize="unset" style={{ height: 'calc(100VH - 82px)' }}>
            <XContainerSidebar
                wider
                paddingSize='unset'
                bgColor='200'
                style={{ backgroundColor: '#f5f5f5' }}
            >
                <StyledHeaderContainer>
                    <h1>ASSESSMENT EDITOR</h1>
                    <StyledGreenXButton 
                        onClick={() => updateAssessmentInfo()} 
                        size='small'
                        disabled={upToDate} 
                    >
                        <StyledFontAwesomeIcon 
                            icon={upToDate ? ['fa', 'check'] : ['fa', 'floppy-disk']} size='1x' 
                            title={upToDate ? 'Assessment up to date' : 'Save assessment.'} />
                    </StyledGreenXButton>
                </StyledHeaderContainer>

                {(assessmentId && assessmentId !== 0) && assessmentInfo && 
                    <div 
                        id='assessment-scrollable-sidebar'
                        style={{ overflowY: 'auto', height: 'calc(100vh - (59px + 82px))'}}
                    >
                        {/* IDENTIFYING DATA */}
                        <IdentifyingData
                            assessmentInfo={assessmentInfo}
                            setAssessmentInfo={setAssessmentInfo}
                            setUpToDate={setUpToDate}
                        />
                        {/* ACTIVITIES LIST*/}
                        <ActivitiesList
                            setAddActivities={setAddActivities}
                            assessmentInfo={assessmentInfo}
                            setAssessmentInfo={setAssessmentInfo}
                            updateAssessmentInfo={updateAssessmentInfo}
                            setUpToDate={setUpToDate}
                        />
                    </div>
                }

            </XContainerSidebar>
            <StyledXContainerPreview bgColor="500">
                {(userAssessmentId && assessmentInfo?.activities?.length > 0)
                    ? <Preview typeOfPreview='assessment' getUserId={getUserAssessmentId} userId={userAssessmentId} />
                    : <p style={{ textAlign: 'center' }}>Looks like this assessment doesn't have any activities yet.</p>
                }
            </StyledXContainerPreview>

            <ReactModal 
                isOpen={addActivities.status}
            >
                <AssessmentAddActivities
                    activitiesToBeAdded={activitiesToBeAdded}
                    setActivitiesToBeAdded={setActivitiesToBeAdded}
                    updateAssessmentInfo={updateAssessmentInfo}
                    potentialActivitiesList={potentialActivitiesList}
                    activitiesListTotal={activitiesListTotal}
                    activitiesParams={activitiesParams}
                    setActivitiesParams={setActivitiesParams}
                    initActivitiesParams={initActivitiesParams}
                    getActivities={getActivities}
                    setAddActivities={setAddActivities}
                />
            </ReactModal>
           
        </XContainerPage >
    )
}

export default AssessmentCreation;
