import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { StyledContainerPreviewSpinner, StyledFontAwesomeIcon } from '../../Styled-Components'
import { XSpinner, XDropdown, XButton } from '@ximdex/xui-react/material'
import { XEVAL_URI_API, PREVIEW_CHANNELS } from '../../CONSTANTS'

function Preview({
    typeOfPreview,
    getUserId,
    userId,
}) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [channel, setChannel] = useState('mobile')
    const [srcOfPreview, setSrcOfPreview] = useState(undefined)

    useEffect(() => {
        if (userId) setSrcOfPreview(`${XEVAL_URI_API}/evaluation?${typeOfPreview}_user_id=${userId}&channel=${channel}&target=${process.env.REACT_APP_CLIENT.toLowerCase()}${typeOfPreview === 'activity' ? `&lang=${params.get('lang')}` : ''}`)
    }, [userId, channel, params.get('lang')])

    const handleChangeChannel = (device) => {
        setChannel(device)
    }

    const refreshPreview = () => {
        getUserId(params.get('activity-id'))
    }

    return (
    <>
        {(srcOfPreview) ? (
            <>
                <div style={{ padding: '0.8rem 0', display: 'grid', gridTemplateColumns: 'auto 2.5rem', gridGap: '0.8rem'}}>
                    <XDropdown 
                        options={PREVIEW_CHANNELS}
                        labelOptions='label'
                        label='Select channel'
                        value={PREVIEW_CHANNELS.filter(prev_channel => prev_channel.value === channel)[0]}
                        onChange={(e, selectedInput) => handleChangeChannel(selectedInput?.value ?? '')}
                        width='100%'
                        hasCheckboxes={false}
                        multiple={false}
                        disableClearable
                        size="small"
                    />
                    <XButton onClick={refreshPreview} style={{ minWidth: 'unset' }}>
                        <StyledFontAwesomeIcon icon={['fa', 'arrows-rotate']} size='1x' title='Refresh preview.' />
                    </XButton>
                </div>
                <iframe
                    src={srcOfPreview}
                    title=""
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    id="iframePreview"
                    style={{ marginBottom: "1rem" }}
                />
            </>
        ) : (
            <StyledContainerPreviewSpinner>
                <XSpinner
                    width="75px"
                    border="15px"
                    color="#43a1a2"
                    backgroundColor="#f2f2f2"
                />
            </StyledContainerPreviewSpinner>
        )}
    </>
  )
}

export default Preview