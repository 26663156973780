import React, {useState, useEffect} from 'react'
import HTMLReactParser from "html-react-parser";
import {
    XCard,
    XInput,
    XTag,
} from '@ximdex/xui-react/material';
import {StyledDivFlexWrap, StyledTargetsContainer} from '../../Styled-Components'
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS, OPTIONS_WITH_TINY_EDITOR } from '../../CONSTANTS';
import EditorTinyMCE from '../../components/TinyMCE/EditorTinyMCE';
import useSortOptionsTargets from '../../hooks/useSortOptionsTargets';
import { WordCollectionAnswers } from './Answers/WordCollectionAnswers';
import { getActivityById } from '../../services/xevalApi.service';

const ActivityCreateTranslations = ({
    languageSelected,
    activityInfo,
    setIsActivityInfoUpdated,
    setActivityTranslation,
    ...props
}) => {
    const [, sortOptionsAndTargets] = useSortOptionsTargets();
    const [localStorageUpdate,setLocalStorageUpdate] = useState(false);
    const [activityTemplateTranslation, setActivityTemplateTranslation] =  useState(undefined);
    // CHECK IF EXISTS VALUES FOR TARGETS AND OPTIONS
    const [hasTargets,setHasTargets] =  useState([]);
    const [hasOptions,setHasOptions] =  useState([]);   

    useEffect(() => {
        buildTemplateTranslation();
        buildHasTargets();
        buildHasOptions();
    }, []);

    const buildTemplateTranslation = async ()=>{
        let translationStorage = JSON.parse(localStorage.getItem("create-translation"))
        
        // CREATE TEMPLATE TRANSLATIONS
            let newData = { ...activityInfo }
            if(!activityInfo.translatableField){
                await getActivityById(activityInfo.id).then(resp => {
                    newData = {...newData, resp}
                })
            }
            newData = sortOptionsAndTargets(newData);
            let template = newData
            template.available_languages = []
            newData?.translatable_fields?.forEach(translatableField => {
                if(translatableField === "options" && newData.options !==null && newData.options.length > 0){
                    let options = []
                    if(translationStorage?.options) 
                        options = translationStorage.options
                    else
                        newData.options?.forEach((option,i) => {
                            var optionCopy = {...option}
                            option.translatable_fields.forEach(translatableField => {
                                optionCopy = {
                                    ...optionCopy,
                                    [translatableField]: ''
                                }
                            })
                            options.push(optionCopy)
                        })
                    template[translatableField] = options
                }
                if (translatableField === "targets" && newData.targets !==null && newData.targets.length > 0){
                    let targets = []
                    if(translationStorage?.targets) 
                        targets = translationStorage.targets
                    else
                        newData.targets?.forEach((target,i) => {
                            let targetCopy = {...target}
                            target.translatable_fields.forEach(translatable_field => {
                                if(translatable_field === 'unaccepted_values' || translatable_field === 'accepted_values'){
                                    targetCopy = {...targetCopy,[translatable_field]: []}
                                }else{
                                    targetCopy[translatable_field] = ""
                                }
                            })
                            targets.push(targetCopy)
                        })
                    template[translatableField] = targets
                }
                if (translatableField === "feedbacks" && newData.feedbacks !== null){
                    let arrayFeedbacks = []
                    if(translationStorage?.feedbacks) 
                        arrayFeedbacks = translationStorage.feedbacks
                    else
                        newData.feedbacks?.forEach((feedback) => {
                            let feedbackCopy = {
                                ...feedback,
                                text: ''
                            }
                            arrayFeedbacks.push(feedbackCopy)
                        })
                    template[translatableField] = arrayFeedbacks;
                }
                if (translatableField === "name" || translatableField === "title" || translatableField === "text") {
                    if(translationStorage[translatableField]) 
                        template[translatableField] = translationStorage[translatableField]
                    else
                        template[translatableField] = ""
                }
            })
            setActivityTemplateTranslation(template)
    }

    const buildHasTargets = () => {
        let hasvalues = []
        if(activityInfo.targets && activityInfo.targets.length > 0){
            activityInfo?.targets.forEach((element,i) => {
                hasvalues.push(false)
                element.translatable_fields.forEach(translatableField => {
                    if(element[translatableField] !=null && element[translatableField].length > 0)
                        hasvalues.splice(hasvalues.length-1,1,true);
                })
            })
        }
        return hasvalues
    }

    const buildHasOptions = () => {
        let hasvalues = []
        if(activityInfo.options && activityInfo.options.length > 0){
            activityInfo?.options.forEach((element,i) => {
                hasvalues.push(false)
                element.translatable_fields.forEach(translatableField => {
                    if(element[translatableField])
                        hasvalues.splice(hasvalues.length-1,1,true);
                })
            })
        }
        setHasOptions(hasvalues)
    }


    useEffect(() => {
        if(activityTemplateTranslation){
            let templateTranslation = parseActivityTargetsArray()
            setActivityTranslation(templateTranslation)
        }

    }, [activityTemplateTranslation]);


    const parseActivityTargetsArray = () => {
        let activityTemplateCopy = {...activityTemplateTranslation}
        if(activityTemplateCopy.targets && activityTemplateCopy.targets.length > 0){
            let accepted_values = activityTemplateCopy.targets[0].accepted_values.filter(value => value !== "")
            let unaccepted_values = activityTemplateCopy.targets[0].unaccepted_values.filter(value => value !== "")
            let targets = {...activityTemplateCopy.targets[0]}
            targets.accepted_values = accepted_values
            targets.unaccepted_values = unaccepted_values
            return {...activityTemplateCopy, targets: [targets]}
        }
        return activityTemplateCopy
    }

    useEffect(() => {
        let translationStorage = JSON.parse(localStorage.getItem("create-translation"))
        localStorage.setItem("create-translation", JSON.stringify({
            ...translationStorage,
            feedbacks: activityTemplateTranslation?.feedbacks,
            name: activityTemplateTranslation?.name,
            text: activityTemplateTranslation?.text,
            title: activityTemplateTranslation?.title,
            options: activityTemplateTranslation?.options,
            targets: activityTemplateTranslation?.targets
        }))

    }, [localStorageUpdate]);

    // Handle Strings
    const handleTranslate = (e, field,position) => { 
        let templateCopy = {...activityTemplateTranslation}
        const input = field === 'text' ? e : e.target.value
        if(field === 'feedbacks'){
            let feedbacksTraductionsCopy = templateCopy.feedbacks
            let item = feedbacksTraductionsCopy[position];
            item['text'] = input;
            feedbacksTraductionsCopy[position] = item;
            templateCopy = {...templateCopy,feedbacks:feedbacksTraductionsCopy}
        }else{
            let stringTraductionCopy = templateCopy[field]
            stringTraductionCopy = input
            templateCopy = {...templateCopy,[field]:stringTraductionCopy}
        }
        setActivityTemplateTranslation(templateCopy)
        setIsActivityInfoUpdated(false)
        setLocalStorageUpdate(!localStorageUpdate)
    }

    // Handle Options
    const handleOptionTranslate = (e,fieldOption,position) => {
        let templateCopy = {...activityTemplateTranslation}
        const input = (OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && fieldOption !== 'feedback') ? e : e.target.value

        let optionsValuesCopy = templateCopy.options;
        let item = optionsValuesCopy[position];
        item[fieldOption] = input;
        optionsValuesCopy[position] = item

        templateCopy = {...templateCopy,options:optionsValuesCopy}
        setActivityTemplateTranslation(templateCopy)
        setIsActivityInfoUpdated(false)
        setLocalStorageUpdate(!localStorageUpdate)
    }

    // Handle Targets
    const handleTargetTranslate = (e,fieldOption,position, arrayTargetValues) => {
        // let translationStorage = JSON.parse(localStorage.getItem("create-translation"))
        let templateCopy = {...activityTemplateTranslation}
        let targetValuesCopy = templateCopy.targets
        let item = targetValuesCopy[position]
        const input = (OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && (fieldOption === 'title' || fieldOption === 'text')) ? e : e.target.value
        
        if(fieldOption === 'title' || fieldOption === 'text'){
            item[fieldOption] = input
            targetValuesCopy[position] = item
        }else{
            item[fieldOption][arrayTargetValues] = input
            targetValuesCopy[position] = item
        }
        // localStorage.setItem("create-translation", JSON.stringify({
        //     ...translationStorage,
        //     targets: targetValues
        // }))
        templateCopy = {...templateCopy,targets:targetValuesCopy}
        
        setActivityTemplateTranslation(templateCopy)
        setIsActivityInfoUpdated(false)
        setLocalStorageUpdate(!localStorageUpdate)

    }

    // Get Cards Titles
    const getTitle = (field) => {
        switch (field) {
            case 'title': return 'Header'
            case 'text': return 'Content'
            case 'name': return 'Name'
            case 'feedbacks': return 'Global feedback'
            case 'options': return 'Options'
            case 'targets': return 'Targets'
          }
    }

    return (
        <div style={{overflowY: 'auto', height: 'calc(100vh - 177px)'}}>
           {activityInfo?.translatable_fields?.map((translatableField,i) =>
                <div key={translatableField + i}>
                    {/* STRINGS */}
                    {activityInfo[translatableField] !== null &&
                    activityInfo[translatableField] !== '' &&
                    (translatableField === "name" || translatableField === "title" || translatableField === "text") &&
                        (
                            <XCard
                                key={'translatable_card' + i}
                                isCollapsed={false}
                                title={getTitle(translatableField)}
                                marginXSize='m'
                            >
                                <p style={{textAlign:'center'}}>{HTMLReactParser(String(activityInfo[translatableField]))}</p>
                                {translatableField === 'text'
                                ? 
                                    <EditorTinyMCE 
                                        value={activityTemplateTranslation && activityTemplateTranslation[translatableField]} 
                                        handleEditorChange={(e) => handleTranslate(e,translatableField)} 
                                        placeholder="Enter translation"
                                        initialValue=""
                                        config={{
                                            max_num_characters: 450,
                                            plugins: [
                                                'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                                'FMathEditor, codesample, fontselect',
                                                'damloader'
                                            ],
                                            toolbar: [`
                                                undo redo | bold italic forecolor backcolor fontsizeselect | 
                                                alignleft aligncenter alignright alignjustify | 
                                                bullist numlist outdent indent | 
                                                link damloader list table | 
                                                charmap FMathEditorButton | 
                                                removeformat
                                            `],
                                        }}
                                    />
                                : 
                                    <XInput
                                        placeholder="Enter translation"
                                        size='normal'
                                        fullWidth
                                        multiline
                                        rows={2}
                                        style={{margin: '8px', width: '95%'}}
                                        value={activityTemplateTranslation && activityTemplateTranslation[translatableField]}
                                        onChange={(e) => handleTranslate(e,translatableField)}
                                        inputProps={{ maxLength: 200 }}
                                    />}
                            </XCard>
                        ) 
                    }
                    {/* OPTIONS */}
                    {translatableField === 'options' && activityInfo.options?.length > 0 &&
                    (activityInfo.image === null || activityInfo.image === '') && 
                        (
                            <>

                                {hasOptions.includes(true) &&
                                <XCard
                                    isCollapsable={true}
                                    isCollapsed={false}
                                    title={getTitle(translatableField)}
                                    marginXSize='m'
                                >
                                    {activityInfo?.options?.map((option,key) => 
                                    <React.Fragment key={option.target_order}>
                                        {hasOptions[key] && 
                                        <XCard 
                                            style={{border: 'solid 1px rgba(0, 0, 0, 0.12)',borderRadius: '10px'}}
                                            key={'Option_key_' + i}
                                            // isCollapsable={true}
                                            isCollapsed={false}
                                            title={`Option ${key+1}`}
                                            marginXSize='m'
                                        >

                                            {option.translatable_fields.map((translatable_field,j) => 
                                                <React.Fragment key={'Translatable_fragment' + j}>
                                                    {typeof option[translatable_field] === 'string' &&
                                                    option[translatable_field] !== '' &&
                                                        <div>
                                                            <p style={{textAlign:'center'}}>{`${translatable_field.charAt(0).toUpperCase() + translatable_field.slice(1)}: `}{HTMLReactParser(String(activityInfo.options[key][translatable_field]))}</p>
                                                            {OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && translatable_field !== 'feedback'
                                                                ?
                                                                    <EditorTinyMCE 
                                                                        value={activityTemplateTranslation && activityTemplateTranslation?.options[key][translatable_field]} 
                                                                        handleEditorChange={(e) => handleOptionTranslate(e,translatable_field,key)} 
                                                                        placeholder="Enter translation"
                                                                        initialValue=""
                                                                        config={{
                                                                            max_num_characters: 450,
                                                                            plugins: [
                                                                                'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                                                                'FMathEditor, codesample, fontselect',
                                                                                'damloader'
                                                                            ],
                                                                            toolbar: [`
                                                                                undo redo | bold italic forecolor backcolor fontsizeselect | 
                                                                                alignleft aligncenter alignright alignjustify | 
                                                                                bullist numlist outdent indent | 
                                                                                link damloader list table | 
                                                                                charmap FMathEditorButton | 
                                                                                removeformat
                                                                            `],
                                                                        }}
                                                                    />
                                                                :
                                                                    <XInput
                                                                        placeholder="Enter translation"
                                                                        style={{ margin: '8px', width: '95%' }}
                                                                        size='normal'
                                                                        fullWidth
                                                                        multiline
                                                                        rows={2}
                                                                        value={activityTemplateTranslation && activityTemplateTranslation?.options[key][translatable_field]}
                                                                        onChange={(e) => handleOptionTranslate(e,translatable_field,key)}
                                                                        inputProps={{ maxLength: 200 }}
                                                                    />
                                                            }
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </XCard>}
                                    </React.Fragment>)}
                                </XCard>}
                            </>
                        )
                    }
                    {/* TARGETS */}
                    {translatableField === 'targets' && 
                    activityInfo.targets?.length > 0 &&
                    // activityInfo.targets !== null &&
                        (
                            <>
                                {hasTargets.includes(true) && <XCard
                                    isCollapsable={true}
                                    isCollapsed={false}
                                    title={getTitle(translatableField)}
                                    marginXSize='m'
                                >
                                    {activityInfo?.targets?.map((target,key) => 
                                    <React.Fragment key={target.order}>
                                        {hasTargets[key] && 
                                        <XCard 
                                            style={{border: 'solid 1px rgba(0, 0, 0, 0.12)',borderRadius: '10px'}}
                                            // isCollapsable={true}
                                            isCollapsed={false}
                                            title={`Target ${key+1}`}
                                            marginXSize='m'
                                        >
                                            {target.translatable_fields.map((translatable_field,j) => 
                                                <React.Fragment key={j}>
                                                {/* TARGETS STRINGS */}
                                                    {target[translatable_field] !== null &&
                                                    target[translatable_field] !== '' &&
                                                     typeof target[translatable_field] === 'string' &&
                                                        <div>
                                                            <p style={{textAlign:'center'}}>{`${translatable_field.charAt(0).toUpperCase() + translatable_field.slice(1)}: `}{HTMLReactParser(String(activityInfo.targets[key][translatable_field]))}</p>
                                                            {OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type)
                                                            ?
                                                                <EditorTinyMCE 
                                                                    value={activityTemplateTranslation && activityTemplateTranslation.targets[key][translatable_field]} 
                                                                    handleEditorChange={(e) => handleTargetTranslate(e, translatable_field,key)} 
                                                                    placeholder="Enter translation"
                                                                    initialValue=""
                                                                    config={{
                                                                        max_num_characters: 450,
                                                                        plugins: [
                                                                            'advlist, autolink, link, image, lists, media, table, wordcount, charmap',
                                                                            'FMathEditor, codesample, fontselect',
                                                                            'damloader'
                                                                        ],
                                                                        toolbar: [`
                                                                            undo redo | bold italic forecolor backcolor fontsizeselect | 
                                                                            alignleft aligncenter alignright alignjustify | 
                                                                            bullist numlist outdent indent | 
                                                                            link damloader list table | 
                                                                            charmap FMathEditorButton | 
                                                                            removeformat
                                                                        `],
                                                                    }}
                                                                />
                                                            :
                                                                <XInput
                                                                    placeholder="Enter translation"
                                                                    style={{ margin: '8px', width: '95%' }}
                                                                    size='medium'
                                                                    fullWidth
                                                                    multiline
                                                                    rows={2}
                                                                    value={activityTemplateTranslation && activityTemplateTranslation?.targets[key][translatable_field]}
                                                                    onChange={(e) => handleTargetTranslate(e, translatable_field,key)}
                                                                    inputProps={{ maxLength: 200 }}
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                    {/* TARAGETS ARRAYS */}
                                                    {target[translatable_field] !== null && 
                                                        typeof target[translatable_field] === 'object' &&
                                                        target[translatable_field].length > 0  && 
                                                        <div>
                                                            <h4>{translatable_field === "accepted_values" ? "Correct words" : "Incorrect words"}</h4>
                                                            <StyledTargetsContainer>
                                                                <XCard
                                                                    isCollapsable={false}
                                                                    title={'Original:'}
                                                                    marginXSize='m'
                                                                >
                                                                    <StyledDivFlexWrap>
                                                                        {target[translatable_field].map((value,i) => 
                                                                                <XTag
                                                                                key={`correctWordCollectionTag_${value}`}
                                                                                style={{ marginTop: '8px', color: 'black' }}
                                                                                name={value}
                                                                            />   
                                                                        )}
                                                                    </StyledDivFlexWrap>
                                                                </XCard>
                                                                <WordCollectionAnswers
                                                                    activityInfo={activityTemplateTranslation}
                                                                    setActivityInfo={setActivityTemplateTranslation}
                                                                    setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                                                                    ACTIVITY_TYPE={activityInfo.type}
                                                                    SETTINGS={ACTIVITIES_ANSWERS_OPTIONS_SETTINGS}
                                                                    cardTitle={'Translation:'}
                                                                    isCollapsable={false}
                                                                />
                                                            </StyledTargetsContainer>
                                                        </div>

                                                    }
                                                </React.Fragment>
                                            )}
                                        </XCard>}
                                    </React.Fragment>)}
                                </XCard>}
                            </>
                        )
                    }
                     {/* FEEDBACKS */}
                     {translatableField === 'feedbacks' &&
                        activityInfo?.feedbacks?.length > 0 &&
                        (   
                            <XCard
                                isCollapsed={false}
                                title={getTitle(translatableField)}
                                marginXSize='m'
                            >
                                {activityInfo?.feedbacks?.map((element,i) => 
                                    <div key={i}> 
                                        <p style={{textAlign:'center'}}>{`${element.success_percentage}%: `}{HTMLReactParser(String(element.text))}</p>
                                        <XInput
                                            placeholder="Enter translation"
                                            style={{ margin: '8px', width: '95%' }}
                                            size='normal'
                                            fullWidth
                                            multiline
                                            rows={2}
                                            value={activityTemplateTranslation && activityTemplateTranslation.feedbacks[i]?.text}
                                            onChange={(e) => handleTranslate(e,translatableField,i)}
                                            inputProps={{ maxLength: 200 }}
                                        />
                                    </div>
                                )}
                            </XCard>
                        )
                    }
                </div>  
            )}
        </div>  
    )
}

export default ActivityCreateTranslations
